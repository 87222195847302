export enum api {
    LOGIN = "/login",
    LOGOUT = "/logout",
    GET_LISTS = "/sites/:siteId/lists",
    GET_COLUMNS = "/sites/:siteId/lists/:listId/columns",
    GET_LIST_ITEMS = "/sites/:siteId/lists/:listId/items",
    GET_LIST_ITEM_DETAILS = "/sites/:siteId/lists/:listId/items/:itemId",
    GET_EMP_ID = "/me?$select=employeeId",
    GET_IMG_DOWNLOAD_URL = "/sites/:siteId/drives/:driveId/root:filePath",
    FEED_MEDICINE = "/calculators/feeds",
    CALCULATE_VACCINE = "/calculators/vaccines",
    CALCULATE_MEDICINE = "/calculators/medicines",
    UPLOAD_CALCULATOR_EXCEL = "/admin/calculators",
}

export const BACK_END_URL = window.REACT_APP_BACK_END_URL
export const SHAREPOINT_BASE_URL = window.REACT_APP_SHAREPOINT_BASE_URL
export const SHAREPOINT_IMG_DRIVE_ID =
    window.REACT_APP_SHAREPOINT_IMG_DRIVE_ID
export const SHAREPOINT_SITE_ID = window.REACT_APP_SHAREPOINT_SITE_ID
export const ENV = window.REACT_APP_NODE_ENV ?? "dev"
