import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { useLocation } from "react-router-dom"
import { GA4EventType } from "../../../constants/enums"
import { ga4GA } from "../../../utils/utils"
import "./Accordion.scss"
import AccordionProductDetails from "./AccordionProductDetails"

export default function CustomAccordion({
    title,
    productDetails,
    expand = false,
}: {
    title: any
    productDetails: any
    expand?: boolean
}) {
    const location = useLocation()
    const handleClickGA = () => {
        /* istanbul ignore else */
        if (location.pathname.includes("/catalog/")) {
            const product = location.pathname.split("/").at(-1)
            const catalogType = location.pathname.split("/").at(-2)
            if (product == "additive") {
                ga4GA(GA4EventType.SEND, {
                    accordion_name: title,
                    eventAction: "feed_additive_accordion_click",
                })
            } else {
                ga4GA(GA4EventType.SEND, {
                    accordion_name: title,
                    eventAction: "product_detail_accordion_click",
                    catalog_type: catalogType,
                    content_id: product,
                })
            }
        }
    }

    return (
        <Accordion
            elevation={0}
            className="margin-none"
            defaultExpanded={expand}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon data-testid={`${title} Expand Icon`} />
                }
                aria-controls={title + "-content"}
                className="accordion-summary"
                onClick={handleClickGA}
            >
                <Typography className="acc-typo">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="acc-padding-none">
                <AccordionProductDetails productDetails={productDetails} />
            </AccordionDetails>
        </Accordion>
    )
}
