import { createSlice } from "@reduxjs/toolkit"

interface IState {
    products: any[]
}

const initialState: IState = {
    products: [],
}

const productRecommendationSlice = createSlice({
    name: "productRecommendation",
    initialState,
    reducers: {
        setProducts: (state, action) => {
            const data = action.payload
            state.products = data
        },
    },
})

export const { setProducts } = productRecommendationSlice.actions

export default productRecommendationSlice
export const getProducts = (state: any) => state.productRecommendation.products
