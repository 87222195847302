import { Chip, Stack } from "@mui/material"
import "./Chips.scss"

export type TChipsFields = {
    label: string
}

export type ChipSection = {
    data: TChipsFields[]
    onClick: any
    active?: string
}

const Chips = ({ data, onClick, active }: ChipSection) => {
    return (
        <div className="btg-chips">
            <Stack direction="row" spacing={1}>
                {data.map((item: TChipsFields, index: number) => (
                    <Chip
                        label={item.label}
                        key={index}
                        variant={item.label === active ? "outlined" : "filled"}
                        onClick={() => {
                            onClick(item.label)
                        }}
                    />
                ))}
            </Stack>
        </div>
    )
}

export default Chips
