import { EventType } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"

const AuthProvider = ({ children, instance }: any) => {
    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        instance.setActiveAccount(instance.getAllAccounts()[0])
    }

    // Optional - This will update account state if a user signs in from another tab or window
    instance.enableAccountStorageEvents()

    instance.addEventCallback(async (event: any) => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event?.payload?.account
        ) {
            const account = event?.payload?.account
            instance.setActiveAccount(account)
            localStorage.setItem("isLoggedIn", "1")
        }
    })
    return <MsalProvider instance={instance}>{children}</MsalProvider>
}

export default AuthProvider
