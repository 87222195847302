import "./InputField.scss"

export interface IPropTypes extends React.HTMLProps<HTMLInputElement> {
    label: string
}

const InputField = ({
    id,
    label,
    placeholder,
    required,
    type,
    ...rest
}: IPropTypes) => {
    return (
        <div className="input-field-container">
            <label htmlFor={`${id}`}>
                {label} {required && <span className="required">*</span>}
            </label>
            <input
                id={id}
                type={type}
                placeholder={placeholder}
                required={required}
                {...rest}
            />
        </div>
    )
}

export default InputField
