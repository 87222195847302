import { useSelector } from "react-redux"
import { getListState } from "../redux/slices/listSlice"
import { Environments, ListAlias, ListPrefix } from "../constants/enums"
import { ENV } from "../configs/api"

const useGetListId = (alias: ListAlias | null = null) => {
    if (alias === null) {
        return null
    }

    const { mapping: sharepointListMapping } = useSelector(getListState)

    let prefix: ListPrefix
    switch (ENV) {
        case Environments.DEV:
            prefix = ListPrefix.DEV
            break
        case Environments.TEST:
            prefix = ListPrefix.TEST
            break
        case Environments.UAT:
            prefix = ListPrefix.UAT
            break
        case Environments.PROD:
            prefix = ListPrefix.PROD
            break
        default:
            prefix = ListPrefix.DEV
            break
    }

    const listName = prefix + alias

    return sharepointListMapping && listName in sharepointListMapping
        ? sharepointListMapping[listName]
        : null
}

export default useGetListId
