import "./ProductRecommendations.scss"
import Divider from "@mui/material/Divider"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Loader from "../../components/common/Loader/Loader"
import NoResultFound from "../../components/common/NoResultFound/NoResultFound"
import Pagination from "../../components/common/Pagination/Pagination"
import TagsListView from "../../components/shared/TagsListView/TagsListView"
import { RoutePaths } from "../../configs/route"
import { GA4EventType, ListAlias } from "../../constants/enums"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"
import useGetListId from "../../hooks/useGetListId"
import { setPageConfig } from "../../redux/slices/pageSlice"
import { getSearchTerm } from "../../redux/slices/searchSlice"
import { useGetListItemsQuery } from "../../redux/api/listApiSlice"
import { getLanguage, setErrorStatus } from "../../redux/slices/appSlice"
import { ga4GA, getColumnName, replacePathParams } from "../../utils/utils"

const ProductL2Tags = () => {
    const perPage = 20
    const { id } = useParams()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const language = useSelector(getLanguage)
    const [page, setPage] = useState<number>(1)
    const inputValue = useSelector(getSearchTerm)
    const tagListId = useGetListId(ListAlias.TAGS)

    const {
        columnMapping,
        isError: columnError,
        isFetching: columnsLoading,
    } = useGetColumnMapping(tagListId, "tags")

    const subTagsField = getColumnName(columnMapping, language, "SUB_TAGS")

    const titleFieldTH = getColumnName(columnMapping, "TH", "TAG")
    const titleFieldEN = getColumnName(columnMapping, "EN", "TAG")
    const titleField = language === "EN" ? titleFieldEN : titleFieldTH

    const {
        data,
        isSuccess,
        isError: dataError,
        isFetching: dataLoading,
    } = useGetListItemsQuery(
        {
            listId: tagListId,
            listName: "tags",
            $expand: `fields($select=${titleFieldEN},${titleFieldTH},${subTagsField})`,
            $orderby: `fields/${titleField} asc`,
        },
        {
            skip: columnsLoading,
        }
    )

    const { title, tags } = useMemo(() => {
        /* istanbul ignore else */
        if (!isSuccess) {
            return { title: "", tags: [] }
        }

        const selectedTag = data.value.find((item: any) => item.id === id)

        const lookupIds: any = []
        selectedTag.fields[subTagsField].forEach((item: any) => {
            lookupIds.push(item.LookupId)
        })

        const p2Tags: any = []
        const filteredTags: any = data.value.filter((item: any) =>
            lookupIds.includes(Number(item.id))
        )

        filteredTags.forEach((item: any) => {
            const tagUrl = replacePathParams(
                RoutePaths.PRODUCT_RECOMMENDATION_RESULT,
                {
                    id: item.id,
                    category: "ahbs",
                }
            )

            p2Tags.push({
                link: tagUrl,
                name: item.fields[titleField],
                en_name: item.fields[titleField],
            })
        })

        return { title: selectedTag.fields[titleField], tags: p2Tags }
    }, [data])

    useEffect(() => {
        setPage(1)
    }, [inputValue])

    const { pages = 0, paginatedData = [] } = useMemo(() => {
        /* istanbul ignore else */
        if (tags.length === 0) {
            return {}
        }

        const pages = Math.ceil(tags.length / perPage)
        /* istanbul ignore else */
        if (!inputValue) {
            return {
                pages,
                paginatedData: tags.slice(
                    (page - 1) * perPage,
                    (page - 1) * perPage + perPage
                ),
            }
        }

        // Search client side in both en and th columns
        const filteredTags = tags.filter((item: any) => {
            return (
                item?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                item?.en_name.toLowerCase().includes(inputValue.toLowerCase())
            )
        })

        return {
            pages: Math.ceil(filteredTags.length / perPage),
            paginatedData: filteredTags.slice(
                (page - 1) * perPage,
                (page - 1) * perPage + perPage
            ),
        }
    }, [tags, inputValue, page])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputValue && paginatedData && paginatedData.length == 0) {
                ga4GA(GA4EventType.SEND, {
                    search_term: inputValue,
                    eventAction: "recommendation_search_not_found",
                })
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [inputValue])

    useEffect(() => {
        dispatch(setErrorStatus(dataError || columnError))
    }, [dataError, columnError])

    useEffect(() => {
        dispatch(
            setPageConfig({
                rightIcon: "search",
                heading: title,
                title: t("product_recommendations"),
                backButtonLink: RoutePaths.PRODUCT_RECOMMENDATION_L1,
            })
        )
    }, [isSuccess])

    return (
        <Loader show={dataLoading || columnsLoading}>
            {paginatedData.length === 0 ? (
                <div className="result-margin">
                    <NoResultFound
                        title={`${t("no_result_found")} \n ${t(
                            "tell_us_what_looking_for"
                        )}`}
                        link={{
                            show: true,
                            width: "160px",
                            target: "_blank",
                            onClick: () => {},
                            title: t("submit_feedback"),
                            href: window.REACT_APP_TAGS_FEEDBACK_URL,
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className="recommendation-container">
                        <Divider className="divider" />
                        {paginatedData.map((item: any, idx: number) => (
                            <TagsListView
                                key={idx}
                                name={item.name}
                                link={item.link}
                            />
                        ))}
                    </div>
                    <Pagination
                        page={page}
                        count={pages}
                        onChange={handleChange}
                    />
                </>
            )}
        </Loader>
    )
}

export default ProductL2Tags
