import { createSlice, createSelector } from "@reduxjs/toolkit"
import { ListAlias } from "../../constants/enums"
import { PropType } from "../../utils/utils"
import { RootState } from "../store"
import { AHBCalculatorTypes } from "../../pages/Calculator/AHB/AHBCalculator"

interface IState {
    language: string
    filterMapping: any
    dateFormat: string
    error: boolean
    columnMapping: any
    calculator: PropType
    exportPreview: string
}

const initialState: IState = {
    error: false,
    language: "TH",
    calculator: {},
    columnMapping: {},
    filterMapping: {},
    dateFormat: "th-TH",
    exportPreview: "",
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setFilterMapping: (state, action) => {
            state.filterMapping = action.payload
        },
        setDateFormat: (state, action) => {
            state.dateFormat = action.payload
        },
        setErrorStatus: (state, action) => {
            state.error = action.payload
        },
        setExportPreview: (state, action) => {
            state.exportPreview = action.payload
        },
        setColumnMapping: (state, action) => {
            const { listAlias, data } = action.payload
            const x: any = { ...state.columnMapping }
            x[listAlias] = data
            state.columnMapping = x
        },
        addItemsToCalculator: (state, action) => {
            // Possible values for type: vaccine, medicine
            const { type, ...payload } = action.payload
            if (!state.calculator[type]) {
                state.calculator[type] = {
                    items: [payload],
                }
            } else {
                const exist = state.calculator[type].items.some((item: any) => {
                    return item.id === payload.id
                })

                if (!exist) {
                    state.calculator[type].items.push(payload)
                }
            }
        },
        removeItemFromCalculator: (state, action) => {
            // Possible values for type: vaccine, medicine
            const { type, id } = action.payload
            const items = state.calculator[type].items.filter(
                (item: any) => id !== item.id
            )

            state.calculator[type].items = items
        },
        addDetailsToCalculator: (state, action) => {
            // Possible values for type: vaccine, medicine
            const { type, ...payload } = action.payload
            state.calculator[type].details = payload
        },
        resetCalculator: (state, action) => {
            if (action.payload.type === "all") {
                state.calculator = {
                    vaccine: {
                        items: [],
                        details: {},
                    },
                    medicine: {
                        items: [],
                        details: {},
                    },
                }
            } else {
                delete state.calculator[action.payload.type]
            }
        },
    },
})

export default appSlice

export const {
    setLanguage,
    setDateFormat,
    setFilterMapping,
    setColumnMapping,
    setErrorStatus,
    resetCalculator,
    addItemsToCalculator,
    addDetailsToCalculator,
    removeItemFromCalculator,
    setExportPreview,
} = appSlice.actions

export const getLanguage = (state: any) => state.app.language
export const getDateFormat = (state: any) => state.app.dateFormat
export const getFilterMapping = (state: any) => state.app.filterMapping
export const getErrorStatus = (state: any) => state.app.error
export const getExportPreview = (state: any) => state.app.exportPreview
export const getColumnMapping = (state: any, listAlias: ListAlias) =>
    state.app.columnMapping[listAlias]

export const getCalculatorItemsCount = createSelector(
    (state: RootState) => state,
    (state: RootState) => {
        const vaccines = state.app.calculator?.vaccine?.items?.length || 0
        const medicines = state.app.calculator?.medicine?.items?.length || 0

        return {
            vaccines,
            medicines,
            total: vaccines + medicines,
        }
    }
)

export const getCalculatorItems = createSelector(
    (state: RootState) => state,
    (_: RootState, type: AHBCalculatorTypes) => type,
    (state: RootState, type) => state.app.calculator[type]?.items || []
)

export const getCalculatorDetails = createSelector(
    (state: RootState) => state,
    (_: RootState, type: AHBCalculatorTypes) => type,
    (state: RootState, type) => state.app.calculator[type]?.details || {}
)
