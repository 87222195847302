import { useNavigate } from "react-router-dom"
import { PageNotFound } from "../../assets/images"
import Button from "../../components/common/Button/Button"
import { RoutePaths } from "../../configs/route"
import "./NotFound.scss"
import { useTranslation } from "react-i18next"
const NotFound = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const handleClick = () => {
        navigate(RoutePaths.HOME)
    }

    return (
        <div className="nf-container">
            <img
                alt={t("page_not_found")}
                src={PageNotFound}
                width={67}
                height={67}
                className="nf-img"
            />
            <span className="nf-title">{t("page_not_found")}</span>
            <span className="nf-content">{t("page_not_found_content")}</span>
            <br />

            <Button className="nf-button" onClick={handleClick}>
                {t("page_not_found_button_label")}
            </Button>
        </div>
    )
}

export default NotFound
