import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import "./ErrorDialog.scss"
import Button from "../Button/Button"
import { useState } from "react"
import style from "../../../styles/_variable.scss"

type ErrorDialog = {
    title: string
    img: string
    message: string
    buttonText: string
    closeHandler?: () => void
}
export default function ErrorDialog({
    title,
    img,
    message,
    buttonText,
    closeHandler,
}: ErrorDialog) {
    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
        closeHandler && closeHandler()
    }
    return (
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "332px",
                    },
                },
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className="error-dialog-title">
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className="error-close-icon"
                    data-testid="error-close-icon"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: style.gray80,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="error-dialog-content">
                <img alt="" src={img} width={64} height={64} />
                <div className="dialog-title">{title}</div>
                <div className="dialog-message">{message}</div>
                <Button
                    variant={"contained"}
                    className="ok-btn"
                    fullWidth={true}
                    onClick={handleClose}
                >
                    {buttonText}
                </Button>
            </DialogContent>
        </Dialog>
    )
}
