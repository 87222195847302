import { GA4EventType, SvgIcons } from "../../../constants/enums"
import style from "../../../styles/_variable.scss"
import { ga4GA } from "../../../utils/utils"
import SvgIcon from "../../common/SvgIcon/SvgIcon"
import "./ProductCatalogTopBar.scss"
import { useTranslation } from "react-i18next"
type PropTypes = {
    count: number
    title: string
    toggleDrawer?: any
    state?: any
    isActive: boolean
}
const ProductCatalogTopBar = ({
    title,
    count = 0,
    toggleDrawer,
    isActive,
}: PropTypes) => {
    const { t } = useTranslation()
    return (
        <section className="catalog-top-bar">
            <div>
                <h1 className="category-title">{title}</h1>
                <div className="product-count">
                    {count} {t("products found")}
                </div>
            </div>
            <div className="filter-button">
                <div
                    onClick={() => {
                        toggleDrawer("top", true)
                        ga4GA(GA4EventType.SEND, {
                            eventAction: "filter_icon_click",
                            catalog_type: title,
                            target: "filter",
                        })
                    }}
                    data-testid="filter-button"
                >
                    <SvgIcon
                        color={isActive ? style.green : style.gray60}
                        name={SvgIcons.FILTER}
                    />
                </div>
            </div>
        </section>
    )
}

export default ProductCatalogTopBar
