import { datadogRum } from "@datadog/browser-rum"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./i18n/i18n"
import reportWebVitals from "./reportWebVitals"
import "./styles/index.scss"

// import store for redux toolkit
import { PublicClientApplication } from "@azure/msal-browser"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import AuthProvider from "./components/shared/AuthProvider/AuthProvider"
import { msalConfig } from "./configs/auth"
import { DATADOG_CONFIG } from "./configs/datadog"
import { persistor, store } from "./redux/store"
import AppRoutes from "./routes/AppRoutes"
import { ga4Initialize } from "./utils/utils"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const msalInstance = new PublicClientApplication(msalConfig)
ga4Initialize(window.REACT_APP_GA4_MEASUREMENT_TRACKING_ID!)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <AuthProvider instance={msalInstance}>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </AuthProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
)

// Uncomment to use RUM datadog
datadogRum.startSessionReplayRecording()
datadogRum.init(DATADOG_CONFIG)

reportWebVitals()
