import "./FeedPackage.scss"
import { useEffect } from "react"
import { Divider } from "@mui/material"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../../../configs/route"
import { replacePathParams } from "../../../../utils/utils"
import { setPageConfig } from "../../../../redux/slices/pageSlice"
import RouteLink from "../../../../components/common/RouteLink/RouteLink"
import { packageCatagories } from "../../../../components/shared/ProductCategoryCard/ProductCategoryConfig"

const FeedPackage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { category } = useParams()

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("select_feed_package"),
                heading: t("select_feed_package"),
                rightIcon: false,
                backButtonLink: replacePathParams(
                    RoutePaths.FEED_CALCULATOR_CATEGORY,
                    {
                        category,
                    }
                ),
            })
        )
    }, [])

    return (
        <div className="feed-package">
            <section className="categories">
                {packageCatagories.map((item: any, idx: number) => (
                    <div
                        key={idx}
                        className="package-category-card"
                        style={{ backgroundColor: item.backgroundColor }}
                    >
                        <RouteLink
                            to={replacePathParams(item.link, {
                                category,
                                package: item.key,
                            })}
                        >
                            <h3 className="title">{item.name}</h3>
                            <Divider orientation="vertical" />
                            <div className="descr">{item.text}</div>
                            <div className="image">
                                <img
                                    src={item.thumbnail}
                                    alt="category image"
                                />
                            </div>
                        </RouteLink>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default FeedPackage
