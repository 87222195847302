export const DATADOG_CONFIG: any = {
    applicationId: window.REACT_APP_DATADOG_APP_ID,
    clientToken: window.REACT_APP_DATADOG_CLI_TOKEN,
    site: window.REACT_APP_DATADOG_SITE,
    service: window.REACT_APP_DATADOG_SERVICE,
    env: window.REACT_APP_NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: window.REACT_APP_GIT_SHA,
    sessionSampleRate: Number(
        window.REACT_APP_DATADOG_SESSION_SAMPLE_RATE
    ),
    premiumSampleRate: Number(
        window.REACT_APP_DATADOG_PREMIUM_SAMPLE_RATE
    ),
    trackUserInteractions: true,
    defaultPrivacyLevel: window.REACT_APP_DATADOG_DEFAULT_PRIVACY_LEVEL,
}
