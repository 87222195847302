import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import ListItemButton from "@mui/material/ListItemButton"
import "./ListView.scss"

const ListView = ({
    productDetails,
    showBulletPoint = false,
    rightChildren,
}: {
    productDetails: string[]
    showBulletPoint?: boolean
    rightChildren?: any
}) => {
    const showHyphen = (item: string, idx: number) => {
        let bool = true

        if (idx === 0 && item === "-") {
            bool = true
        } else if (item === "-") {
            bool = false
        }
        return bool
    }

    return (
        <List
            dense
            className="listview"
            sx={
                showBulletPoint
                    ? {
                          width: "100%",
                          bgcolor: "background.paper",
                          listStyleType: "disc",
                          pl: 4,
                      }
                    : {}
            }
        >
            {productDetails.map((item, idx) => {
                return (
                    showHyphen(item, idx) && (
                        <ListItem
                            key={idx}
                            className="display-list"
                            disablePadding
                        >
                            <ListItemButton className="acc-padding-none">
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            variant="body2"
                                            className="list-text"
                                        >
                                            {item} {rightChildren}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                )
            })}
        </List>
    )
}

export default ListView
