import { useMsal } from "@azure/msal-react"
import { LoginBg, LoginBgLg, LogoFull } from "../../../assets/images"
import Button from "../../../components/common/Button/Button"
import { tokenRequest } from "../../../configs/auth"
import "./Login.scss"
import { useTranslation } from "react-i18next"

const Login = () => {
    const { instance } = useMsal()
    const { t } = useTranslation()

    const handleLogin = () => {
        instance.loginRedirect(tokenRequest)
    }

    return (
        <>
            <section className="login-screen">
                <div className="bg-gradient">
                    <picture>
                        <source media="(min-width:744px)" srcSet={LoginBgLg} />
                        <img
                            src={LoginBg}
                            alt="Login Background"
                            className="w-100"
                        />
                    </picture>
                </div>
                <div className="card">
                    <section className="content">
                        <div className="logo">
                            <img src={LogoFull} alt="Login Screen Logo" />
                        </div>
                        <h3 className="title">{t("login_title")}</h3>
                    </section>
                    <section className="button-container">
                        <Button
                            variant={"contained"}
                            className="login-btn"
                            onClick={handleLogin}
                            fullWidth={true}
                        >
                            {t("login_button_text")}
                        </Button>
                    </section>
                </div>
            </section>
        </>
    )
}

export default Login
