import {
    Pagination as MuiPagination,
    Box,
    PaginationProps,
} from "@mui/material"
import "./Pagination.scss"

const Pagination = ({
    count = 0,
    variant = "outlined",
    shape = "rounded",
    size = "medium",
    defaultPage = 1,
    siblingCount = 0,
    onChange,
    page,
}: PaginationProps) => {
    if (count <= 1) {
        return null
    }

    return (
        <Box className="pagination-bar">
            <MuiPagination
                count={count}
                variant={variant}
                shape={shape}
                size={size}
                defaultPage={defaultPage}
                siblingCount={siblingCount}
                data-testid={"pagination"}
                onChange={onChange}
                page={page}
            />
        </Box>
    )
}

export default Pagination
