import {
    Box,
    Checkbox,
    Divider,
    Drawer,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LeftArrow } from "../../../assets/images"
import { useSearchParams } from "react-router-dom"
import { IurlParams } from "../../../pages/ProductCatalog/ProductCatalog"
import { PropType, convertToQueryString } from "../../../utils/utils"
import FilterAccordion from "../Accordion/FilterAccordion"
import Button from "../Button/Button"
import "./Filters.scss"

interface IFilterDrawer {
    toggleDrawer: any
    drawerState: any
    filters: PropType
    urlParams: IurlParams | PropType
    handleLoading: any
}

const FilterDrawer = ({
    toggleDrawer,
    drawerState,
    filters,
    handleLoading,
}: IFilterDrawer) => {
    const anchor = "top"
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const orderParam = searchParams.get("order")
    const [order, setOrder] = useState<string | null>(orderParam)
    const [state, setState] = useState(() => {
        try {
            const data = JSON.parse(JSON.stringify(filters))

            const params: any = {}
            searchParams.forEach((value: string, key: string) => {
                if (key !== "order" && key !== "q") {
                    params[key] = value.split(",")
                }
            })

            if (Object.keys(params).length) {
                Object.entries(data.filters).forEach(([key, value]: any) => {
                    data.filters[key].options = value.options.map((i: any) => {
                        if (
                            params[value.name] &&
                            params[value.name].includes(String(i.value))
                        ) {
                            i.checked = true
                            data.filters[key].expand = true
                        } else {
                            i.checked = false
                        }

                        return i
                    })
                })
            }

            return data
        } catch (e) {
            return {}
        }
    })

    useEffect(() => {
        if (orderParam !== order) setOrder(orderParam)
    }, [drawerState])

    const handleSubmit = (event: any) => {
        event.preventDefault()

        const queryParams: any = {}

        if (order) {
            queryParams["order"] = order
        }
        Object.values(state.filters).forEach((value: any) => {
            const checked: string[] = []
            value.options.forEach((i: any) => {
                if (i.checked) {
                    checked.push(i.value)
                }
            })

            if (checked.length) {
                if (value.name in queryParams) {
                    queryParams[value.name] =
                        queryParams[value.name].concat(checked)
                } else {
                    queryParams[value.name] = checked
                }
            }
        })

        setSearchParams(convertToQueryString(queryParams))
        toggleDrawer("top", false)
        handleLoading()
    }

    const handleReset = () => {
        setState(filters)
        setOrder(null)
    }

    const handleClear = (e: any) => {
        e.stopPropagation()
    }

    return (
        <Drawer
            anchor={"top"}
            open={drawerState["top"]}
            className="filter-drawer"
            onClose={() => {
                toggleDrawer("top", false)
            }}
        >
            <div className="filter-container">
                <section className="filter-header">
                    <div
                        className="backBtn"
                        data-testid="backButton"
                        onClick={() => {
                            toggleDrawer(anchor, false)
                        }}
                    >
                        <img src={LeftArrow} alt="back" />
                    </div>
                    <div className="filter-heading">{t("filter")}</div>
                    <div className="vhidden">
                        <img src={LeftArrow} />
                    </div>
                </section>

                <Box
                    component="form"
                    role="presentation"
                    onSubmit={(event) => {
                        handleSubmit(event)
                    }}
                    sx={{
                        width: anchor === "top" ? "auto" : 450,
                        height: "100%",
                    }}
                >
                    <div className="calculate-toggle">
                        <div className="toggle-heading">
                            {t("available_to_calculate")}
                        </div>
                        <Switch disabled checked />
                    </div>
                    <Divider />
                    {filters.sorting === true && (
                        <>
                            <div className="sort-typography">
                                {t("sort_by")}
                            </div>

                            <section className="sorting-options">
                                <RadioGroup
                                    defaultValue="aToz"
                                    value={order ?? "asc"}
                                    name="radio-buttons-group"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    onChange={(e: any) => {
                                        setOrder(e.target.value)
                                    }}
                                >
                                    <FormControlLabel
                                        value="asc"
                                        label="A to Z"
                                        control={<Radio />}
                                        labelPlacement="start"
                                        {...((order === "asc" ||
                                            order == null) && {
                                            className: "active",
                                        })}
                                    />
                                    <FormControlLabel
                                        value="desc"
                                        label="Z to A"
                                        control={<Radio />}
                                        labelPlacement="start"
                                        {...(order === "desc" && {
                                            className: "active",
                                        })}
                                    />
                                </RadioGroup>
                            </section>
                            <Divider />
                        </>
                    )}

                    {Object.entries(state.filters).map(
                        ([key, value]: any, index: number) => (
                            <Fragment key={index}>
                                <FilterAccordion
                                    title={key}
                                    count={value.options.length}
                                    expand={value.expand || false}
                                    {...(value.clear && {
                                        handleClear: handleClear,
                                    })}
                                >
                                    <section className="filter-options">
                                        {value.options.map(
                                            (item: any, index: number) => (
                                                <div
                                                    key={index}
                                                    className="checkbox-container"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                value={
                                                                    item.value
                                                                }
                                                                name={
                                                                    value.name
                                                                }
                                                                checked={
                                                                    item.checked ||
                                                                    false
                                                                }
                                                                disabled={
                                                                    item.disabled ||
                                                                    false
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setState(
                                                                        (
                                                                            prev: PropType
                                                                        ) => {
                                                                            const val =
                                                                                {
                                                                                    ...value,
                                                                                }
                                                                            val.options[
                                                                                index
                                                                            ].checked =
                                                                                e.target.checked
                                                                            return {
                                                                                ...prev,
                                                                                [key]: val,
                                                                            }
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                        label={item.label}
                                                        {...(item.checked && {
                                                            className: "active",
                                                        })}
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            )
                                        )}
                                    </section>
                                </FilterAccordion>
                                <Divider />
                            </Fragment>
                        )
                    )}

                    <Stack
                        spacing={2}
                        direction="row"
                        className="bottom-controls"
                    >
                        <Button
                            variant="outlined"
                            onClick={handleReset}
                            className="reset-btn"
                        >
                            {t("reset")}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            className="done-btn"
                        >
                            {t("done")}
                        </Button>
                    </Stack>
                </Box>
            </div>
        </Drawer>
    )
}

export default FilterDrawer
