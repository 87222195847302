import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import "./Dialog.scss"
import style from "../../../styles/_variable.scss"

type IDialog = {
    open: boolean
    title: string
    handleClose: () => void
    children: React.ReactNode
}
export default function AlertDialog({
    open,
    title,
    handleClose,
    children,
}: IDialog) {
    return (
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                    },
                },
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                <span className="dialog-span">{title}</span>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className="close-icon"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: style.gray80,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">{children}</DialogContent>
        </Dialog>
    )
}
