import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Chips from "../../components/common/Chips/Chips"
import FilterDrawer from "../../components/common/Filters/Filters"
import Loader from "../../components/common/Loader/Loader"
import ProductCatalogList from "../../components/shared/ProductCatalogList/ProductCatalogList"
import ProductCatalogTopBar from "../../components/shared/ProductCatalogTopBar/ProductCatalogTopBar"
import { api } from "../../configs/api"
import { RoutePaths } from "../../configs/route"
import { ListAlias, ProductCategory } from "../../constants/enums"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"
import useGetListId from "../../hooks/useGetListId"
import useMakeChunkRequest from "../../hooks/useMakeChunkRequest"
import { useGetListItemDetailsQuery } from "../../redux/api/listApiSlice"
import {
    getFilterMapping,
    getLanguage,
    setColumnMapping,
    setErrorStatus,
} from "../../redux/slices/appSlice"
import { setProducts } from "../../redux/slices/productRecommendationSlice"
import {
    escapeSingleQuotes,
    getColumnName,
    htmlEncodeSingleQuotes,
    replacePathParams,
} from "../../utils/utils"
import "./ProductCatalog.scss"
import { setPageConfig } from "../../redux/slices/pageSlice"

export type PropTypes = { productCategory: ProductCategory; filters?: any }
export type Anchor = "top" | "left" | "bottom" | "right"

export type StringOrNull = string | null
export interface IurlParams {
    filters: StringOrNull
    energy: StringOrNull
    protein: StringOrNull
    lysine: StringOrNull
    supplier: StringOrNull
    delivery: StringOrNull
    warranty: StringOrNull
    order: StringOrNull
    search: StringOrNull
}

export const chipsData = [
    {
        label: ProductCategory.AHB,
    },
    {
        label: ProductCategory.FEED,
    },
    {
        label: ProductCategory.FARM_EQUIPMENT,
    },
    {
        label: ProductCategory.LAB_SERVICE,
    },
]

const getConfiguration = (category: ProductCategory) => {
    const config = {
        [ProductCategory.FEED]: {
            link: RoutePaths.FEED_DETAILS,
            listAlias: ListAlias.FEEDS,
            titleColumnAlias: "FEEDTYPE",
        },
        [ProductCategory.AHB]: {
            link: RoutePaths.AHB_DETAILS,
            listAlias: ListAlias.AHBS,
            titleColumnAlias: "PRODUCT_NAME",
        },
        [ProductCategory.FARM_EQUIPMENT]: {
            link: RoutePaths.FARM_EQUIPMENTS_DETAILS,
            listAlias: ListAlias.FARM_EQUIPMENTS,
            titleColumnAlias: "PRODUCT_NAME",
        },
        [ProductCategory.LAB_SERVICE]: {
            link: RoutePaths.LAB_SERVICES_DETAILS,
            listAlias: ListAlias.LAB_SERVICES,
            titleColumnAlias: "TEST_NAME",
        },
    }

    return config[category]
}

const ProductCatalog = ({ productCategory: category }: PropTypes) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const language = useSelector(getLanguage)
    const filterMapping = useSelector(getFilterMapping)
    const tagListId = useGetListId(ListAlias.TAGS)
    const productGroupListId = useGetListId(ListAlias.PRODUCT_GROUPS)
    const isRecommendationPage = location.pathname.includes("tags")
    const [drawerState, setDrawerState] = useState({
        top: false,
    })

    const sortOrder = searchParams.get("order") ?? "asc"
    const urlParams: IurlParams = {
        filters: searchParams.get("filters"),
        energy: searchParams.get("energy"),
        protein: searchParams.get("protein"),
        lysine: searchParams.get("lysine"),
        supplier: searchParams.get("supplier"),
        delivery: searchParams.get("delivery"),
        warranty: searchParams.get("warranty"),
        order: searchParams.get("order"),
        search: searchParams.get("q"),
    }


    const isFilter =
        searchParams.has("filters") ||
        searchParams.has("energy") ||
        searchParams.has("protein") ||
        searchParams.has("lysine") ||
        searchParams.has("supplier") ||
        searchParams.has("delivery") ||
        searchParams.has("warranty") ||
        searchParams.has("order")

    const { id: tagId, category: URLCategory } = useParams()
    let productCategory: ProductCategory = category
    if (isRecommendationPage) {
        productCategory =
            Object.values(ProductCategory).find((item: any) => {
                return (
                    URLCategory ===
                    item.replaceAll(" ", "-").toLowerCase() + "s"
                )
            }) ?? ProductCategory.AHB
    }

    const { link, listAlias, titleColumnAlias } =
        getConfiguration(productCategory)
    const listId = useGetListId(listAlias)

    const {
        isError: productGroupColumnError,
        isFetching: productGroupColumnLoading,
        columnMapping: productGroupColumnsMapping,
    } = useGetColumnMapping(
        isRecommendationPage
            ? null
            : urlParams.filters
                ? productGroupListId
                : null,
        "product-groups"
    )

    const {
        choices,
        columnMapping,
        isError: productColumnError,
        isFetching: productColumnLoading,
    } = useGetColumnMapping(listId, "products")

    const {
        isError: productTagsColumnError,
        isFetching: productTagsColumnLoading,
        columnMapping: productTagsColumnMapping,
    } = useGetColumnMapping(
        isRecommendationPage ? tagListId : null,
        "product-tags"
    )

    const toggleDrawer = (anchor: Anchor, open: boolean) => {
        setDrawerState({ ...drawerState, [anchor]: open })
    }

    const onClickChipItem = (value: any) => {
        navigate(
            replacePathParams(RoutePaths.PRODUCT_RECOMMENDATION_RESULT, {
                id: tagId,
                category: value.replaceAll(" ", "-").toLowerCase() + "s",
            })
        )
    }

    const tagCategoryField = useMemo(() => {
        let categoryField = ""
        if (!Object.keys(productTagsColumnMapping).length) {
            return categoryField
        }

        switch (productCategory) {
            case ProductCategory.FEED: {
                categoryField = getColumnName(
                    productTagsColumnMapping,
                    'TH',
                    "FEEDS"
                )
                break
            }
            case ProductCategory.AHB: {
                categoryField = getColumnName(
                    productTagsColumnMapping,
                    'TH',
                    "AHBS"
                )
                break
            }
            case ProductCategory.FARM_EQUIPMENT: {
                categoryField = getColumnName(
                    productTagsColumnMapping,
                    'TH',
                    "FARM_EQUIPMENTS"
                )
                break
            }
            case ProductCategory.LAB_SERVICE: {
                categoryField = getColumnName(
                    productTagsColumnMapping,
                    'TH',
                    "LAB_SERVICES_PR"
                )
                break
            }
        }
        return categoryField
    }, [productTagsColumnMapping, language, productCategory])


    const categoryField = useMemo(() => {
        let categoryField = ""
        if (!Object.keys(productGroupColumnsMapping).length) {
            return categoryField
        }

        switch (productCategory) {
            case ProductCategory.FEED: {
                categoryField = getColumnName(
                    productGroupColumnsMapping,
                    'TH',
                    "FEEDS"
                )
                break
            }
            case ProductCategory.AHB: {
                categoryField = getColumnName(
                    productGroupColumnsMapping,
                    'TH',
                    "AHBS"
                )
                break
            }
            case ProductCategory.FARM_EQUIPMENT: {
                categoryField = getColumnName(
                    productGroupColumnsMapping,
                    'TH',
                    "FARM_EQUIPMENTS"
                )
                break
            }
            case ProductCategory.LAB_SERVICE: {
                categoryField = getColumnName(
                    productGroupColumnsMapping,
                    'TH',
                    "LAB_SERVICES"
                )
                break
            }
        }

        return categoryField
    }, [productCategory, language, productGroupColumnsMapping])

    const imageField = getColumnName(columnMapping, language, "PICTURE")
    const titleFieldEN = getColumnName(columnMapping, 'EN', titleColumnAlias)
    const titleFieldTH = getColumnName(columnMapping, 'TH', titleColumnAlias)
    const tagField = getColumnName(productTagsColumnMapping, language, "TAG")
    
    const productGroupFilterString = (filters: any[] = []) => {
        return filters
            .map((id: any) => {
                if (id in filterMapping) {
                    return `fields/${titleFieldTH} eq '${escapeSingleQuotes(
                        filterMapping[id]
                    )}'`
                }
            })
            .join(" or ")
    }

    const productSearchString = () => {
        return `startswith(fields/${titleFieldEN}, '${urlParams.search}') or startswith(fields/${titleFieldTH}, '${urlParams.search}')`
    }

    const _productGroupFilterString = productGroupFilterString(
        urlParams.filters?.split(",")
    )


    // Product tags api call
    const {
        data: productTagsData,
        isError: productTagsError,
        isFetching: productTagsLoading,
    } = useGetListItemDetailsQuery(
        {
            itemId: tagId,
            listId: tagListId,
            listName: "product-tags",
            $expand: `fields($select=Title,${tagField},${tagCategoryField})`,
        },
        { skip: !isRecommendationPage || productTagsColumnLoading }
    )

    // Product groups api call
    const {
        data: productGroupData,
        isError: productGroupError,
        isLoading: productGroupLoading,
    } = useMakeChunkRequest(
        api.GET_LIST_ITEMS,
        {
            listName: "product-groups",
            listId: productGroupListId,
            $expand: `fields($select=Title,${titleFieldEN},${categoryField})`,
            $filter: _productGroupFilterString,
        },
        { skip: !urlParams.filters || productGroupColumnLoading }
    )

    const productFilterString = useMemo(() => {
        const filter: any[] = []

        const addFilter = (urlParam: StringOrNull, columnAlias: string) => {
            if (urlParam && Object.keys(columnMapping).length) {
                filter.push(
                    urlParam
                        .split(",")
                        .map(
                            (item: any) =>
                                `fields/${getColumnName(
                                    columnMapping,
                                    'EN',
                                    columnAlias
                                )} eq '${item}'`,
                        )
                        .join(" or ")
                )
            }
        }

        if (productGroupData.length && urlParams.filters) {
            // Union all products
            const products = productGroupData
                ? productGroupData.reduce((acc: any, curr: any) => {
                    if (categoryField in curr.fields) {
                        curr.fields[categoryField].forEach((item: any) => {
                            acc.push(item?.LookupValue)
                        })
                    }
                    // Remove duplicates
                    const x: any = new Set(acc)
                    return [...x]
                }, [])
                : []

            filter.push(
                products
                    .map(
                        (item: any) =>
                            `fields/${titleFieldTH} eq '${htmlEncodeSingleQuotes(item)}'`
                    )
                    .join(" or ")
            )
        }

        if (
            isRecommendationPage &&
            productTagsData &&
            tagCategoryField in productTagsData.fields
        ) {
            // Union all products
            const products = productTagsData.fields[tagCategoryField].reduce(
                (acc: any, curr: any) => {
                    acc.push(curr?.LookupValue)
                    // Remove duplicates
                    const x: any = new Set(acc)
                    return [...x]
                },
                []
            )

            filter.push(
                products
                    .map(
                        (item: any) =>
                            `fields/${titleFieldTH} eq '${htmlEncodeSingleQuotes(item)}'`
                    )
                    .join(" or ")
            )
        }

        if (productCategory === ProductCategory.FEED) {
            addFilter(urlParams.energy, "ENERGY")
            addFilter(urlParams.protein, "NUTRITION_PROTEIN")
            addFilter(urlParams.lysine, "LYSINE")
        } else if (productCategory === ProductCategory.AHB) {
            addFilter(urlParams.supplier, "SUPPLIER")
        } else if (productCategory === ProductCategory.FARM_EQUIPMENT) {
            addFilter(urlParams.delivery, "DELIVERY_METHOD")
            addFilter(urlParams.warranty, "WARRANTY")
        }

        if (filter.length) return filter.join(" or ")
    }, [
        language,
        urlParams,
        columnMapping,
        productCategory,
        productTagsData,
        tagCategoryField,
        productGroupData,
    ])

    const productQuerySkip = () => {
        if (urlParams.filters) {
            return (
                productColumnLoading ||
                productGroupColumnLoading ||
                productGroupLoading
            )
        }

        if (isRecommendationPage) {
            return (
                productTagsColumnLoading ||
                productTagsLoading ||
                productColumnLoading ||
                !productFilterString
            )
        }

        return productColumnLoading
    }

    // Product api call
    const {
        data: productData,
        isError: productError,
        isLoading: productLoading,
    } = useMakeChunkRequest(
        api.GET_LIST_ITEMS,
        {
            listId,
            listName: "products",
            $expand: `fields($select=Title,${imageField},${titleFieldEN})`,
            $filter: urlParams.search
                ? productSearchString()
                : productFilterString,
            $sortOrder: sortOrder,
        },
        {
            skip: productQuerySkip(),
        }
    )

    // Set product data as empty in case of tags page with prevented API call (missing data with selected category)
    let _productData =
        isRecommendationPage && !productFilterString ? [] : productData


    // manage product recommendation search results
    if (
        isRecommendationPage &&
        urlParams.search &&
        tagCategoryField &&
        productTagsData
    ) {
        const data =
            tagCategoryField in productTagsData.fields
                ? productTagsData.fields[tagCategoryField].map(
                    (item: any) => item?.LookupValue
                )
                : []
        _productData = _productData.filter((item: any) =>
            data.includes(item?.fields?.Title)
        )
    }

    // save recommendation products in redux to display suggestions
    useEffect(() => {
        if (isRecommendationPage && tagCategoryField && productTagsData) {
            const data =
                tagCategoryField in productTagsData.fields
                    ? productTagsData.fields[tagCategoryField].map(
                        (item: any) => item?.LookupValue
                    )
                    : []
            dispatch(setProducts(data))
        }
    }, [productTagsData, tagCategoryField])

    const getTitle = () => {
        if (isRecommendationPage) {
            return  language === "TH" ? productTagsData?.fields?.Title : productTagsData?.fields[tagField]
        }

        return urlParams.search ?? productCategory
    }

    const isLoading = () => {
        return (
            productLoading ||
            productGroupLoading ||
            productColumnLoading ||
            productGroupColumnLoading ||
            productTagsColumnLoading ||
            productTagsLoading
        )
    }

    const isError = () => {
        return (
            productError ||
            productGroupError ||
            productColumnError ||
            productGroupColumnError ||
            productTagsError ||
            productTagsColumnError
        )
    }

    useEffect(() => {
        dispatch(setErrorStatus(isError()))
    }, [
        productError,
        productGroupError,
        productColumnError,
        productGroupColumnError,
        productTagsColumnError,
        productTagsError,
    ])

    // save products list column mapping into redux state
    useEffect(() => {
        if (Object.keys(columnMapping).length) {
            dispatch(
                setColumnMapping({
                    listAlias,
                    data: columnMapping,
                })
            )
        }
    }, [columnMapping])

    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: "drawer-search",
            })
        )
    }, [])
    return (
        <Loader show={isLoading()}>
            <div className="page-product-catalog">
                <ProductCatalogTopBar
                    count={_productData.length}
                    title={getTitle()}
                    toggleDrawer={toggleDrawer}
                    isActive={isFilter}
                />
                {isRecommendationPage && (
                    <Chips
                        data={chipsData}
                        onClick={onClickChipItem}
                        active={productCategory}
                    />
                )}

                <ProductCatalogList
                    toggleDrawer={toggleDrawer}
                    data={_productData}
                    link={link}
                    type={productCategory}
                    titleField={titleFieldEN}
                    imageField={imageField}
                    isFilter={isFilter}
                />

                <FilterDrawer
                    category={productCategory}
                    toggleDrawer={toggleDrawer}
                    drawerState={drawerState}
                    urlParams={urlParams}
                    choices={choices}
                />
            </div>
        </Loader>
    )
}

export default ProductCatalog
