import { useMsal } from "@azure/msal-react"
import { Navigate, Outlet } from "react-router-dom"
import { RoutePaths } from "../configs/route"

const ProtectedRoute = () => {
    const { accounts } = useMsal()

    return accounts.length === 0 ? (
        <Navigate to={RoutePaths.HOME} />
    ) : (
        <Outlet />
    )
}

export default ProtectedRoute
