import { faRotateRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Divider, FormControlLabel } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../../../components/common/Button/Button"
import Chips from "../../../../components/common/Chips/Chips"
import Loader from "../../../../components/common/Loader/Loader"
import { RoutePaths } from "../../../../configs/route"
import Image from "../../../../components/common/Image/Image"
import { ListAlias, getRelativeKeys } from "../../../../constants/enums"
import useGetColumnMapping from "../../../../hooks/useGetColumnMapping"
import useGetListId from "../../../../hooks/useGetListId"
import { useGetListItemsQuery } from "../../../../redux/api/listApiSlice"
import { getLanguage, setErrorStatus } from "../../../../redux/slices/appSlice"
import { setPageConfig } from "../../../../redux/slices/pageSlice"
import {
    PropType,
    getColumnName,
    getFieldValue,
    getImageUrl,
    getPlaceholderImage,
    replacePathParams,
} from "../../../../utils/utils"
import "./SelectFeed.scss"
import { RootState } from "../../../../redux/store"
import {
    Packages,
    getProducts,
    setProducts,
} from "../../../../redux/slices/feedCalculatorSlice"
import Pagination from "../../../../components/common/Pagination/Pagination"
import NoResultFound from "../../../../components/common/NoResultFound/NoResultFound"
import { NoResultCross } from "../../../../assets/images"
const SelectFeed = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { category, package: packageType } = useParams()
    const [tabData, setTabData] = useState<PropType>({})
    const [tab, setTab] = useState("")
    const [page, setPage] = useState<number>(1)
    const listId = useGetListId(ListAlias.FEED_CALCULATION)
    const language = useSelector(getLanguage)
    const products = useSelector((state: RootState) => {
        return getProducts(state, packageType as Packages)
    })
    const navigate = useNavigate()
    const perPage = 20

    const updatedProducts = products?.updated

    // API calls for feed calculation list
    const {
        choices,
        columnMapping,
        isError: columnError,
        isFetching: columnLoading,
    } = useGetColumnMapping(listId, "feed-calculation")

    const imageField = getColumnName(columnMapping, language, "PICTURE")
    const titleField = columnMapping[getRelativeKeys.TITLE]
    const swinePeriodField = getColumnName(
        columnMapping,
        language,
        "SWINE_PERIOD"
    )

    const platinumPackageField =
        columnMapping[getRelativeKeys.PLATINUM_DEFAULT_AMOUNT]
    const goldPackageField = columnMapping[getRelativeKeys.GOLD_DEFAULT_AMOUNT]
    const silverPackageField =
        columnMapping[getRelativeKeys.SILVER_DEFAULT_AMOUNT]

    const packageField =
        packageType === "platinum"
            ? platinumPackageField
            : packageType === "gold"
            ? goldPackageField
            : packageType === "silver"
            ? silverPackageField
            : null

    const {
        data,
        isError: dataError,
        isFetching: dataLoading,
    } = useGetListItemsQuery(
        {
            listId,
            listName: "feed-calculation",
            $expand: `fields($select=${titleField},${packageField},${swinePeriodField},${imageField})`,
        },
        { skip: columnLoading }
    )

    const swinePeriodChoices = Object.keys(choices).length
        ? getFieldValue(choices, language, "SWINE_PERIOD")
        : []

    const chipsData = swinePeriodChoices.map((item: string) => ({
        label: item,
    }))

    useEffect(() => {
        if (Object.keys(choices).length) {
            setTab(swinePeriodChoices.length ? swinePeriodChoices[0] : "")
        }
    }, [choices])

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("select_feed"),
                heading: t("select_feed"),
                rightIcon: false,
                backButtonLink: replacePathParams(
                    RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE,
                    { category, package: packageType }
                ),
            })
        )
    }, [])

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    // setting all tabs data in tab state
    useEffect(() => {
        if (data?.value.length) {
            const obj: any = {}
            swinePeriodChoices.forEach((item: string) => {
                obj[item] = data?.value
                    .filter(
                        (x: PropType) => x.fields[swinePeriodField] === item
                    )
                    .map((item: PropType) => ({
                        id: item?.id,
                        title: item?.fields[titleField],
                        image: item?.fields[imageField],
                        amount_of_feed: item?.fields[packageField],
                        feed_additive_number: 0,
                        checked: updatedProducts.some(
                            (x: PropType) => x?.id === item?.id
                        ),
                    }))
            })
            setTabData(obj)
        }
    }, [data])

    const handleClear = () => {
        setTabData(
            swinePeriodChoices.reduce((acc: PropType, item: string) => {
                acc[item] = tabData[item].map((_item: PropType) => ({
                    ..._item,
                    checked: false,
                }))

                return acc
            }, {})
        )
    }

    const toggleCheckBox = (id: string, checked: boolean) => {
        setTabData({
            ...tabData,
            [tab]: tabData[tab].map((item: PropType) => {
                if (item?.id === id) {
                    return { ...item, checked: !checked }
                } else {
                    return item
                }
            }),
        })
    }

    const addOnClick = () => {
        let allTabsData = swinePeriodChoices.reduce(
            (acc: any[], item: string) => {
                const items = tabData[item].filter((x: PropType) => x?.checked)
                if (items.length) {
                    acc = [...acc, ...items]
                }
                return acc
            },
            []
        )

        // manage product data persistance - if data is present in redux state then use that data
        const existingProducts = updatedProducts.filter((item: PropType) =>
            allTabsData.some((x: PropType) => x?.id === item?.id)
        )

        if (existingProducts.length) {
            const newProducts = allTabsData.filter(
                (item: PropType) =>
                    !existingProducts.some((x: PropType) => x?.id === item?.id)
            )

            allTabsData = [...existingProducts, ...newProducts]
        }

        dispatch(
            setProducts({
                type: packageType,
                products: allTabsData,
            })
        )
        navigate(
            replacePathParams(RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE, {
                category,
                package: packageType,
            })
        )
    }

    const checkedItems = useMemo(() => {
        const checkedItems = swinePeriodChoices.reduce(
            (acc: any[], item: string) => {
                const items = tabData[item].filter((x: PropType) => x?.checked)
                if (items.length) {
                    acc = [...acc, ...items]
                }
                return acc
            },
            []
        )
        return checkedItems.length
    }, [tabData])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    // manage pagination
    const { pages = 0, paginatedData = [] } = useMemo(() => {
        const currentTabData = tabData[tab] ?? []
        if (currentTabData.length === 0) {
            return {}
        }

        const pages = Math.ceil(currentTabData.length / perPage)

        return {
            pages,
            paginatedData: currentTabData.slice(
                (page - 1) * perPage,
                (page - 1) * perPage + perPage
            ),
        }
    }, [tabData, page, tab])

    useEffect(() => {
        setPage(1)
    }, [tab])

    const addtoCalculatorDisabled = (): boolean => {
        return !checkedItems || checkedItems > 10
    }

    const clearDisabled = (): boolean => {
        return !checkedItems
    }

    return (
        <Loader show={columnLoading || dataLoading}>
            <div className="select-feed-page">
                <Chips data={chipsData} onClick={setTab} active={tab} />
                <Divider />
                {paginatedData.length ? (
                    <>
                        {paginatedData?.map((item: PropType) => {
                            return (
                                <div
                                    key={item?.id}
                                    onClick={() => {
                                        toggleCheckBox(item?.id, item?.checked)
                                    }}
                                    className="list-item"
                                >
            
                                    <div className="select-feed-details">
                                        <Image
                                            alt={item.title}
                                            className="image"
                                            src={getImageUrl(item?.image)}
                                            placeholder={getPlaceholderImage()}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item?.checked}
                                                />
                                            }
                                            label={item?.title}
                                            {...(item?.checked && {
                                                className: "active",
                                            })}
                                            labelPlacement="start"
                                        />
                                    </div>

                                    <Divider />
                                </div>
                            )
                        })}
                        <Pagination
                            page={page}
                            count={pages}
                            onChange={handleChange}
                        />
                    </>
                ) : (
                    <NoResultFound
                        title={t("no_feed_found")}
                        image={NoResultCross}
                    />
                )}

                <section className="action-btns">
                    <Button
                        onClick={handleClear}
                        variant="outlined"
                        className="clear-btn"
                        disabled={clearDisabled()}
                    >
                        <FontAwesomeIcon
                            size="2xs"
                            icon={faRotateRight}
                            style={{ transform: "translateX(-3px)" }}
                        />
                        <span>{t("clear_all")}</span>
                    </Button>
                    <Button
                        onClick={addOnClick}
                        variant="contained"
                        className="result-btn"
                        disabled={addtoCalculatorDisabled()}
                    >
                        {`${t("add_to_calculator")} (${checkedItems})`}
                    </Button>
                </section>
            </div>
        </Loader>
    )
}

export default SelectFeed
