import "./ProductRecommendations.scss"
import Divider from "@mui/material/Divider"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../configs/route"
import { ListAlias } from "../../constants/enums"
import useGetListId from "../../hooks/useGetListId"
import usePagination from "../../hooks/usePagination"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../components/common/Loader/Loader"
import { setPageConfig } from "../../redux/slices/pageSlice"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"
import { useGetListItemsQuery } from "../../redux/api/listApiSlice"
import { getColumnName, replacePathParams } from "../../utils/utils"
import Pagination from "../../components/common/Pagination/Pagination"
import { getLanguage, setErrorStatus } from "../../redux/slices/appSlice"
import TagsListView from "../../components/shared/TagsListView/TagsListView"
import NoResultFound from "../../components/common/NoResultFound/NoResultFound"

type PropType = { name: string; link: string }

const ProductL1Tags = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const language = useSelector(getLanguage)
    const tagListId = useGetListId(ListAlias.TAGS)

    const {
        columnMapping,
        isError: columnError,
        isFetching: columnsLoading,
    } = useGetColumnMapping(tagListId, "tags")

    const titleField = getColumnName(columnMapping, language, "TAG")

    const {
        data,
        isSuccess,
        isError: dataError,
        isFetching: dataLoading,
    } = useGetListItemsQuery(
        {
            listId: tagListId,
            $filter: `fields/level eq 1`,
            $expand: `fields($select=${titleField})`,
            $orderby: `fields/${titleField} asc`,
        },
        {
            skip: columnsLoading,
        }
    )

    const tags = useMemo(() => {
        const tags: PropType[] = []
        /* istanbul ignore else */
        if (isSuccess) {
            data.value.forEach((item: any) => {
                tags.push({
                    name: item.fields[titleField],
                    link: replacePathParams(
                        RoutePaths.PRODUCT_RECOMMENDATION_L2,
                        {
                            id: item.id,
                        }
                    ),
                })
            })
        }
        return tags
    }, [data])

    const { page, pages, setPage, paginatedData } = usePagination(tags, 20)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("product_recommendations"),
                heading: t("product_recommendations"),
                backButtonLink: RoutePaths.HOME,
                rightIcon: false,
            })
        )
    }, [])

    return (
        <Loader show={dataLoading || columnsLoading}>
            {paginatedData.length === 0 ? (
                <div className="result-margin">
                    <NoResultFound title={t("no_result_found")} />
                </div>
            ) : (
                <>
                    <div className="recommendation-container">
                        <Divider className="divider" />
                        <div className="margins">
                            {paginatedData.map((item: any, idx: number) => (
                                <TagsListView
                                    key={idx}
                                    name={item.name}
                                    link={item.link}
                                />
                            ))}
                        </div>
                    </div>

                    <Pagination
                        page={page}
                        count={pages}
                        onChange={handleChange}
                    />
                </>
            )}
        </Loader>
    )
}

export default ProductL1Tags
