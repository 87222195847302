import { RoutePaths } from "../../../configs/route"
import {
    ProductCategory,
    packageCategory,
    feedCalculatorCatagory,
} from "../../../constants/enums"
import {
    AhbProduct,
    FarmEquipmentThumbnail,
    FeedThumbnail,
    LabServiceThumbnail,
    Platinum,
    Gold,
    Silver,
    Swine,
} from "../../../assets/images"

export const productCategories: any = [
    {
        name: ProductCategory.FEED,
        thumbnail: FeedThumbnail,
        backgroundColor: "#DEED77",
        link: RoutePaths.FEED_CATALOG,
    },
    {
        name: ProductCategory.AHB,
        thumbnail: AhbProduct,
        backgroundColor: "#D0F18A",
        link: RoutePaths.AHB_CATALOG,
    },
    {
        name: ProductCategory.FARM_EQUIPMENT,
        thumbnail: FarmEquipmentThumbnail,
        backgroundColor: "#D2C660",
        link: RoutePaths.FARM_EQUIPMENTS_CATALOG,
    },
    {
        name: ProductCategory.LAB_SERVICE,
        thumbnail: LabServiceThumbnail,
        backgroundColor: "#D4E276",
        link: RoutePaths.LAB_SERVICES_CATALOG,
    },
]

export const calculatorCategories: any = [
    {
        name: ProductCategory.FEED,
        thumbnail: FeedThumbnail,
        backgroundColor: "#DEED77",
        link: RoutePaths.FEED_CALCULATOR,
    },
    {
        name: ProductCategory.AHB,
        thumbnail: AhbProduct,
        backgroundColor: "#D0F18A",
        link: RoutePaths.AHBS_CALCULATOR_VACCINES,
    },
]

export const feedCalculatorCatagories: any = [
    {
        name: feedCalculatorCatagory.BETAGRO_SWINE,
        thumbnail: Swine,
        backgroundColor: "#D0F18A",
        link: RoutePaths.FEED_CALCULATOR_CATEGORY,
        key: feedCalculatorCatagory.BETAGRO_SWINE.toLowerCase().replace(
            " ",
            "-"
        ),
    },
]

export const packageCatagories: any = [
    {
        name: packageCategory.PLATINUM,
        key: packageCategory.PLATINUM.toLowerCase(),
        thumbnail: Platinum,
        backgroundColor: "#D0F18A",
        text: "โปรแกรมอาหารประสิทธิภาพสูง",
        link: RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE,
    },
    {
        name: packageCategory.GOLD,
        key: packageCategory.GOLD.toLowerCase(),
        thumbnail: Gold,
        backgroundColor: "#E3D668",
        text: "โปรแกรมอาหารคุณภาพ",
        link: RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE,
    },
    {
        name: packageCategory.SILVER,
        key: packageCategory.SILVER.toLowerCase(),
        thumbnail: Silver,
        backgroundColor: "#EDEFE0",
        text: "โปรแกรมอาหารคุ้มค่า",
        link: RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE,
    },
]
