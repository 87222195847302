import { PropsWithChildren } from "react"
import AnimatedLoader from "./loader.json"
import Lottie from "lottie-react"
import "./Loader.scss"

interface IProps {
    show?: boolean
}

const LottieLoader = ({ show = true, children }: PropsWithChildren<IProps>) => {
    if (show === true) {
        return (
            <div className="lottie-loader">
                <Lottie
                    animationData={AnimatedLoader}
                    loop={true}
                    className="loader"
                    data-testid="lottie-loader"
                />
            </div>
        )
    }

    return <>{children}</>
}

export default LottieLoader
