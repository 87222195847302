import "./Calculator.scss"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { ProductCategory } from "../../constants/enums"
import { resetPageConfig } from "../../redux/slices/pageSlice"
import RouteLink from "../../components/common/RouteLink/RouteLink"
import { getCalculatorItemsCount } from "../../redux/slices/appSlice"
import { calculatorCategories } from "../../components/shared/ProductCategoryCard/ProductCategoryConfig"

const Calculator = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { total } = useSelector(getCalculatorItemsCount)

    const getCounterValue = () => {
        if (!total) {
            return null
        }

        return (
            <div className="vaccine-tab">
                <div className="counter-value">{total}</div>
            </div>
        )
    }

    useEffect(() => {
        dispatch(resetPageConfig())
    }, [])

    return (
        <div className="calculator">
            <h2>{t("Usage_estimation_calculator")}</h2>
            <section className="categories">
                {calculatorCategories.map((item: any, idx: number) => (
                    <div
                        key={idx}
                        className="calculator-category-card"
                        style={{ backgroundColor: item.backgroundColor }}
                    >
                        <RouteLink to={item.link}>
                            <h3 className="title">{item.name}</h3>
                            {item.name === ProductCategory.AHB &&
                                getCounterValue()}
                            <div className="image">
                                <img
                                    src={item.thumbnail}
                                    alt="category image"
                                />
                            </div>
                        </RouteLink>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default Calculator
