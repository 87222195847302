import { useNavigate, useSearchParams } from "react-router-dom"
import { GA4EventType, ProductCategory } from "../../../constants/enums"
import usePagination from "../../../hooks/usePagination"
import {
    ga4GA,
    getImageUrl,
    removeTrailingSlashes,
    replacePathParams,
} from "../../../utils/utils"
import NoResultFound from "../../common/NoResultFound/NoResultFound"
import Pagination from "../../common/Pagination/Pagination"
import ProductCard from "../../common/ProductCard/ProductCard"
import { useTranslation } from "react-i18next"
import { AHBCalculatorTypes } from "../../../pages/Calculator/AHB/AHBCalculator"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getLanguage } from "../../../redux/slices/appSlice"

type PropTypes = {
    data: any
    link: string
    isFilter: boolean
    toggleDrawer?: any
    imageField: string
    calculate?: boolean
    type: ProductCategory
    subtype?: AHBCalculatorTypes
    titleField?: string
}

const ProductCatalogList = ({
    data,
    link,
    type,
    subtype,
    toggleDrawer,
    imageField,
    isFilter,
    calculate,
    titleField,
}: PropTypes) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { page, pages, paginatedData, setPage } = usePagination(data)
    const language = useSelector(getLanguage)
    const [title, setTitle] = useState<string | any>("")

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    useEffect(() => {
        if(language === "EN"){
            setTitle(titleField)
        }else{
            setTitle("Title")
        }
        if (
            !paginatedData.length &&
            !data.length &&
            searchParams.get("q") !== null
        ) {
            ga4GA(GA4EventType.SEND, {
                catalog_type: type,
                eventAction: "no_result_found",
                target: isFilter ? "filter" : "search",
            })
        }
    }, [data])


    if (!paginatedData.length) {
        return (
            <NoResultFound
                title={t("no_result_found") as any}
                button={{
                    show: isFilter || searchParams.has("q"),
                    label: isFilter ? t("adjust_filter") : t("clear_search"),
                    width: "160px",
                    onClick: () => {
                        if (isFilter) {
                            toggleDrawer("top", true)
                        }

                        if (searchParams.get("q")) {
                            navigate(removeTrailingSlashes(location.pathname))
                        }
                    },
                }}
            />
        )
    }
    return (
        <>
            <section className="product-list">
                {paginatedData.map((item: any) => (
                    <ProductCard
                        type={type}
                        id={item.id}
                        key={item.id}
                        subtype={subtype}
                        calculate={calculate}
                        title={item?.fields[title]}
                        image={getImageUrl(item?.fields[imageField])}
                        link={replacePathParams(link, {
                            id: item?.id,
                        })}
                    />
                ))}
            </section>

            <Pagination count={pages} onChange={handleChange} page={page} />
        </>
    )
}

export default ProductCatalogList
