import { Calendar } from "../../../assets/images"
import Image from "../../common/Image/Image"
import "./ProductInformation.scss"
import { useTranslation } from "react-i18next"
import CalculatorButton from "../../common/CalculatorButton/CalculatorButton"
import { useLocation } from "react-router-dom"

export interface Props {
    id: string
    img: string
    name: string
    date?: string | null
    description?: string
    defaultImage?: string
    calculate?: boolean
}

const ProductInformation = ({
    id,
    name,
    description,
    date,
    img,
    defaultImage,
    calculate,
}: Props) => {
    const { t } = useTranslation()
    const location = useLocation()

    return (
        <>
            <Image
                src={img}
                alt={name}
                className="feed-img"
                placeholder={defaultImage}
            />
            <div className="product-tile">
                <h2 id="product-title-fs-32">{name}</h2>
                <div id="desc-fs-20">{description}</div>
                <div id="time-fs-21">
                    <img src={Calendar} alt="calendar" className="mb-5" />
                    <span>
                        {t("last_updated")}: <span>{date}</span>
                    </span>
                </div>
                {calculate && (
                    <div className="add-to-calculator">
                        <CalculatorButton
                            id={id}
                            image={img}
                            title={name}
                            type={
                                location.state &&
                                location.state.includes("vaccine")
                                    ? "vaccine"
                                    : "medicine"
                            }
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default ProductInformation
