import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import "./Accordion.scss"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"

interface IProps {
    title: string
    expand?: boolean
    children: any
    handleClear: any
    count?: number
    handleChange?: any
    required?: boolean
    onChange?: (expanded: boolean) => any
}

export default function FilterAccordion({
    title,
    expand = false,
    children,
    handleClear,
    count = 0,
    required,
    onChange,
}: IProps) {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(expand)

    useEffect(() => {
        setExpanded(expand)
    }, [expand])

    return (
        <div>
            <Accordion
                expanded={expanded}
                defaultExpanded={expanded}
                className="filter-accordion"
                onChange={() => {
                    setExpanded((prev) => !prev)
                    onChange && onChange(expanded)
                }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon data-testid={`${title} Expand Icon`} />
                    }
                    aria-controls={title + "-content"}
                >
                    <Typography className="acc-typo">
                        <span className="title">{title}</span>
                        {(required || expanded) && (
                            <span className="count"> ({count})</span>
                        )}
                        {required && <span className="required"> *</span>}
                    </Typography>

                    {expanded && handleClear && (
                        <span
                            className="clear"
                            onClick={(e: any) => {
                                handleClear(e)

                                // TODO: solution for now. Clicking clear collapses the accordion. Not sure why ?
                                setTimeout(() => {
                                    setExpanded(true)
                                }, 0)
                            }}
                        >
                            {t("clear")}
                        </span>
                    )}
                </AccordionSummary>
                <AccordionDetails className="acc-padding-none">
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
