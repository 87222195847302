import { t } from "i18next"
import { Divider } from "@mui/material"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router"
import CloseIcon from "@mui/icons-material/Close"
import { RoutePaths } from "../../../configs/route"
import { AHBCalculatorTypes } from "./AHBCalculator"
import { useDispatch, useSelector } from "react-redux"
import { ProductCategory } from "../../../constants/enums"
import Image from "../../../components/common/Image/Image"
import Button from "../../../components/common/Button/Button"
import { PropType, getPlaceholderImage } from "../../../utils/utils"
import FilterAccordion from "../../../components/common/Accordion/FilterAccordion"
import {
    resetCalculator,
    removeItemFromCalculator,
} from "../../../redux/slices/appSlice"

interface IProps {
    type: AHBCalculatorTypes
}

const SelectedProducts = ({ type }: IProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [expand, setExpand] = useState(true)

    const products = useSelector((state: any) => {
        return state.app.calculator[type]?.items || []
    })

    const { title, maxLimit, redirectPath, selectVaccine, stateData } =
        useMemo(() => {
            switch (type) {
                case "vaccine":
                    return {
                        maxLimit: 10,
                        title: t("vaccine_list"),
                        selectVaccine: t("select_vaccine"),
                        redirectPath:
                            RoutePaths.AHBS_CALCULATOR_SELECT_VACCINES,
                        stateData: "vaccine",
                    }
                case "medicine":
                    return {
                        maxLimit: 10,
                        title: t("medicine_list"),
                        selectVaccine: t("select_medicine"),
                        redirectPath:
                            RoutePaths.AHBS_CALCULATOR_SELECT_MEDICINES,
                        stateData: "medicine",
                    }
            }
        }, [type])

    const handleClear = () => {
        dispatch(resetCalculator({ type }))
    }

    const handleDelete = (item: PropType) => {
        dispatch(
            removeItemFromCalculator({
                id: item.id,
                type: type,
            })
        )
    }

    return (
        <>
            <section className="select-vaccine">
                <FilterAccordion
                    expand={true}
                    title={title}
                    required={true}
                    count={products.length}
                    onChange={() => {
                        setExpand((prev) => !prev)
                    }}
                    {...(products.length && {
                        handleClear,
                    })}
                >
                    <section className="suggestions">
                        <ul>
                            {products.map((item: any) => (
                                <li key={item.id} className="product-item">
                                    <div className="image">
                                        <Image
                                            src={item.image}
                                            alt={item.title}
                                            placeholder={getPlaceholderImage(
                                                ProductCategory.AHB
                                            )}
                                        />
                                    </div>
                                    <div className="title">{item.title}</div>
                                    <div className="remove">
                                        <CloseIcon
                                            className="closeIcon"
                                            data-testid="cross-icon"
                                            onClick={() => handleDelete(item)}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {products.length === 0 && (
                        <>
                            <Button
                                fullWidth={true}
                                id={`select-${type}`}
                                variant="contained"
                                className="select-vaccine-btn"
                                onClick={() => {
                                    navigate(redirectPath, { state: stateData })
                                }}
                            >
                                {selectVaccine}
                            </Button>
                        </>
                    )}

                    {products.length > 0 && (
                        <Button
                            id={`add-${type}`}
                            variant="contained"
                            className="addmore-btn"
                            disabled={products.length === maxLimit}
                            onClick={() => {
                                if (products.length < maxLimit) {
                                    navigate(redirectPath, { state: stateData })
                                }
                            }}
                        >
                            {t("add_more")}
                        </Button>
                    )}
                </FilterAccordion>
            </section>

            {(products.length === 0 || (products.length > 0 && !expand)) && (
                <Divider />
            )}
        </>
    )
}

export default SelectedProducts
