import { useRef } from "react"
import Button from "../Button/Button"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { useDispatch, useSelector } from "react-redux"
import { getSearchTerm, setSearchTerm } from "../../../redux/slices/searchSlice"

type PropType = {
    setShowSearchBar: any
}

const SearchBar = ({ setShowSearchBar }: PropType) => {
    const inputRef: any = useRef()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const searchTerm = useSelector(getSearchTerm)

    const handleInput = (e: any) => {
        dispatch(setSearchTerm(e.target.value))
    }

    return (
        <section className="recommendation-search-bar w-100">
            <div className="input-field">
                <SearchIcon className="searchIcon" data-testid="search" />
                <input
                    autoFocus
                    type="text"
                    ref={inputRef}
                    value={searchTerm}
                    onInput={handleInput}
                    placeholder={t("search")}
                    data-testid="input-field"
                />
                {searchTerm && (
                    <CloseIcon
                        className="closeIcon"
                        data-testid="cross-icon"
                        onClick={() => {
                            dispatch(setSearchTerm(""))
                            inputRef.current.focus()
                        }}
                    />
                )}
            </div>
            <div className="button">
                <Button
                    variant="text"
                    className="cancelBtn"
                    onClick={() => {
                        setShowSearchBar(false)
                        dispatch(setSearchTerm(""))
                    }}
                >
                    {t("cancel")}
                </Button>
            </div>
        </section>
    )
}

export default SearchBar
