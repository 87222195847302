import { BrowserCacheLocation, Configuration } from "@azure/msal-browser"

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        // eslint-disable-next-line
        clientId: window.REACT_APP_AUTH_MSAL_CLIENT_ID!,
        authority: window.REACT_APP_AUTH_MSAL_AUTHORITY_URL,
        redirectUri: window.REACT_APP_AUTH_REDIRECT_URI,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
}
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const tokenRequest = {
    scopes: ["User.Read", "Sites.Read.All"],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
}
