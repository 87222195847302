import { useMsal } from "@azure/msal-react"
import { faPills } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu, MenuItem } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { RoutePaths } from "../../../configs/route"
import { GA4EventType } from "../../../constants/enums"
import useIs404 from "../../../hooks/useIs404"
import style from "../../../styles/_variable.scss"
import Loader from "../Loader/Loader"
import RouteLink from "../RouteLink/RouteLink"
import SearchDrawer from "../Search/Search"
import { getProfileName } from "./GetProfileName"
import "./Header.scss"
import { useTranslation } from "react-i18next"
import SearchBar from "../Search/SearchBar"
import { useDispatch, useSelector } from "react-redux"
import { setSearchTerm } from "../../../redux/slices/searchSlice"
import { getPageConfig } from "../../../redux/slices/pageSlice"
import {
    clearPersistStore,
    ga4GA,
    removeTrailingSlashes,
} from "../../../utils/utils"
import {
    AgroKnowLogo,
    LeftArrow,
    LogOutIcon,
    Logo,
    Search,
    Share,
    ThFlag,
    UkFlag,
    GB_Icon,
} from "../../../assets/images/index"
import html2canvas from "html2canvas"
import { getLanguage, setExportPreview, setLanguage } from "../../../redux/slices/appSlice"
import i18n from "../../../i18n/i18n"

type Anchor = "top" | "left" | "bottom" | "right"

const Header = () => {
    const is404 = useIs404()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { instance, accounts } = useMsal()
    const [anchorEl, setAnchorEl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showSearchBar, setShowSearchBar] = useState<boolean>(false)
    const { heading, backButtonLink, rightIcon, backButtonState } =
        useSelector(getPageConfig)
    const [searchDrawerState, setSearchDrawerState] = useState({
        top: false,
    })

    const LanguageNow = useSelector(getLanguage)
    const [language, setlanguage] = useState(LanguageNow);



    const handleLangChange = () => {
        const newLang = language === 'EN' ? 'TH' : 'EN';
        setlanguage(newLang);
        i18n.changeLanguage(newLang.toLowerCase());
        localStorage.setItem('lang', newLang.toLowerCase());
        dispatch(setLanguage(newLang))
    };

    // reset search bar state when location is change
    useEffect(() => {
        setShowSearchBar(false)
        dispatch(setSearchTerm(""))
    }, [location.pathname])

    const toggleSearchDrawer = (anchor: Anchor, open: boolean) => {
        setSearchDrawerState({ ...searchDrawerState, [anchor]: open })
    }

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        setIsLoading(true)
        localStorage.removeItem("isLoggedIn")
        localStorage.removeItem("lang")
        clearPersistStore()
        instance.logoutRedirect()
    }

    const exportAsImage = () => {
        const vaccine_target = location.pathname.includes("/vaccines/results")
        const medicine_target = location.pathname.includes("/medicines/results")
        ga4GA(GA4EventType.SEND, {
            eventAction: "share_button_click",
            target: vaccine_target
                ? "vaccine"
                : medicine_target
                    ? "medicine"
                    : "feed",
        })
        const exportHTMLElement: HTMLDivElement | null =
            document.querySelector("#exportHtml")

        if (exportHTMLElement === null) {
            return
        }

        exportHTMLElement.style.padding = "10px"
        exportHTMLElement.children[0].classList.add("export-image")
        exportHTMLElement.children[0].classList.remove("hide-export-image")

        html2canvas(exportHTMLElement, {
            useCORS: true,
            allowTaint: true,
        }).then((canvas) => {
            const dataUrl = canvas.toDataURL("image/png")
            dispatch(setExportPreview(dataUrl))

            if (exportHTMLElement) {
                exportHTMLElement.style.padding = "0px"
                exportHTMLElement.children[0].classList.add("hide-export-image")
                exportHTMLElement.children[0].classList.remove("export-image")
            }
        })
    }

    const isFeedAdditivePage = (): boolean => {
        return (
            removeTrailingSlashes(location.pathname) ===
            RoutePaths.FEED_ADDITIVE
        )
    }

    const renderRightIcon = () => {
        // Used for keeping design consistent in CSS flex. Need to fix some other way
        const emptyContainer = (
            <div className="vhidden">
                <img src={LeftArrow} />
            </div>
        )

        if (!accounts.length || is404) {
            return emptyContainer
        }

        switch (rightIcon) {
            case "search":
            case "drawer-search":
                return (
                    <div
                        className="cursor"
                        onClick={() => {
                            if (rightIcon === "search") {
                                setShowSearchBar(true)
                            } else {
                                toggleSearchDrawer("top", true)
                            }
                        }}
                    >
                        <img src={Search} alt="search" />
                    </div>
                )
            case "additive":
                return (
                    <RouteLink
                        state={{ from: location.state, to: location.pathname }}
                        to={RoutePaths.FEED_ADDITIVE}
                        onClick={() =>
                            ga4GA(GA4EventType.SEND, {
                                target: "feed_additive_icon",
                                eventAction: "feed_additive_icon_click",
                            })
                        }
                    >
                        <FontAwesomeIcon
                            data-testid="feed-additives-btn"
                            icon={faPills}
                            style={{
                                color: isFeedAdditivePage()
                                    ? style.green60
                                    : style.gray70,
                                fontSize: "1.2em",
                            }}
                        />
                    </RouteLink>
                )
            case "share":
                return (
                    <div className="share" onClick={exportAsImage}>
                        <img
                            src={Share}
                            alt="share"
                            data-testid="export-image"
                        />
                    </div>
                )
            case "menu":
                return (
                    <>
                        <div
                            id="profile-avatar"
                            className="cursor"
                            onClick={handleMenuOpen}
                        >
                            <Avatar alt="profile" className="profile">
                                {getProfileName(accounts)}
                            </Avatar>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            id="profile-menu"
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            <MenuItem onClick={handleLogout}>
                                <img src={LogOutIcon} alt="log out" />
                                <span className="menu-item-text">
                                    {t("log_out")}
                                </span>
                            </MenuItem>
                        </Menu>
                    </>
                )
            default:
                return emptyContainer
        }
    }

    return (
        <>
            <Loader show={isLoading}>
                <header>
                    <div className="header-imgs">
                        {showSearchBar && (
                            <SearchBar setShowSearchBar={setShowSearchBar} />
                        )}

                        {!showSearchBar && (
                            <>
                                {backButtonLink ? (
                                    <div
                                        className="cursor"
                                        onClick={() =>
                                            navigate(backButtonLink, {
                                                state: backButtonState,
                                            })
                                        }
                                    >
                                        <img src={LeftArrow} alt="back" />
                                    </div>
                                ) : (
                                    <RouteLink
                                        className="logo"
                                        to={RoutePaths.HOME}
                                    >
                                        <img
                                            src={
                                                accounts?.length
                                                    ? AgroKnowLogo
                                                    : Logo
                                            }
                                            alt="betagro logo"
                                        />
                                    </RouteLink>
                                )}

                                {heading && (
                                    <div className="title">{heading}</div>
                                )}

                                <div className="container lang-switcher-container">
                                    <button
                                        onClick={handleLangChange}
                                        className="lang-switcher-button"
                                    >
                                        <img
                                            src={GB_Icon}
                                            alt="Change Language"
                                            className="lang-switcher-icon"
                                        />
                                        <img
                                            src={language === 'EN'
                                                ? UkFlag
                                                : ThFlag}
                                            alt="Language Flag"
                                            className="lang-flag"
                                        />
                                    </button>
                                </div>
                                {renderRightIcon()}
                            </>
                        )}
                    </div>
                </header>
            </Loader>

            {!showSearchBar && (
                <SearchDrawer
                    toggleDrawer={toggleSearchDrawer}
                    drawerState={searchDrawerState}
                />
            )}
        </>
    )
}

export default Header