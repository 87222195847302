import { useEffect, useMemo, useState } from "react"
import useFilters from "../../../../hooks/useFilters"
import { RoutePaths } from "../../../../configs/route"
import { useDispatch, useSelector } from "react-redux"
import useGetListId from "../../../../hooks/useGetListId"
import { Anchor } from "../../../ProductCatalog/ProductCatalog"
import Loader from "../../../../components/common/Loader/Loader"
import { PropType, getColumnName } from "../../../../utils/utils"
import { getSearchTerm } from "../../../../redux/slices/searchSlice"
import useGetColumnMapping from "../../../../hooks/useGetColumnMapping"
import {
    ListAlias,
    ProductCategory,
    getRelativeKeys,
} from "../../../../constants/enums"
import { useGetListItemsQuery } from "../../../../redux/api/listApiSlice"
import FilterDrawer from "../../../../components/common/Filters/FiltersV2"
import { getLanguage, setErrorStatus } from "../../../../redux/slices/appSlice"
import ProductCatalogTopBar from "../../../../components/shared/ProductCatalogTopBar/ProductCatalogTopBar"
import ProductCatalogList from "../../../../components/shared/ProductCatalogList/ProductCatalogList"
import { setPageConfig } from "../../../../redux/slices/pageSlice"
import { useSearchParams } from "react-router-dom"

const AHBCalculatorMedicines = () => {
    const vaccineLabel = "Vaccine"
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const language = useSelector(getLanguage)
    const searchTerm = useSelector(getSearchTerm)
    const ahbsListId = useGetListId(ListAlias.AHBS)
    const productGroupListId = useGetListId(ListAlias.PRODUCT_GROUPS)
    const [drawerState, setDrawerState] = useState({
        top: false,
    })
    const [loading, setLoading] = useState(false)

    // Get product group columns
    const {
        isError: productGroupColumnError,
        isFetching: productGroupColumnLoading,
        columnMapping: productGroupColumnsMapping,
    } = useGetColumnMapping(productGroupListId, "product-groups")

    const titleField = getColumnName(
        productGroupColumnsMapping,
        language,
        "NAME"
    )
    const subTagsField = getColumnName(
        productGroupColumnsMapping,
        'TH',
        "SUB_GROUPS"
    )
    const ahbsField = getColumnName(
        productGroupColumnsMapping,
        language,
        "AHBS"
    )

    // Get medicine sub groups from product groups
    const {
        data: productGroups,
        isError: productGroupError,
        isFetching: productGroupLoading,
    } = useGetListItemsQuery(
        {
            listId: productGroupListId,
            listName: "product-groups",
            $filter: `fields/catalog_type eq 'AHB' and ((fields/level eq 1 and fields/${titleField} ne '${vaccineLabel}') or fields/level eq 2)`,
            $expand: `fields($select=level,${titleField},${subTagsField},${ahbsField})`,
        },
        {
            skip: productGroupColumnLoading,
        }
    )

        // Get AHB catalog columns
    const {
        choices,
        isError: productColumnError,
        isFetching: productColumnLoading,
        columnMapping: productColumnMapping,
    } = useGetColumnMapping(ahbsListId, "ahbs")

    
    const titleFieldProductName = productColumnMapping[`${language} Product name`]

    const supplierField =
        getRelativeKeys[`${language}_SUPPLIER` as keyof typeof getRelativeKeys]

    // Prepare filters to bes used in filter drawer
    const {
        filters = {},
        medicineProducts = [],
        subgroupProductsMapping = {},
    } = useMemo(() => {
        const filters: PropType = {
            sorting: true,
            filters: {},
        }

        if (choices && supplierField && choices[supplierField]) {
            filters.filters.Supplier = {
                expand: false,
                clear: false,
                name: "supplier",
                options: choices[supplierField].map((item: any) => {
                    return {
                        value: item,
                        label: item,
                        checked: false,
                        ignoreUrlParam: true,
                    }
                }),
            }
        }

        if (!productGroups) {
            return { filters, medicineProducts: [] }
        }

        const medicines = productGroups.value.filter((item: any) => {
            return item.fields.level === "1"
        })

        // Prepare filters
        const subGroupIds: number[] = []

        medicines.forEach((medicine: any) => {
            filters["filters"][medicine.fields[titleField]] = {
                options: [],
                clear: false,
                expand: true,
                name: "filters",
            }

            medicine.fields[subTagsField].forEach((item: any) => {
                subGroupIds.push(item.LookupId)
                filters["filters"][medicine.fields[titleField]].options.push({
                    checked: true,
                    value: item.LookupId,
                    ignoreUrlParam: true,
                    label: item.LookupValue,
                })
            })
        })

        // Filter again from product groups for AHBs medicines
        let medicineProducts: any = []
        const subgroupProductsMapping: PropType = {}
        productGroups.value.forEach((item: any) => {
            if (subGroupIds.includes(Number(item.id))) {
                const productIds = item["fields"][ahbsField].map(
                    (i: any) => i.LookupId
                )

                if (
                    subgroupProductsMapping[item.id] in subgroupProductsMapping
                ) {
                    subgroupProductsMapping[item.id] = subgroupProductsMapping[
                        item.id
                    ].push(...productIds)
                } else {
                    subgroupProductsMapping[item.id] = productIds
                }

                if (productIds) {
                    medicineProducts.push(...productIds)
                }
            }
        })

        // Get unique values
        medicineProducts = new Set(medicineProducts)
        medicineProducts = [...medicineProducts]

        return { filters, medicineProducts, subgroupProductsMapping }
    }, [productGroupLoading, productColumnLoading])

    const productTitleField = getColumnName(
        productColumnMapping,
        "TH",
        "PRODUCT_NAME"
    )

    const productTitleFieldEN = getColumnName(
        productColumnMapping,
        "EN",
        "PRODUCT_NAME"
    )
    const productImageField = getColumnName(
        productColumnMapping,
        language,
        "PICTURE"
    )
    const productSupplierField = getColumnName(
        productColumnMapping,
        language,
        "SUPPLIER"
    )

    // Get all products from AHB catalog
    const {
        data: products,
        isError: productError,
        isFetching: productLoading,
    } = useGetListItemsQuery(
        {
            listName: "ahbs",
            listId: ahbsListId,
            $expand: `fields($select=calculable,${productTitleField},${productTitleFieldEN},${productImageField},${productSupplierField})`,
            $orderby: `fields/${productTitleField} asc`,
        },
        {
            skip: productColumnLoading || medicineProducts.length === 0,
        }
    )
    const filterParam = searchParams.get("filters")
    const supplierParam = searchParams.get("supplier")

    // Prepare final data (client side)
    const data = useMemo(() => {
        if (!products || !products?.value?.length) {
            return []
        }

        return products.value.filter((item: any) => {
            const conditions = [
                item.fields?.calculable === true &&
                medicineProducts.includes(Number(item.id)),
            ]

            const filterConditions = []

            if (searchTerm) {
                const title = item?.fields[productTitleField]?.toUpperCase()
                const titleEN = item?.fields[productTitleFieldEN]?.toUpperCase()
                const _searchTerm = searchTerm.toUpperCase()

                conditions.push(
                    title?.includes(_searchTerm) ||
                    titleEN?.includes(_searchTerm)
                )
            }

            if (filterParam) {
                const filterIds = filterParam.split(",")
                const filterProductIds: any[] = filterIds.reduce(
                    (acc: any[], item: any) => {
                        acc.push(
                            ...(item in subgroupProductsMapping
                                ? subgroupProductsMapping[item]
                                : [])
                        )
                        return acc
                    },
                    []
                )
                filterConditions.push(
                    filterProductIds.includes(Number(item.id))
                )
            }

            if (supplierParam) {
                const suppliers = supplierParam.split(",")
                filterConditions.push(
                    suppliers.includes(item?.fields[productSupplierField])
                )
            }

            if (filterConditions.length) {
                conditions.push(filterConditions.includes(true))
            }

            return conditions.every((item) => item)
        })
    }, [
        products,
        searchTerm,
        filterParam,
        supplierParam,
        subgroupProductsMapping,
    ])

    const toggleDrawer = (anchor: Anchor, open: boolean) => {
        setDrawerState({ ...drawerState, [anchor]: open })
    }

    // Show error in case any API call fails
    useEffect(() => {
        dispatch(
            setErrorStatus(
                productGroupColumnError ||
                productColumnError ||
                productGroupError ||
                productError
            )
        )
    }, [
        productGroupColumnError,
        productColumnError,
        productGroupError,
        productError,
    ])

    const isLoading = () => {
        return (
            productGroupColumnLoading ||
            productColumnLoading ||
            productGroupLoading ||
            productLoading ||
            loading
        )
    }

    const handleLoading = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 300)
    }

    // Filter data
    const { filteredData } = useFilters(data, filters)

        useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: "search",
                backButtonLink: RoutePaths.AHBS_CALCULATOR_MEDICINES,
            })
        )
    }, [])

    return (
        <Loader show={isLoading()}>
            <div className="page-product-catalog">
                <ProductCatalogTopBar
                    isActive={true}
                    title={ProductCategory.AHB}
                    count={filteredData.length}
                    toggleDrawer={toggleDrawer}
                />

                <ProductCatalogList
                    isFilter={false}
                    calculate={true}
                    subtype="medicine"
                    data={filteredData}
                    titleField={titleFieldProductName}
                    type={ProductCategory.AHB}
                    toggleDrawer={toggleDrawer}
                    link={RoutePaths.AHB_DETAILS}
                    imageField={productImageField}
                />

                <FilterDrawer
                    toggleDrawer={toggleDrawer}
                    drawerState={drawerState}
                    urlParams={{}}
                    filters={filters}
                    handleLoading={handleLoading}
                />
            </div>
        </Loader>
    )
}

export default AHBCalculatorMedicines
