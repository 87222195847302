import { useTranslation } from "react-i18next"
import { PropType } from "../../../../utils/utils"
import InputField from "../../../../components/common/InputField/InputField"
import SelectField from "../../../../components/common/SelectField/SelectField"

interface IProps {
    formData: PropType
    onChangeHandler: (e: any) => any
}

const FormFields = ({ formData = {}, onChangeHandler }: IProps) => {
    const { t } = useTranslation()

    return (
        <section className="input-fields">
            <InputField
                type="text"
                maxLength={5}
                required={true}
                id="breederInput"
                inputMode="numeric"
                name="no_of_breeder"
                onInput={onChangeHandler}
                label={t("no_of_breeder")}
                placeholder={t("add_number")}
                value={formData.no_of_breeder}
            />
            <InputField
                type="text"
                maxLength={5}
                required={true}
                id="pigletInput"
                name="no_of_piglet"
                inputMode="numeric"
                label={t("no_of_piglet")}
                onInput={onChangeHandler}
                placeholder={t("add_number")}
                value={formData.no_of_piglet}
            />
            <SelectField
                native={false}
                required={true}
                multiple={false}
                autoWidth={false}
                name="type_of_use"
                id="typeOfuseSelect"
                label={t("type_of_use")}
                onChange={onChangeHandler}
                value={formData.type_of_use}
                data={[
                    {
                        label: "Intramuscular",
                        value: "Intramuscular",
                    },
                    {
                        label: "Intradermal",
                        value: "Intradermal",
                    },
                ]}
            />
        </section>
    )
}

export default FormFields
