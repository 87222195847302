import {
    Box,
    Checkbox,
    Divider,
    Drawer,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { LeftArrow } from "../../../assets/images"
import {
    FilterTitle,
    GA4EventType,
    ListAlias,
    ProductCategory,
} from "../../../constants/enums"
import useGetListId from "../../../hooks/useGetListId"
import { IurlParams } from "../../../pages/ProductCatalog/ProductCatalog"
import { useGetListItemsQuery } from "../../../redux/api/listApiSlice"
import {
    getLanguage,
    setErrorStatus,
    setFilterMapping,
} from "../../../redux/slices/appSlice"
import {
    convertToQueryString,
    ga4GA,
    getFieldValue,
    removeEmptyKeys,
} from "../../../utils/utils"
import FilterAccordion from "../Accordion/FilterAccordion"
import Button from "../Button/Button"
import Loader from "../Loader/Loader"
import "./Filters.scss"
import { useTranslation } from "react-i18next"
interface IFilterDrawer {
    toggleDrawer: any
    drawerState: any
    category: ProductCategory
    urlParams: IurlParams
    choices: any
}

const FilterDrawer = ({
    toggleDrawer,
    drawerState,
    category,
    urlParams,
    choices,
}: IFilterDrawer) => {
    const anchor = "top"
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const language: string = useSelector(getLanguage)
    const [order, setOrder] = useState<any>(null)
    const [accordion, setAccordian] = useState<any>({})
    const [filterData, setFilterData] = useState<any[]>([])
    const isRecommendationPage = location.pathname.includes("tags")
    const { t } = useTranslation()
    const CHECKBOXES_INTIAL_VALUE = {
        filters: [],
        energy: [],
        lysine: [],
        protein: [],
        supplier: [],
        delivery: [],
        warranty: [],
    }
    const [checkBoxes, setCheckBoxes] = useState<any>(CHECKBOXES_INTIAL_VALUE)

    // Get product groups and subgroups for filter options
    const productGroupListID = useGetListId(ListAlias.PRODUCT_GROUPS)

    const manageCheckBoxesState = () => {
        const checkBoxesState = { ...checkBoxes }
        const persistCheckedItems = (urlParam: string | null, key: string) => {
            if (urlParam) checkBoxesState[key] = urlParam.split(",")
        }

        persistCheckedItems(urlParams.filters, "filters")

        if (category === ProductCategory.FEED) {
            persistCheckedItems(urlParams.energy, "energy")
            persistCheckedItems(urlParams.protein, "protein")
            persistCheckedItems(urlParams.lysine, "lysine")
        } else if (category === ProductCategory.AHB) {
            persistCheckedItems(urlParams.supplier, "supplier")
        } else if (category === ProductCategory.FARM_EQUIPMENT) {
            persistCheckedItems(urlParams.delivery, "delivery")
            persistCheckedItems(urlParams.warranty, "warranty")
        }

        setCheckBoxes(checkBoxesState)
    }

    const {
        isSuccess,
        isLoading,
        isError: dataError,
        data: productGroupData,
    } = useGetListItemsQuery(
        {
            listId: productGroupListID,
            listName: "product-groups",
            $expand:
                "fields($select=*)",
            $filter: `fields/catalog_type eq '${category}' and fields/level eq 1`,
        },
        {
            skip: isRecommendationPage,
        }
    )

    const Filter = (choices: any, language: string) => {
        const data: any[] = []

        const add = (id: string, title: string, options: any[]) => {
            data.push({
                id,
                title,
                options,
            })
        }

        const addChoiceFilter = (title: string, type: string, suffix = "") => {
            const optionsEN = getFieldValue(choices, 'EN', type);
            let options = getFieldValue(choices, language, type);
            if (Array.isArray(options) && options.length) {
                options = options.map((item: any, index: number) => ({
                    LookupId: optionsEN[index],
                    LookupValue: suffix ? item + suffix : item,
                    type:
                        type === "NUTRITION_PROTEIN"
                            ? "protein"
                            : type === "DELIVERY_METHOD"
                                ? "delivery"
                                : type.toLowerCase(),
                }));
                data.push({ id: title, title, options });
            }
        }
        

        const getFilters = () => data.filter((item: any) => item.options.length)

        return { add, addChoiceFilter, getFilters }
    }


    useEffect(() => {
        let feedOptions: any[] = []
        const accordianState: any = {}
        const persistAccordian = (param: string | null, key: string) => {
            if (param) accordianState[key] = true
        }
        const filter = Filter(choices, language)

        // Add AHB choice filter options
        if (category === ProductCategory.AHB && Object.keys(choices).length) {
            if (urlParams.supplier) accordianState[FilterTitle.SUPPLIER] = true
            filter.addChoiceFilter(FilterTitle.SUPPLIER, "SUPPLIER")
        }

        // when api success save mapping into redux and set accordian state
        if (productGroupData?.value?.length) {
            const mappingObj = productGroupData?.value.reduce(
                (acc: any, curr: any) => {
                    const subGroupFields = language === 'TH' ? curr.fields?.THSubGroup : curr.fields?.TH_x0020_Sub_x0020_groups_x003a_;

                    curr.fields?.THSubGroup.map((itemTH: any) => {
                        acc[itemTH?.LookupId] = itemTH?.LookupValue;
                    })

                    const options = subGroupFields.map((item: any) => {
                        if (checkBoxes["filters"].includes(item?.LookupId.toString())) {
                            persistAccordian(curr?.id, curr?.id);
                        }
                        return {
                            ...item,
                            type: "filters",
                            LookupId: item.LookupId.toString(),
                        };
                    });

                    // if category feed then merge options
                    if (category === ProductCategory.FEED) {
                        feedOptions = [...feedOptions, ...options];
                    } else {
                        if (language === 'TH') {
                            filter.add(curr.id, curr.fields.Title, options);
                        } else {
                            filter.add(curr.id, curr.fields.ENName, options);
                        }
                    }

                    return acc;
                },
                {}
            );


            // add feed choice filter options
            if (category === ProductCategory.FEED) {
                persistAccordian(urlParams.filters, FilterTitle.TYPE_OF_SWINE)
                persistAccordian(urlParams.energy, FilterTitle.ENERY_PER_KG)
                persistAccordian(urlParams.protein, FilterTitle.PROTEIN)
                persistAccordian(urlParams.lysine, FilterTitle.LYSINE)

                filter.add(
                    FilterTitle.TYPE_OF_SWINE,
                    FilterTitle.TYPE_OF_SWINE,
                    feedOptions
                )

                if (Object.keys(choices).length) {
                    filter.addChoiceFilter(FilterTitle.ENERY_PER_KG, "ENERGY")
                    filter.addChoiceFilter(FilterTitle.LYSINE, "LYSINE", "%")
                    filter.addChoiceFilter(
                        FilterTitle.PROTEIN,
                        "NUTRITION_PROTEIN",
                        "%"
                    )
                }
            }

            // Add Farm Equipments choice filter options
            if (
                category === ProductCategory.FARM_EQUIPMENT &&
                Object.keys(choices).length
            ) {
                persistAccordian(
                    urlParams.delivery,
                    FilterTitle.DELIVERY_METHOD
                )
                persistAccordian(urlParams.warranty, FilterTitle.WARRANTY)

                filter.addChoiceFilter(
                    FilterTitle.DELIVERY_METHOD,
                    "DELIVERY_METHOD"
                )
                filter.addChoiceFilter(FilterTitle.WARRANTY, "WARRANTY")
            }

            setFilterData(filter.getFilters())
            manageCheckBoxesState()
            setAccordian(accordianState)
            dispatch(setFilterMapping(mappingObj))
        }

        if (searchParams.get("order")) {
            setOrder(searchParams.get("order"))
        }
    }, [isSuccess, choices])

    useEffect(() => {
        dispatch(setErrorStatus(dataError))
    }, [dataError])

    const handleAccordionChange = (id: string) => {
        const x = { ...accordion }
        x[id] = !x[id]
        setAccordian(x)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()

        const qs = convertToQueryString(
            removeEmptyKeys({
                ...checkBoxes,
                order: order,
            })
        )
        if (qs.includes("order=")) {
            ga4GA(GA4EventType.SEND, {
                eventAction: "sort",
                order: qs.split("order=")[1],
                field: "name",
                target: "Done Button",
                catalog_type: category,
            })
        }
        Object.keys(checkBoxes).map((filterVal: any) => {
            if (checkBoxes[filterVal] && checkBoxes[filterVal].length !== 0) {
                ga4GA(GA4EventType.SEND, {
                    eventAction: "filter",
                    target: "Done Button",
                    catalog_type: category,
                    filter:
                        filterVal == "filters" ? "type_of_swine" : filterVal,
                })
            }
        })

        navigate(`?${qs}`)
        toggleDrawer("top", false)
    }

    const handleChange = (event: any, type = "filters") => {
        const { value, checked } = event.target
        const x = { ...checkBoxes }
        if (checked) {
            x[type].push(value)
        } else {
            x[type] = x[type].filter((item: any) => item !== value)
        }
        setCheckBoxes(x)
    }

    const handleReset = () => {
        setCheckBoxes(CHECKBOXES_INTIAL_VALUE)
        setOrder(null)
    }

    const handleClear = (e: any) => {
        e.stopPropagation()
        const el =
            e.target.offsetParent.offsetParent.querySelector(".filter-options")
        const ids = el.getAttribute("data-ids").split(",")
        const type = el.getAttribute("data-type")
        const checkBoxesState: any = { ...checkBoxes }

        if (category === ProductCategory.FEED) {
            checkBoxesState[type] = []
        } else {
            checkBoxesState[type] = checkBoxesState[type].filter(
                (item: any) => !ids.includes(item)
            )
        }
        setCheckBoxes(checkBoxesState)
    }

    return (
        <Drawer
            anchor={"top"}
            open={drawerState["top"]}
            className="filter-drawer"
            onClose={() => {
                toggleDrawer("top", false)
            }}
        >
            <Loader show={isLoading}>
                <div className="filter-container">
                    <section className="filter-header">
                        <div
                            className="backBtn"
                            onClick={() => {
                                toggleDrawer(anchor, false)
                            }}
                        >
                            <img src={LeftArrow} alt="back" />
                        </div>
                        <div className="filter-heading">{t("filter")}</div>
                        <div className="vhidden">
                            <img src={LeftArrow} />
                        </div>
                    </section>

                    <Box
                        sx={{
                            width: anchor === "top" ? "auto" : 450,
                            height: "100%",
                        }}
                        component="form"
                        onSubmit={(event) => {
                            handleSubmit(event)
                        }}
                        role="presentation"
                    >
                        <div className="sort-typography">{t("sort_by")}</div>

                        <section className="sorting-options">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="aToz"
                                name="radio-buttons-group"
                                value={order ?? "asc"}
                                onChange={(e: any) => {
                                    setOrder(e.target.value)
                                }}
                            >
                                <FormControlLabel
                                    label="A to Z"
                                    value="asc"
                                    {...((order === "asc" || order == null) && {
                                        className: "active",
                                    })}
                                    control={<Radio />}
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="desc"
                                    {...(order === "desc" && {
                                        className: "active",
                                    })}
                                    control={<Radio />}
                                    label="Z to A"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </section>
                        <Divider />

                        {filterData.map(({ id, title, options }: any) => {
                            const ids = options.map(
                                (item: any) => item?.LookupId
                            )
                            const type = options.length && options[0]["type"]
                            const count: any = options.length
                            const expand = checkBoxes[type].find(
                                (item: string) => ids.includes(item)
                            )

                            return (
                                <Fragment key={id}>
                                    <FilterAccordion
                                        title={title}
                                        handleClear={handleClear}
                                        count={count}
                                        expand={accordion[id] || !!expand}
                                        handleChange={() => {
                                            handleAccordionChange(id)
                                        }}
                                    >
                                        <section
                                            className="filter-options"
                                            data-ids={ids}
                                            data-type={type}
                                        >
                                            {options.map((item: any) => {
                                                const checked =
                                                    checkBoxes[item.type] &&
                                                    checkBoxes[
                                                        item.type
                                                    ].includes(item?.LookupId)
                                                return (
                                                    <div
                                                        className="checkbox-container"
                                                        key={item?.LookupId}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={
                                                                        item?.LookupId
                                                                    }
                                                                    name={
                                                                        item?.LookupValue
                                                                    }
                                                                    checked={
                                                                        checked
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleChange(
                                                                            e,
                                                                            item?.type
                                                                        )
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                item?.LookupValue
                                                            }
                                                            {...(checked && {
                                                                className:
                                                                    "active",
                                                            })}
                                                            labelPlacement="start"
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </section>
                                    </FilterAccordion>
                                    <Divider />
                                </Fragment>
                            )
                        })}

                        <Stack
                            spacing={2}
                            direction="row"
                            className="bottom-controls"
                        >
                            <Button
                                variant="outlined"
                                onClick={handleReset}
                                className="reset-btn"
                            >
                                {t("reset")}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="done-btn"
                            >
                                {t("done")}
                            </Button>
                        </Stack>
                    </Box>
                </div>
            </Loader>
        </Drawer>
    )
}

export default FilterDrawer
