import { createSlice } from "@reduxjs/toolkit"

interface Config {
    title: string | undefined // Used for SEO
    heading: string // Used to display in application header
    backButtonLink: string
    backButtonState: string
    rightIcon:
        | false
        | "menu"
        | "search"
        | "drawer-search"
        | "additive"
        | "share"
}

interface IState {
    header: true
    footer: true
    config: Config
}

const initialState: IState = {
    header: true,
    footer: true,
    config: {
        heading: "",
        rightIcon: "menu",
        backButtonLink: "",
        backButtonState: "",
        title: window.REACT_APP_NAME,
    },
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPageConfig: (state, action) => {
            state.config = action.payload
        },
        resetPageConfig: (state) => {
            state.header = initialState.header
            state.footer = initialState.footer
            state.config = initialState.config
        },
        setHeaderDisplay: (state, action) => {
            state.header = action.payload
        },
        setFooterDisplay: (state, action) => {
            state.footer = action.payload
        },
    },
})

export const {
    setPageConfig,
    resetPageConfig,
    setHeaderDisplay,
    setFooterDisplay,
} = pageSlice.actions

export const getPageConfig = (state: any) => state.page.config
export const getHeaderDisplay = (state: any) => state.page.header
export const getFooterDisplay = (state: any) => state.page.footer

export default pageSlice
