/* istanbul ignore file */
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import translationEnglish from "./Translation/English/translation.json"
import translationThai from "./Translation/Thai/translation.json"
import { store } from "../redux/store"

const resources = {
    en: {
        translation: translationEnglish,
    },
    th: {
        translation: translationThai,
    },
}

i18next.use(initReactI18next).init({
    returnNull: false,
    resources,
    lng: store.getState().app.language.toLowerCase(),
})

export default i18next
