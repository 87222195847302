import { Divider } from "@mui/material"
import { useEffect, useMemo } from "react"
import {
    ActiveIngredients,
    Diluent,
    ExpireDate,
    Product,
    Registration,
    Supplier,
    TradeBox,
} from "../../assets/images"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import AccordionListView from "../../components/common/Accordion/AccordionListView"
import ProductBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs"
import Loader from "../../components/common/Loader/Loader"
import ProductInformation from "../../components/shared/ProductInformation/ProductInformation"
import { ProductCategory } from "../../constants/enums"
import {
    getFieldValue,
    getImageUrl,
    getPlaceholderImage,
} from "../../utils/utils"
import useGetProductDetails from "../../hooks/useGetProductDetails"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useDispatch } from "react-redux"
import { RoutePaths } from "../../configs/route"
import { setPageConfig } from "../../redux/slices/pageSlice"

const AHBProductDetails = () => {
    const location = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id, isLoading, productDetails, language, lastModifiedDateTime } =
        useGetProductDetails("AHBS")
    const {
        productImage,
        storage = [],
        benefits = [],
        instruction = [],
        contraindications = [],
        productInformation = [],
        medicationFreePeriod = [],
    } = useMemo(() => {
        if (Object.keys(productDetails).length === 0) {
            return {}
        }

        return {
            storage: [getFieldValue(productDetails, language, "STORAGE")],
            productImage: getFieldValue(productDetails, language, "PICTURE"),
            benefits: [getFieldValue(productDetails, language, "BENEFINTS")],
            instruction: [
                getFieldValue(productDetails, language, "INSTRUCTION"),
            ],
            contraindications: [
                getFieldValue(productDetails, language, "CONTRAINDICATIONS"),
            ],
            medicationFreePeriod: [
                getFieldValue(
                    productDetails,
                    language,
                    "MEDICATION_FREE_PERIOD"
                ),
            ],
            productInformation: [
                {
                    name: t("trade_name"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "TRADE_NAME"
                    ),
                    icon: TradeBox,
                },
                {
                    name: t("supplier"),
                    value: getFieldValue(productDetails, language, "SUPPLIER"),
                    icon: Supplier,
                },
                {
                    name: t("active_ingredients"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "ACTIVE_INGREDIENTS"
                    ),
                    icon: ActiveIngredients,
                },
                {
                    name: t("diluent"),
                    value: getFieldValue(productDetails, language, "DILUENT"),
                    icon: Diluent,
                },
                {
                    name: t("package_size"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "PACKAGE_SIZE"
                    ),
                    icon: Product,
                },
                {
                    name: t("expiration_date"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "EXPIRATION_DATE"
                    ),
                    icon: ExpireDate,
                },
                {
                    name: t("registration_no"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "REGISTRATION_NO"
                    ),
                    icon: Registration,
                },
            ],
        }
    }, [language, productDetails])

    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: false,
                backButtonLink:
                    location.state && location.state.includes("vaccine")
                        ? RoutePaths.AHBS_CALCULATOR_SELECT_VACCINES
                        : location.state && location.state.includes("medicine")
                        ? RoutePaths.AHBS_CALCULATOR_SELECT_MEDICINES
                        : location.state || RoutePaths.AHB_CATALOG,
            })
        )
    }, [])

    return (
        <Loader show={isLoading}>
            {Object.keys(productDetails).length > 0 && (
                <>
                    <ProductBreadcrumbs
                        productDetails={productDetails}
                        productKeys={[
                            "PRODUCT_GROUP",
                            "PRODUCT_SUB_GROUP",
                            "PRODUCT_NAME",
                        ]}
                    />
                    <ProductInformation
                        id={id}
                        name={getFieldValue(
                            productDetails,
                            language,
                            "PRODUCT_NAME"
                        )}
                        description={getFieldValue(
                            productDetails,
                            language,
                            "DESCRIPTION"
                        )}
                        date={lastModifiedDateTime}
                        img={getImageUrl(productImage)}
                        defaultImage={getPlaceholderImage(ProductCategory.AHB)}
                        calculate={
                            location.state
                                ? location.state.includes("vaccine") ||
                                  location.state.includes("medicine")
                                : false
                        }
                    />
                    <Divider />
                    <CustomAccordion
                        title={t("product_information")}
                        productDetails={productInformation}
                        expand
                    />
                    <Divider />
                    <AccordionListView
                        title={t("benefit")}
                        productDetails={benefits}
                        showBulletPoint
                    />
                    <Divider />
                    <AccordionListView
                        title={t("instruction")}
                        productDetails={instruction}
                    />
                    <Divider />
                    <AccordionListView
                        title={t("medication_free_period")}
                        productDetails={medicationFreePeriod}
                    />
                    <Divider />
                    <AccordionListView
                        title={t("storage")}
                        productDetails={storage}
                    />
                    <Divider />
                    <AccordionListView
                        title={t("contraindications")}
                        productDetails={contraindications}
                    />
                    <Divider />
                </>
            )}
        </Loader>
    )
}

export default AHBProductDetails
