import "./Contact.scss"
import { useEffect, useMemo, useState } from "react"
import { ListAlias, ProductCategory } from "../../constants/enums"
import Chips from "../../components/common/Chips/Chips"
import useGetListId from "../../hooks/useGetListId"
import { useDispatch, useSelector } from "react-redux"
import { getLanguage, setErrorStatus } from "../../redux/slices/appSlice"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"
import { useGetListItemsQuery } from "../../redux/api/listApiSlice"
import Loader from "../../components/common/Loader/Loader"
import ContactDetails from "./ContactDetails"
import { Copy, Name, Email, Phone, Time, Notes } from "../../assets/images"
import { Divider } from "@mui/material"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { resetPageConfig } from "../../redux/slices/pageSlice"
import {
    PropType,
    createProductDetails,
    getFieldValue,
} from "../../utils/utils"

export type TContactDataFields = {
    fields: object
}

export const chipsData = [
    {
        label: ProductCategory.FEED,
    },
    {
        label: ProductCategory.AHB,
    },
    {
        label: ProductCategory.FARM_EQUIPMENT,
    },
    {
        label: ProductCategory.LAB_SERVICE,
    },
]

const Contact = () => {
    const dispatch = useDispatch()
    const language = useSelector(getLanguage)
    const listId = useGetListId(ListAlias.CONTACTS)
    const [tab, setTab] = useState(ProductCategory.FEED)
    const { t } = useTranslation()
    const {
        columnMapping,
        isError: columnError,
        isLoading: columnsLoading,
    } = useGetColumnMapping(listId)

    const {
        data,
        isLoading,
        isError: dataError,
        isSuccess: dataSuccess,
    } = useGetListItemsQuery({
        listId,
        $expand: "fields",
    })

    const copyToClipBoard = (text: string) => (
        <CopyToClipboard data-testid="copy-to-clipboard" text={text}>
            <img src={Copy} alt="copy" className="copy-to-clipboard-icon" />
        </CopyToClipboard>
    )

    const formattedContactDetailsStructure = (
        mapping: any,
        language: string
    ) => {
        return {
            title: getFieldValue(mapping, language, "SPECIES_EXPERTISE"),
            details: [
                {
                    name: t("name"),
                    value: getFieldValue(mapping, language, "NAME"),
                    icon: Name,
                },
                {
                    name: t("email"),
                    value: getFieldValue(mapping, language, "Email"),
                    icon: Email,
                    rightChildren: copyToClipBoard(
                        getFieldValue(mapping, language, "Email")
                    ),
                },
                {
                    name: t("phone_numbers"),
                    value: getFieldValue(mapping, language, "PHONE_NUMBER"),
                    icon: Phone,
                    rightChildren: copyToClipBoard(
                        getFieldValue(mapping, language, "PHONE_NUMBER")
                    ),
                },
                {
                    name: t("time_of_contact"),
                    value: getFieldValue(mapping, language, "OFFICE_HOURS"),
                    icon: Time,
                },
                {
                    name: t("notes"),
                    value: getFieldValue(mapping, language, "NOTES"),
                    icon: Notes,
                },
            ],
        }
    }

    const details = useMemo((): PropType => {
        if (
            !dataSuccess ||
            !columnMapping ||
            Object.keys(columnMapping).length === 0
        ) {
            return {}
        }

        const details: PropType = {}
        data?.value?.forEach((item: TContactDataFields) => {
            const mapping = createProductDetails(columnMapping, item.fields)

            const catalog = getFieldValue(
                mapping,
                language,
                "CATALOGUE_EXPERTISE"
            )

            if (!(catalog in details)) {
                details[catalog] = [
                    formattedContactDetailsStructure(mapping, language),
                ]
            } else {
                details[catalog].push(
                    formattedContactDetailsStructure(mapping, language)
                )
            }
        })

        return details
    }, [language, data, columnMapping])

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    useEffect(() => {
        dispatch(resetPageConfig())
    }, [])

    return (
        <Loader show={isLoading || columnsLoading}>
            <div className="contact-page">
                <h1 className="contact-heading">{t("contact_person")}</h1>
                <Chips data={chipsData} onClick={setTab} active={tab} />
                <Divider />
                <ContactDetails data={details[tab]} />
            </div>
        </Loader>
    )
}

export default Contact
