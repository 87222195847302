import { Divider } from "@mui/material"
import { Fragment, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ListAlias } from "../../../constants/enums"
import useGetColumnMapping from "../../../hooks/useGetColumnMapping"
import useGetListId from "../../../hooks/useGetListId"
import { useGetListItemsQuery } from "../../../redux/api/listApiSlice"
import { getLanguage, setErrorStatus } from "../../../redux/slices/appSlice"
import { createProductDetails, getFieldValue } from "../../../utils/utils"
import AccordionListView from "../../common/Accordion/AccordionListView"
import AlertDialog from "../../common/Dialog/AlertDialog"
import LottieLoader from "../../common/Loader/LottieLoader"
import NoResultFound from "../../common/NoResultFound/NoResultFound"
import { useTranslation } from "react-i18next"
export type VocabPropType = {
    title: string
    data: string[]
    rightChildren?: JSX.Element
}

const TechnicalVocab = ({ handleClose }: { handleClose: () => void }) => {
    const dispatch = useDispatch()
    const language = useSelector(getLanguage)
    const listId = useGetListId(ListAlias.VOCABS)
    const { t } = useTranslation()

    const {
        columnMapping,
        isError: columnError,
        isLoading: columnsLoading,
    } = useGetColumnMapping(listId, "vocabs")

    const {
        data,
        isError: dataError,
        isLoading: dataLoading,
    } = useGetListItemsQuery({
        listId,
        $expand: "fields",
        listName: "vaocabs",
    })

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    const vocabs: any[] = useMemo(() => {
        if (!data || data?.value.length === 0 || !columnMapping) {
            return []
        }

        const vocabList: VocabPropType[] = data?.value.map((item: any) => {
            const details = createProductDetails(columnMapping, item?.fields)
            return {
                title: `${getFieldValue(
                    details,
                    language,
                    "JARGON"
                )} (${getFieldValue(
                    details,
                    language,
                    "ACRONYM"
                )}) | ${getFieldValue(details, language, "TRANSLATION")}`,
                data: [
                    getFieldValue(details, language, "MEANING"),
                    getFieldValue(details, language, "EXAMPLE"),
                ],
            }
        })

        return vocabList
    }, [language, data, columnMapping])

    return (
        <AlertDialog
            open={true}
            title={t("technical_vocabularies")}
            handleClose={handleClose}
        >
            <LottieLoader show={dataLoading || columnsLoading}>
                {vocabs.length === 0 && (
                    <div className="no-result">
                        <NoResultFound />
                    </div>
                )}

                {vocabs.map((item, idx) => (
                    <Fragment key={item.title + idx}>
                        <AccordionListView
                            title={item.title}
                            productDetails={item.data}
                            type="technical_vocabularies"
                        />
                        <Divider />
                    </Fragment>
                ))}
            </LottieLoader>
        </AlertDialog>
    )
}

export default TechnicalVocab
