import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import Divider from "@mui/material/Divider"
import { useTranslation } from "react-i18next"
import { ProductCategory } from "../../constants/enums"
import Loader from "../../components/common/Loader/Loader"
import { setPageConfig } from "../../redux/slices/pageSlice"
import useGetProductDetails from "../../hooks/useGetProductDetails"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import ProductBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs"
import AccordionListView from "../../components/common/Accordion/AccordionListView"
import ProductInformation from "../../components/shared/ProductInformation/ProductInformation"
import {
    Product,
    Fat,
    Fiber,
    Lysine,
    Moisture,
    Protein,
    Energy,
} from "../../assets/images"
import {
    getFieldValue,
    getImageUrl,
    getPlaceholderImage,
} from "../../utils/utils"
import { RoutePaths } from "../../configs/route"
import { useLocation } from "react-router"

const FeedProductDetails = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const { id, isLoading, productDetails, language, lastModifiedDateTime } =
        useGetProductDetails("FEEDS")

    const {
        productImage,
        benefits = [],
        nutritionDetails = [],
        productInformation = [],
    } = useMemo(() => {
        if (Object.keys(productDetails).length === 0) {
            return {}
        }

        return {
            productImage: getFieldValue(productDetails, language, "PICTURE"),
            productInformation: [
                {
                    name: t("package_size"),
                    value: getFieldValue(
                        productDetails,
                        language,
                        "PACKAGE_SIZE_INFO"
                    ),
                    icon: Product,
                },
            ],
            nutritionDetails: [
                {
                    name: t("min_protein"),
                    value:
                        getFieldValue(
                            productDetails,
                            language,
                            "NUTRITION_PROTEIN"
                        ) + t("units"),
                    icon: Protein,
                },
                {
                    name: t("min_fat"),
                    value:
                        getFieldValue(
                            productDetails,
                            language,
                            "NUTRITION_FAT"
                        ) + t("units"),
                    icon: Fat,
                },
                {
                    name: t("max_fiber"),
                    value:
                        getFieldValue(
                            productDetails,
                            language,
                            "NUTRITION_FIBER"
                        ) + t("units"),
                    icon: Fiber,
                },
                {
                    name: t("max_moisture"),
                    value:
                        getFieldValue(
                            productDetails,
                            language,
                            "NUTRITION_MOISTURE"
                        ) + t("units"),
                    icon: Moisture,
                },
                {
                    name: t("lysine"),
                    value:
                        getFieldValue(productDetails, language, "LYSINE") +
                        t("units"),
                    icon: Lysine,
                },
                {
                    name: t("energy") + " " + t("energy_unit"),
                    value: getFieldValue(productDetails, language, "ENERGY"),
                    icon: Energy,
                },
            ],
            benefits: [
                getFieldValue(productDetails, language, "BENEFINT1"),
                getFieldValue(productDetails, language, "BENEFINT2"),
                getFieldValue(productDetails, language, "BENEFINT3"),
            ],
        }
    }, [language, productDetails])
    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: "additive",
                backButtonLink: location.state || RoutePaths.FEED_CATALOG,
            })
        )
    }, [])

    return (
        <Loader show={isLoading}>
            {Object.keys(productDetails).length > 0 && (
                <>
                    <ProductBreadcrumbs
                        productDetails={productDetails}
                        productKeys={[
                            "SWINE_LIFE_STAGE",
                            "SWINE_SUBLIFE_STAGE",
                            "FEEDTYPE",
                        ]}
                    />
                    <ProductInformation
                        id={id}
                        name={getFieldValue(
                            productDetails,
                            language,
                            "FEEDTYPE"
                        )}
                        description={getFieldValue(
                            productDetails,
                            language,
                            "SHORT_DESCRIPTION"
                        )}
                        date={lastModifiedDateTime}
                        img={getImageUrl(productImage)}
                        defaultImage={getPlaceholderImage(ProductCategory.FEED)}
                    />
                    <Divider />
                    <CustomAccordion
                        title={t("product_information")}
                        productDetails={productInformation}
                        expand
                    />
                    <Divider />
                    <AccordionListView
                        title={t("benefit")}
                        productDetails={benefits}
                        showBulletPoint
                    />
                    <Divider />
                    <CustomAccordion
                        title={t("nutrition")}
                        productDetails={nutritionDetails}
                    />
                    <Divider />
                </>
            )}
        </Loader>
    )
}

export default FeedProductDetails
