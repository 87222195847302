import { createSlice } from "@reduxjs/toolkit"

interface IState {
    searchTerm: string
}

const initialState: IState = {
    searchTerm: "",
}

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload
        },
    },
})

export const { setSearchTerm } = searchSlice.actions

export default searchSlice
export const getSearchTerm = (state: any) => state.search.searchTerm
