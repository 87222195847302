import { GA4EventType } from "../../../constants/enums"
import { ga4GA } from "../../../utils/utils"
import RouteLink from "../../common/RouteLink/RouteLink"
import "./ProductCategoryCard.scss"

const ProductCategoryCard = ({
    name,
    thumbnail,
    backgroundColor,
    link,
}: any) => {
    const handleClickEvent = (name: string) => {
        ga4GA(GA4EventType.SEND, {
            eventAction: "catalog_card_click",
            catalog_type: name,
        })
    }
    return (
        <div className="product-category-card" style={{ backgroundColor }}>
            <RouteLink to={link} onClick={() => handleClickEvent(name)}>
                <h3 className="title">{name}</h3>
                <div className="image">
                    <img src={thumbnail} alt="category image" />
                </div>
            </RouteLink>
        </div>
    )
}

export default ProductCategoryCard
