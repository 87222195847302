import { useMsal } from "@azure/msal-react"
import { useTranslation } from "react-i18next"
const Profile = () => {
    const { accounts } = useMsal()
    const { t } = useTranslation()
    return (
        <>
            <div>{t("profile")}</div>
            <ul>
                <li>
                    {t("name")} : {accounts[0]?.name}
                </li>
                <li>
                    {t("username")} : {accounts[0]?.username}
                </li>
                <li>
                    {t("tenant_ID")} : {accounts[0]?.tenantId}
                </li>
            </ul>
        </>
    )
}

export default Profile
