import "./Home.scss"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { resetPageConfig } from "../../../redux/slices/pageSlice"
import ProductCategoryCard from "../ProductCategoryCard/ProductCategoryCard"
import { productCategories } from "../ProductCategoryCard/ProductCategoryConfig"
import ProductRecommendTags from "../../../pages/ProductRecommendTags/ProductRecommendTags"

const HomePage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetPageConfig())
    }, [])

    return (
        <div className="home-page">
            <section className="categories">
                {productCategories.map((item: any) => (
                    <ProductCategoryCard key={item.name} {...item} />
                ))}
            </section>
            <ProductRecommendTags />
        </div>
    )
}

export default HomePage
