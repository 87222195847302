/* istanbul ignore file */
export enum ButtonSizes {
    SM = "sm",
    MD = "md",
    LG = "lg",
}

export enum ButtonTypes {
    PRIMARY = "primary",
}

export enum ProductCategory {
    FEED = "Feed",
    AHB = "AHB",
    FARM_EQUIPMENT = "Farm Equipment",
    LAB_SERVICE = "Lab Service",
}

export enum Environments {
    DEV = "dev",
    TEST = "test",
    UAT = "uat",
    PROD = "prod",
}

export enum ListAlias {
    FEEDS = "feeds",
    FEED_ADDITIVE = "feed_additives",
    AHBS = "ahbs",
    FARM_EQUIPMENTS = "farm_equipments",
    LAB_SERVICES = "lab_services",
    PRODUCT_GROUPS = "product_groups",
    CONTACTS = "contacts",
    VOCABS = "vocabs",
    TAGS = "tags",
    FEED_CALCULATION = "feed_calculations",
}

export enum ListPrefix {
    DEV = "dev_",
    TEST = "test_",
    UAT = "uat_",
    PROD = "master_",
}

export enum getRelativeKeys {
    EN_PRODUCT_NAME = "EN Product name",
    TH_PRODUCT_NAME = "TH Product name",

    EN_PRODUCT_GROUP = "EN Product group",
    TH_PRODUCT_GROUP = "TH Product group",

    EN_PRODUCT_SUB_GROUP = "EN Product sub-group",
    TH_PRODUCT_SUB_GROUP = "TH Product sub-group",

    EN_TRADE_NAME = "EN Trade name",
    TH_TRADE_NAME = "TH Trade name",

    EN_SUPPLIER = "EN Supplier",
    TH_SUPPLIER = "TH Supplier",

    EN_ACTIVE_INGREDIENTS = "EN Active ingredients",
    TH_ACTIVE_INGREDIENTS = "TH Active ingredients",

    EN_ACTIVE_INGREDIENT = "EN Active ingredient",
    TH_ACTIVE_INGREDIENT = "TH Active ingredient",

    EN_BENEFINTS = "EN Benefits",
    TH_BENEFINTS = "TH Benefits",

    EN_PACKAGE_SIZE = "EN Package size",
    TH_PACKAGE_SIZE = "TH Package size ขนาดบรรจุ",

    EN_EXPIRATION_DATE = "EN Expiration date",
    TH_EXPIRATION_DATE = "TH Expiration date",

    EN_REGISTRATION_NO = "EN Registration no.",
    TH_REGISTRATION_NO = "TH Registration no. เลขทะเบียน",

    EN_DESCRIPTION = "EN Description / Contains / Ingredients",
    TH_DESCRIPTION = "TH Description / Contains / Ingredients ส่วนประกอบ",

    EN_PICTURE = "EN Picture",
    TH_PICTURE = "TH Picture",

    EN_DOSE_USAGE = "EN Dose and Usage",
    TH_DOSE_USAGE = "TH Dose and Usage ขนาดและวิธีใช้",

    EN_STORAGE = "EN Storage",
    TH_STORAGE = "TH การเก็บรักษา / Storage",

    EN_CONTRAINDICATIONS = "EN Contraindications",
    TH_CONTRAINDICATIONS = "TH ข้อห้ามใช้ / contraindications",

    EN_MEDICATION_FREE_PERIOD = "EN Medication-free period",
    TH_MEDICATION_FREE_PERIOD = "TH ระยะหยุดยา / Medication-free period",

    EN_INSTRUCTION = "EN Instruction",
    TH_INSTRUCTION = "TH Instruction ขนาดและวิธีใช้",

    EN_SWINE_LIFE_STAGE = "EN Swine life stage",
    TH_SWINE_LIFE_STAGE = "TH Swine life stage",

    EN_SWINE_PERIOD = "EN Swine period",
    TH_SWINE_PERIOD = "TH Swine period",

    EN_SWINE_SUBLIFE_STAGE = "EN Swine sub-life stage",
    TH_SWINE_SUBLIFE_STAGE = "TH Swine sub-life stage",

    EN_FEEDTYPE = "EN Feed type",
    TH_FEEDTYPE = "TH Feed type",

    EN_SHORT_DESCRIPTION = "EN Short description",
    TH_SHORT_DESCRIPTION = "TH Short description",

    EN_NUTRITION_PROTEIN = "EN Nutrition Min protein %",
    TH_NUTRITION_PROTEIN = "TH Nutrition Min protein %",

    EN_NUTRITION_FAT = "EN Nutrition Min fat %",
    TH_NUTRITION_FAT = "TH Nutrition Min fat %",

    EN_NUTRITION_FIBER = "EN Nutrition Max fiber %",
    TH_NUTRITION_FIBER = "TH Nutrition Max fiber %",

    EN_NUTRITION_MOISTURE = "EN Nutrition Max moisture %",
    TH_NUTRITION_MOISTURE = "TH Nutrition Max moisture %",

    EN_LYSINE = "EN Lysine",
    TH_LYSINE = "TH Lysine",

    EN_BENEFINT1 = "EN Benefit 1",
    TH_BENEFINT1 = "TH Benefit 1",

    EN_BENEFINT2 = "EN Benefit 2",
    TH_BENEFINT2 = "TH Benefit 2",

    EN_BENEFINT3 = "EN Benefit 3",
    TH_BENEFINT3 = "TH Benefit 3",

    EN_PACKAGE_SIZE_INFO = "EN Package size - Product information",
    TH_PACKAGE_SIZE_INFO = "TH Package size - Product information",

    EN_PRODUCT_DESCRIPTION = "EN Product description",
    TH_PRODUCT_DESCRIPTION = "TH Product description",

    EN_MATERIAL = "EN Material",
    TH_MATERIAL = "TH Material",

    EN_PRODUCT_SIZE = "EN Product Size",
    TH_PRODUCT_SIZE = "TH Product size",

    EN_PRODUCT_SELLING_POINT = "EN Product selling point",
    TH_PRODUCT_SELLING_POINT = "TH Product selling point",

    EN_HEIGHT = "EN Height",
    TH_HEIGHT = "TH Height",

    EN_WEIGHT = "EN Weight",
    TH_WEIGHT = "TH Weight",

    EN_DELIVERY_METHOD = "EN Delivery method",
    TH_DELIVERY_METHOD = "TH Delivery method",

    EN_WARRANTY = "EN Warranty",
    TH_WARRANTY = "TH Warranty",

    EN_NO_OF_SUPPORTING_LEGS = "EN Number of supporting legs",
    TH_NO_OF_SUPPORTING_LEGS = "TH Number of supporting legs",

    EN_EFFICIENCY_POWER_RATING = "EN Efficiency per power rating",
    TH_EFFICIENCY_POWER_RATING = "TH Efficiency per power rating ประสิทธิภาพต่ออัตราพลังงาน",

    EN_WIND_EFFICIENCY = "EN Wind efficiency",
    TH_WIND_EFFICIENCY = "TH Wind efficiency ประสิทธิภาพแรงลม",

    EN_MOTOR_TYPE_SPECIFICATION = "EN Motor type and specification",
    TH_MOTOR_TYPE_SPECIFICATION = "TH Motor type and specification",

    EN_FAN_SPECIFICATION = "EN Fan specification",
    TH_FAN_SPECIFICATION = "TH Fan specification",

    EN_NO_OF_RING = "EN Number of วง",
    TH_NO_OF_RING = "TH Number of วง",

    EN_PACKAGE_UNIT = "EN Package unit",
    TH_PACKAGE_UNIT = "TH Package unit ขนาดบรรจุ",

    EN_PRODUCT_DIMENSION = "EN Product dimension",
    TH_PRODUCT_DIMENSION = "TH Product dimension",

    EN_PRODUCT_CATEGORY = "EN Product category",
    TH_PRODUCT_CATEGORY = "TH Product category",

    EN_ADDITIVE_NUMBER = "EN Additive number",
    TH_ADDITIVE_NUMBER = "TH Additive number",

    EN_DOSE_PPM = "EN Dose(ppm)",
    TH_DOSE_PPM = "TH Dose(ppm)",

    EN_ADDITIVE_PRICE = "EN Additive price (THB/ton)",
    TH_ADDITIVE_PRICE = "TH Additive price (THB/ton)",

    EN_FEED_ADDITIVE_APPLICATION = "EN Feed addtive application",
    TH_FEED_ADDITIVE_APPLICATION = "TH Feed addtive application",

    EN_SPECIES_EXPERTISE = "EN Species expertise",
    TH_SPECIES_EXPERTISE = "TH Species expertise",

    EN_NAME = "EN Name",
    TH_NAME = "TH Name",

    EN_Email = "EN Email",
    TH_Email = "TH Email",

    EN_PHONE_NUMBER = "EN Phone number",
    TH_PHONE_NUMBER = "TH Phone number",

    EN_OFFICE_HOURS = "EN Office hours",
    TH_OFFICE_HOURS = "TH Office hours",

    EN_NOTES = "EN Notes",
    TH_NOTES = "TH Notes",

    EN_CATALOGUE_EXPERTISE = "EN Catalogue expertise",
    TH_CATALOGUE_EXPERTISE = "TH Catalogue expertise",

    EN_TEST_NAME = "EN Test name",
    TH_TEST_NAME = "TH Test name",

    EN_LAB_TESTING_SERVICE = "EN Lab testing service",
    TH_LAB_TESTING_SERVICE = "TH Lab testing service",

    EN_TEST_CATEGORY = "EN Test category",
    TH_TEST_CATEGORY = "TH Test category",

    EN_METHOD = "EN Method",
    TH_METHOD = "TH Method",

    EN_TEST_DURATION = "EN Test duration (Unit:Working day)",
    TH_TEST_DURATION = "TH Test duration (Unit:Working day)",

    EN_RESULT_FORMAT = "EN Test result format",
    TH_RESULT_FORMAT = "TH Test result format",

    EN_SWINE_LIFE_CYCLE = "EN Life cycle",
    TH_SWINE_LIFE_CYCLE = "TH Life cycle",

    EN_ENERGY = "EN Energy",
    TH_ENERGY = "TH Energy",

    EN_JARGON = "EN Jargon",
    TH_JARGON = "TH Jargon",

    EN_ACRONYM = "EN Acronym",
    TH_ACRONYM = "TH Acronym",

    EN_TRANSLATION = "EN translation",
    TH_TRANSLATION = "TH translation",

    EN_MEANING = "EN Meaning",
    TH_MEANING = "TH Meaning",

    EN_EXAMPLE = "EN Example",
    TH_EXAMPLE = "TH Example",

    EN_FEEDS = "EN Feeds",
    TH_FEEDS = "TH Feeds",

    EN_FARM_EQUIPMENTS = "EN Farm equipments",
    TH_FARM_EQUIPMENTS = "TH Farm equipments",

    TH_LAB_SERVICES = "TH Lab Services",
    EN_LAB_SERVICES = "EN Lab Services",

    TH_LAB_SERVICES_PR = "TH Lab services",
    EN_LAB_SERVICES_PR = "EN Lab services",

    TH_AHBS = "TH AHBs",
    EN_AHBS = "EN AHBs",

    TH_TAG = "TH Tag",
    EN_TAG = "EN Tag",

    TH_SUB_TAGS = "TH Sub tags",
    EN_SUB_TAGS = "EN Sub tags",

    TH_SUB_GROUPS = "TH Sub groups",
    EN_SUB_GROUPS = "EN Sub groups",

    PRIORITY = "Priority",

    TITLE = "Title",
    PLATINUM_DEFAULT_AMOUNT = "Platinum default amount",
    GOLD_DEFAULT_AMOUNT = "Gold default amount",
    SILVER_DEFAULT_AMOUNT = "Silver default amount",

    EN_DILUENT = "EN Diluent",
    TH_DILUENT = "TH Diluent สารเจือจาง",

    TH_TREATMENT_PERIOD = "TH Treatment Period",
    EN_TREATMENT_PERIOD = "EN Treatment Period",

    TH_WITHDRAWAL_PERIOD = "TH Withdrawal Period",
    EN_WITHDRAWAL_PERIOD = "EN Withdrawal Period",
}

export enum SvgIcons {
    FILTER = "filter",
    CONTACT = "contact",
    HOME = "home",
    CALCULATOR = "calculator",
}

export enum FilterTitle {
    TYPE_OF_SWINE = "Type of Swine",
    ENERY_PER_KG = "Energy per kg",
    LYSINE = "Lysine %",
    PROTEIN = "Protein %",
    SUPPLIER = "Supplier",
    DELIVERY_METHOD = "Delivery Method",
    WARRANTY = "Warranty",
}

export enum GA4EventType {
    SET = "set",
    SEND = "send",
    USER_PROPERTIES = "user_properties",
    PAGE_VIEW = "pageview",
}

export enum GA4TrafficType {
    EXTERNAL = "external",
    INTERNAL = "internal",
}
export enum packageCategory {
    PLATINUM = "Platinum",
    GOLD = "Gold",
    SILVER = "Silver",
}
export enum feedCalculatorCatagory {
    BETAGRO_SWINE = "Betagro swine",
}
