import {
    getDateFormat,
    getLanguage,
    setErrorStatus,
} from "../redux/slices/appSlice"
import useGetListId from "./useGetListId"
import useGetColumnMapping from "./useGetColumnMapping"
import { ListAlias } from "../constants/enums"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import { PropType, createProductDetails, getDisplayDate } from "../utils/utils"
import { useGetListItemDetailsQuery } from "../redux/api/listApiSlice"

const useGetProductDetails = (key: string): PropType => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const language = useSelector(getLanguage)
    const dateFormat = useSelector(getDateFormat)
    const listId = useGetListId(ListAlias[`${key}` as keyof typeof ListAlias])

    const {
        columnMapping,
        isError: columnError,
        isLoading: columnsLoading,
    } = useGetColumnMapping(listId, key)

    const {
        data,
        isSuccess,
        isError: dataError,
        isLoading: dataLoading,
    } = useGetListItemDetailsQuery({
        listId,
        itemId: id,
        listName: key,
        $expand: "fields",
    })

    const productDetails = useMemo(() => {
        /* istanbul ignore else */
        if (data && Object.keys(columnMapping).length > 0) {
            return createProductDetails(columnMapping, data.fields)
        }

        return {}
    }, [isSuccess, data, columnMapping])

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    const getLastModifiedDateTime = (): string | null => {
        if (data && Object.keys(columnMapping).length > 0) {
            return getDisplayDate(dateFormat, data.lastModifiedDateTime)
        }

        return null
    }

    return {
        id,
        language,
        dateFormat,
        productDetails,
        isError: columnError || dataError,
        isLoading: columnsLoading || dataLoading,
        lastModifiedDateTime: getLastModifiedDateTime(),
    }
}

export default useGetProductDetails
