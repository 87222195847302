import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { Button } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom"
import { RoutePaths } from "../../../configs/route"
import {
    GA4EventType,
    ListAlias,
    ProductCategory,
} from "../../../constants/enums"
import useDebouncedValue from "../../../hooks/useDebouncedValue"
import useGetListId from "../../../hooks/useGetListId"
import { useGetListItemsQuery } from "../../../redux/api/listApiSlice"
import {
    getColumnMapping,
    getLanguage,
    setErrorStatus,
} from "../../../redux/slices/appSlice"
import { getProducts } from "../../../redux/slices/productRecommendationSlice"
import {
    convertToQueryString,
    ga4GA,
    getColumnName,
    getImageUrl,
    getPlaceholderImage,
    removeTrailingSlashes,
    replacePathParams,
} from "../../../utils/utils"
import Image from "../Image/Image"
import LottieLoader from "../Loader/LottieLoader"
import RouteLink from "../RouteLink/RouteLink"
import "./Search.scss"

interface ISearchDrawer {
    toggleDrawer: any
    drawerState: any
}

export default function SearchDrawer({
    drawerState,
    toggleDrawer,
}: ISearchDrawer) {
    const location = useLocation()
    const dispatch = useDispatch()
    const inputRef: any = useRef()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { category } = useParams()
    const language: string = useSelector(getLanguage)
    const [suggestions, setSuggestions] = useState([])
    const isRecommendationPage = location.pathname.includes("tags")

    const [searchParams] = useSearchParams()
    const search = searchParams.get("q")
    const [inputValue, debouncedValue, setInputValue] =
        useDebouncedValue(search)

    useEffect(() => {
        if (search === null) {
            setInputValue("")
            setSuggestions([])
        }
    }, [search])

    const { link, titleColumnAlias, listAlias, productCategory } =
        useMemo(() => {
            let link: string
            let titleColumnAlias: string
            let listAlias: ListAlias
            let productCategory

            if (isRecommendationPage) {
                productCategory = Object.values(ProductCategory).find(
                    (item: any) => {
                        return (
                            category ===
                            item.replaceAll(" ", "-").toLowerCase() + "s"
                        )
                    }
                )
            }

            switch (
                isRecommendationPage
                    ? productCategory
                    : removeTrailingSlashes(location.pathname)
            ) {
                case RoutePaths.FEED_CATALOG:
                case ProductCategory.FEED:
                    link = RoutePaths.FEED_DETAILS
                    titleColumnAlias = "FEEDTYPE"
                    listAlias = ListAlias.FEEDS
                    break
                case RoutePaths.AHB_CATALOG:
                case ProductCategory.AHB:
                    link = RoutePaths.AHB_DETAILS
                    titleColumnAlias = "PRODUCT_NAME"
                    listAlias = ListAlias.AHBS
                    break
                case RoutePaths.FARM_EQUIPMENTS_CATALOG:
                case ProductCategory.FARM_EQUIPMENT:
                    link = RoutePaths.FARM_EQUIPMENTS_DETAILS
                    titleColumnAlias = "PRODUCT_NAME"
                    listAlias = ListAlias.FARM_EQUIPMENTS
                    break
                case RoutePaths.LAB_SERVICES_CATALOG:
                case ProductCategory.LAB_SERVICE:
                    link = RoutePaths.LAB_SERVICES_DETAILS
                    titleColumnAlias = "TEST_NAME"
                    listAlias = ListAlias.LAB_SERVICES
                    break
                default:
                    link = "#"
                    titleColumnAlias = ""
                    listAlias = ListAlias.FEEDS
                    break
            }

            return { link, titleColumnAlias, listAlias, productCategory }
        }, [category, location])

    const columnMapping = useSelector((state) =>
        getColumnMapping(state, listAlias)
    )
    const listId = useGetListId(listAlias)
    const recommendationProducts = useSelector(getProducts)

    const imageField = getColumnName(columnMapping, language, "PICTURE")
    const titleFieldEN = getColumnName(columnMapping, language, titleColumnAlias)
    const titleFieldTH = getColumnName(columnMapping, language, titleColumnAlias)

    const { data, isSuccess, isFetching, isError } = useGetListItemsQuery(
        {
            listId,
            $expand: `fields($select=${titleFieldTH},${imageField})`,
            $filter: `startswith(fields/${titleFieldEN}, '${inputValue}') or startswith(fields/${titleFieldTH}, '${inputValue}')`,
            $orderby: `fields/${titleFieldTH} asc`,
            ...(!isRecommendationPage && { $top: 5 }),
        },
        {
            skip:
                titleFieldEN &&
                titleFieldTH &&
                imageField &&
                debouncedValue &&
                inputValue === debouncedValue
                    ? false
                    : true,
        }
    )

    useEffect(() => {
        if (isSuccess && imageField) {
            let _data = data
                ? data.value.map((item: any) => {
                      return {
                          id: item?.id,
                          title: language === "TH" ? item.fields.Title : item.fields[titleFieldEN],
                          image: getImageUrl(item.fields[imageField]),
                      }
                  })
                : []

            if (isRecommendationPage) {
                _data = _data
                    .filter((items: any) =>
                        recommendationProducts.includes(items.title)
                    )
                    .slice(0, 5)
            }

            setSuggestions(_data)
        }
    }, [data, imageField])

    const inputHandler = (event: any) => {
        setInputValue(event.target.value)
        if (!event.target.value) {
            setSuggestions([])
        }
    }

    const handleEnter = (event: any) => {
        if (event.key === "Enter" && inputValue) {
            const qs = convertToQueryString({
                q: inputValue,
            })
            ga4GA(GA4EventType.SEND, {
                search_term: inputValue,
                catalog_type: listAlias,
                eventAction: "search",
            })

            if (
                isRecommendationPage &&
                suggestions &&
                suggestions.length == 0
            ) {
                ga4GA(GA4EventType.SEND, {
                    search_term: inputValue,
                    eventAction: "recommendation_search_not_found",
                })
            }

            navigate(`?${qs}`, { replace: true })
            toggleDrawer("top", false)
        }
    }

    useEffect(() => {
        dispatch(setErrorStatus(isError))
    }, [isError])

    return (
        <Drawer
            anchor={"top"}
            className="search-drawer"
            open={drawerState["top"]}
            onClose={() => {
                toggleDrawer("top", false)
                setSuggestions([])
            }}
        >
            <section className="container">
                <section className="search-bar">
                    <div className="input-field">
                        <SearchIcon
                            className="searchIcon"
                            data-testid="search"
                        />
                        <input
                            autoFocus
                            type="text"
                            ref={inputRef}
                            value={inputValue}
                            onInput={inputHandler}
                            onKeyDown={handleEnter}
                            data-testid="input-field"
                            placeholder={t("search_products")}
                        />
                        {inputValue && (
                            <CloseIcon
                                onClick={() => {
                                    setInputValue("")
                                    setSuggestions([])
                                    inputRef.current.focus()
                                }}
                                className="closeIcon"
                                data-testid="cross-icon"
                            />
                        )}
                    </div>
                    <div className="button">
                        <Button
                            variant="text"
                            onClick={() => {
                                if (search) {
                                    navigate(
                                        removeTrailingSlashes(location.pathname)
                                    )
                                }
                                toggleDrawer("top", false)
                                setSuggestions([])
                                setInputValue("")
                            }}
                        >
                            {t("cancel")}
                        </Button>
                    </div>
                </section>

                <section className="suggestions">
                    <LottieLoader show={isFetching}>
                        <ul>
                            {suggestions.map(({ id, title, image }) => (
                                <li key={id}>
                                    <RouteLink
                                        to={replacePathParams(link, { id })}
                                        onClick={() => {
                                            toggleDrawer("top", false)
                                        }}
                                        className="product-item"
                                    >
                                        <div className="image">
                                            <Image
                                                src={image}
                                                alt={title}
                                                placeholder={getPlaceholderImage(
                                                    productCategory
                                                )}
                                            />
                                        </div>
                                        <div className="title">{title}</div>
                                    </RouteLink>
                                </li>
                            ))}
                        </ul>
                    </LottieLoader>
                </section>
            </section>
        </Drawer>
    )
}
