/* istanbul ignore file */
export enum RoutePaths {
    HOME = "/",
    PROFILE = "/profile",
    CALCULATOR = "/calculator",
    CONTACT = "/contacts",
    ADMIN_CALCULATOR_EXCEL_UPLOAD = "/admin/calculators",
    FEED_CATALOG = "/catalog/feeds",
    FEED_DETAILS = "/catalog/feeds/:id",
    FEED_ADDITIVE = "/catalog/feeds/additive",
    AHB_CATALOG = "/catalog/ahbs",
    AHB_DETAILS = "/catalog/ahbs/:id",
    FARM_EQUIPMENTS_CATALOG = "/catalog/farm-equipments",
    FARM_EQUIPMENTS_DETAILS = "/catalog/farm-equipments/:id",
    LAB_SERVICES_CATALOG = "/catalog/lab-services",
    LAB_SERVICES_DETAILS = "/catalog/lab-services/:id",
    PRODUCT_RECOMMENDATION_L1 = "/tags",
    PRODUCT_RECOMMENDATION_L2 = "/tags/:id",
    PRODUCT_RECOMMENDATION_RESULT = "/tags/:id/results/:category",
    AHBS_CALCULATOR = "/calculator/ahbs",
    AHBS_CALCULATOR_VACCINES = "/calculator/ahbs/vaccines",
    AHBS_CALCULATOR_MEDICINES = "/calculator/ahbs/medicines",
    AHBS_CALCULATOR_SELECT_VACCINES = "/calculator/ahbs/vaccines/select",
    AHBS_CALCULATOR_SELECT_MEDICINES = "/calculator/ahbs/medicines/select",
    AHBS_ESTIMATION_RESULT_VACCINES = "/calculator/ahbs/vaccines/results",
    AHBS_ESTIMATION_RESULT_MEDICINES = "/calculator/ahbs/medicines/results",
    FEED_CALCULATOR = "/calculator/feed",
    FEED_CALCULATOR_CATEGORY = "/calculator/feed/:category",
    FEED_CALCULATOR_PACKAGE = "/calculator/feed/:category/packages",
    FEED_CALCULATOR_PACKAGE_CUSTOMIZE = "/calculator/feed/:category/package/:package",
    FEED_CALCULATOR_SELECT_FEEDS = "/calculator/feed/:category/package/:package/feeds",
    FEED_CALCULATOR_ESTIMATION_RESULT = "/calculator/feed/:category/package/:package/results",
}
