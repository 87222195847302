import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { useLocation } from "react-router"
import { GA4EventType } from "../../../constants/enums"
import { ga4GA } from "../../../utils/utils"
import "./Accordion.scss"
import ListView from "./ListView"

export default function AccordionListView({
    title,
    productDetails,
    showBulletPoint,
    rightChildren,
    type,
}: {
    title: string
    productDetails: string[]
    showBulletPoint?: boolean
    rightChildren?: any
    type?: string
}) {
    const location = useLocation()
    const handleClickEvent = () => {
        /* istanbul ignore else */
        if (location.pathname.includes("/catalog/")) {
            const product = location.pathname.split("/").at(-1)
            const catalogType = location.pathname.split("/").at(-2)
            /* istanbul ignore else */
            if (!type) {
                ga4GA(GA4EventType.SEND, {
                    accordion_name: title,
                    eventAction: "product_detail_accordion_click",
                    catalog_type: catalogType,
                    content_id: product,
                })
            }
        }
    }
    return (
        <Accordion elevation={0} className="margin-none">
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon data-testid={`${title} Expand Icon`} />
                }
                aria-controls={title + "-content"}
                className="accordion-summary"
                onClick={handleClickEvent}
            >
                <Typography className="listview-typo">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="acc-padding-none">
                <ListView
                    productDetails={productDetails}
                    showBulletPoint={showBulletPoint}
                    rightChildren={rightChildren}
                />
            </AccordionDetails>
        </Accordion>
    )
}
