import "./AHBEstimationResult.scss"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router"
import { useMsal } from "@azure/msal-react"
import Divider from "@mui/material/Divider"
import { useTranslation } from "react-i18next"
import { RootState } from "../../../redux/store"
import { Fragment, useEffect, useRef } from "react"
import { RoutePaths } from "../../../configs/route"
import { AHBCalculatorTypes } from "./AHBCalculator"
import { useDispatch, useSelector } from "react-redux"
import Image from "../../../components/common/Image/Image"
import { ProductCategory } from "../../../constants/enums"
import {
    PropType,
    getPlaceholderImage,
    numberFormat,
} from "../../../utils/utils"
import Loader from "../../../components/common/Loader/Loader"
import { AgroLogoLowDimension } from "../../../assets/images"
import {
    setPageConfig,
    setHeaderDisplay,
} from "../../../redux/slices/pageSlice"
import {
    useCalculateMedicineResultQuery,
    useCalculateVaccineResultQuery,
} from "../../../redux/api/calculatorApiSlice"
import {
    getCalculatorDetails,
    getCalculatorItems,
    getExportPreview,
    resetCalculator,
    setExportPreview,
    setErrorStatus,
    getLanguage,
} from "../../../redux/slices/appSlice"

interface IProps {
    type: AHBCalculatorTypes
}

const AHBEstimationResult = ({ type }: IProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { accounts } = useMsal()
    const language = useSelector(getLanguage)
    const inputRef = useRef<HTMLDivElement>(null)
    const exportPreview = useSelector(getExportPreview)

    const products = useSelector((state: RootState) =>
        getCalculatorItems(state, type)
    )

    const productIds = products.map((item: PropType) => item.id).join(",")

    const info = useSelector((state: RootState) =>
        getCalculatorDetails(state, type)
    )

    const detailsNotFound = !Object.keys(info).length || !products.length

    // make calculator API call for vaccine and medicine
    const {
        data: vaccineResponse,
        isError: vaccineError,
        isFetching: vaccineLoading,
    } = useCalculateVaccineResultQuery(
        {
            piglets: Number(info.no_of_piglet),
            breeders: Number(info.no_of_breeder),
            usage_type: info.type_of_use,
            vaccine_ids: productIds,
        },
        { skip: type === "medicine" || detailsNotFound }
    )

    const {
        data: medicineResponse,
        isError: medicineError,
        isFetching: medicineLoading,
    } = useCalculateMedicineResultQuery(
        {
            language,
            swines: Number(info.no_of_swine),
            swine_weight: Number(info.average_weight),
            feed_consumption: Number(info.daily_feed_consumption),
            water_consumption: Number(info.daily_water_consumption),
            medicine_ids: productIds,
        },
        { skip: type === "vaccine" || detailsNotFound }
    )

    useEffect(() => {
        if (detailsNotFound) {
            navigate(
                type === "vaccine"
                    ? RoutePaths.AHBS_CALCULATOR_VACCINES
                    : RoutePaths.AHBS_CALCULATOR_MEDICINES
            )
        } else {
            dispatch(setExportPreview(""))
        }
    }, [])

    useEffect(() => {
        dispatch(setHeaderDisplay(exportPreview ? false : true))
    }, [exportPreview])

    useEffect(() => {
        dispatch(setErrorStatus(vaccineError || medicineError))
    }, [vaccineError, medicineError])

    useEffect(() => {
        dispatch(
            setPageConfig({
                title:
                    type === "vaccine"
                        ? t("vaccine_estimation_result")
                        : t("medicine_estimation_result"),
                heading:
                    type === "vaccine"
                        ? t("vaccine_estimation_result")
                        : t("medicine_estimation_result"),
                backButtonLink:
                    type === "vaccine"
                        ? RoutePaths.AHBS_CALCULATOR_VACCINES
                        : RoutePaths.AHBS_CALCULATOR_MEDICINES,
                rightIcon: "share",
            })
        )
    }, [])

    const handleOnInput = (event: any) => {
        if (event.target.innerText.length >= 250 && inputRef.current) {
            inputRef.current.textContent = event.target.innerText.slice(0, 250)
        }
        if (event.target.innerHTML.trim() === "<br>") {
            event.target.innerHTML = ""
        }
    }

    const handleNotes = (e: any) => {
        if (
            e.keyCode !== 8 &&
            inputRef.current &&
            inputRef?.current?.textContent
        ) {
            if (inputRef.current.textContent.length >= 250) {
                e.preventDefault()
            }
        }
    }

    return (
        <Loader show={vaccineLoading || medicineLoading}>
            {!exportPreview ? (
                <Fragment>
                    <div className="estimationResult">
                        <div id="exportHtml">
                            <div className="hide-export-image">
                                <img
                                    src={AgroLogoLowDimension}
                                    width={65}
                                    height={52}
                                    alt="export image"
                                />
                                <span>{accounts[0]?.name}</span>
                            </div>
                            <div className="summary">
                                <h2>{t("summary")}</h2>
                                {Object.entries(info).map(
                                    ([key, value]: any, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                <div className="categrory">
                                                    <h3>{t(key)}</h3>
                                                    <span>
                                                        {value && !isNaN(value)
                                                            ? numberFormat(
                                                                  Number(value)
                                                              )
                                                            : value}
                                                    </span>
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )
                                    }
                                )}
                            </div>

                            <div className="amount">
                                <h2>{t("amount_required")}</h2>
                                {products.map((item: any) => {
                                    const data: any =
                                        type === "vaccine"
                                            ? vaccineResponse?.find(
                                                  (x: any) =>
                                                      Number(item?.id) ===
                                                      x?.vaccine_id
                                              )
                                            : medicineResponse?.find(
                                                  (x: any) =>
                                                      Number(item?.id) ===
                                                      x?.medicine_id
                                              )

                                    const isNAN =
                                        type === "vaccine"
                                            ? isNaN(data?.quantity)
                                            : isNaN(data?.min_quantity) ||
                                              isNaN(data?.max_quantity)

                                    return (
                                        <Fragment key={item.id}>
                                            <div className="categrory">
                                                <div className="image">
                                                    <Image
                                                        src={item.image}
                                                        alt={item.title}
                                                        className="thumbnail"
                                                        placeholder={getPlaceholderImage(
                                                            ProductCategory.AHB
                                                        )}
                                                    />
                                                    <h3>{item.title}</h3>
                                                </div>
                                                <h3>
                                                    <span className="qty">
                                                        {!isNAN
                                                            ? type === "vaccine"
                                                                ? Math.ceil(
                                                                      Number(
                                                                          data?.quantity
                                                                      )
                                                                  )
                                                                : Math.ceil(
                                                                      Number(
                                                                          data?.min_quantity
                                                                      )
                                                                  ) +
                                                                  "-" +
                                                                  Math.ceil(
                                                                      Number(
                                                                          data?.max_quantity
                                                                      )
                                                                  )
                                                            : "N/A"}
                                                    </span>
                                                    {!isNAN && (
                                                        <span className="unit">
                                                            {type === "medicine"
                                                                ? data?.unit
                                                                : `${t(
                                                                      "amount_units"
                                                                  )}`}
                                                        </span>
                                                    )}
                                                </h3>
                                            </div>
                                            <Divider />
                                        </Fragment>
                                    )
                                })}
                            </div>

                            <div className="notes">
                                <h2>{t("notes")}</h2>
                                <div
                                    ref={inputRef}
                                    contentEditable
                                    data-text={t("note_placeholder")}
                                    className="editable"
                                    onInput={handleOnInput}
                                    onKeyDown={handleNotes}
                                />
                            </div>
                        </div>

                        <Button
                            variant="outlined"
                            className="start-over"
                            onClick={() => {
                                dispatch(resetCalculator({ type: "all" }))
                                navigate(RoutePaths.CALCULATOR)
                            }}
                        >
                            {t("start_over")}
                        </Button>
                    </div>
                </Fragment>
            ) : (
                <div className="preview">
                    <span>{t("preview_note")}</span>
                    <img src={exportPreview} alt="estimation result preview" />
                    <Button
                        variant="outlined"
                        className="back-to-result"
                        onClick={() => {
                            dispatch(setExportPreview(""))
                        }}
                    >
                        {t("back_to_result")}
                    </Button>
                </div>
            )}
        </Loader>
    )
}

export default AHBEstimationResult
