import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react"
import Home from "./components/shared/Home/Home"
import Login from "./components/shared/Login/Login"

function App() {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Home />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div>
    )
}

export default App
