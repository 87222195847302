import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import "./Breadcrumbs.scss"
import { PropType } from "../../../utils/utils"
import { getRelativeKeys } from "../../../constants/enums"
import { useSelector } from "react-redux"
import { getLanguage } from "../../../redux/slices/appSlice"

export interface IPropTypes {
    productDetails?: PropType
    productKeys: string[]
}

export interface productBCType {
    name: string
}

export default function ProductBreadcrumbs({
    productDetails = {},
    productKeys,
}: IPropTypes) {
    const language = useSelector(getLanguage)

    const getBreadcrumbs = () => {
        const productBreadcrumbs: productBCType[] = []
        productKeys.forEach((item: string) => {
            const relativekey =
                getRelativeKeys[
                    `${language}_${item}` as keyof typeof getRelativeKeys
                ]
            productBreadcrumbs.push(
                productDetails[relativekey] === undefined
                    ? "-"
                    : productDetails[relativekey]
            )
        })

        return productBreadcrumbs
    }

    return (
        <div className="breadcrumbs">
            <Stack spacing={2}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {getBreadcrumbs().map((item, key) => (
                        <Typography key={key} className="breadcrumbs-typo">
                            {item.toString()}
                        </Typography>
                    ))}
                </Breadcrumbs>
            </Stack>
        </div>
    )
}
