import { SHAREPOINT_SITE_ID, api } from "../../configs/api"
import { replacePathParams } from "../../utils/utils"
import { apiSlice } from "./apiSlice"

export const listApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLists: builder.query({
            query: (params) => {
                return {
                    url: replacePathParams(api.GET_LISTS, {
                        siteId: SHAREPOINT_SITE_ID,
                    }),
                    method: "GET",
                    ...(params && params),
                }
            },
        }),
        getListColumns: builder.query({
            query: (params) => {
                const rest = { listName: params?.listName }
                return {
                    url: replacePathParams(api.GET_COLUMNS, {
                        siteId: params?.siteId,
                        listId: params?.listId,
                    }),
                    method: "GET",
                    params: rest,
                }
            },
        }),
        getListItems: builder.query({
            query: (params) => {
                const { listId, ...rest } = params
                return {
                    url: replacePathParams(api.GET_LIST_ITEMS, {
                        siteId: SHAREPOINT_SITE_ID,
                        listId,
                    }),
                    method: "GET",
                    headers: {
                        Prefer: "HonorNonIndexedQueriesWarningMayFailRandomly",
                    },
                    params: rest,
                }
            },
        }),
        listItems: builder.mutation({
            query: (params) => {
                const { listId, ...rest } = params
                return {
                    url: replacePathParams(api.GET_LIST_ITEMS, {
                        siteId: SHAREPOINT_SITE_ID,
                        listId,
                    }),
                    method: "GET",
                    headers: {
                        Prefer: "HonorNonIndexedQueriesWarningMayFailRandomly",
                    },
                    params: rest,
                }
            },
        }),
        getListItemDetails: builder.query({
            query: (params) => {
                const { listId, itemId, ...rest } = params
                return {
                    url: replacePathParams(api.GET_LIST_ITEM_DETAILS, {
                        siteId: SHAREPOINT_SITE_ID,
                        listId: listId,
                        itemId: itemId,
                    }),
                    method: "GET",
                    params: rest,
                }
            },
        }),
        getEmployeeId: builder.query({
            query: () => {
                return {
                    url: replacePathParams(api.GET_EMP_ID, {}),
                    method: "GET",
                }
            },
        }),
        getImgDownloadUrl: builder.query({
            query: (params) => {
                const { driveId, filePath } = params
                return {
                    url: replacePathParams(api.GET_IMG_DOWNLOAD_URL, {
                        siteId: SHAREPOINT_SITE_ID,
                        driveId: driveId,
                        filePath: filePath,
                    }),
                    method: "GET",
                }
            },
        }),
    }),
})

export const {
    useGetListsQuery,
    useGetListColumnsQuery,
    useGetListItemsQuery,
    useGetListItemDetailsQuery,
    useListItemsMutation,
    useGetEmployeeIdQuery,
    useGetImgDownloadUrlQuery,
} = listApiSlice
