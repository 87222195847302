import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./AdminCalculatorExcelUpload.scss"
import Button from "../../components/common/Button/Button"
import InputField from "../../components/common/InputField/InputField"
import SelectField from "../../components/common/SelectField/SelectField"
import { PropType } from "../../utils/utils"
import { useUploadCalculatorExcelMutation } from "../../redux/api/calculatorApiSlice"
import GenericError from "../../components/shared/GenericError/GenericError"

const FORM_INITIAL_DATA: PropType = {
    file: undefined,
    modelName: "",
    uploadKey: "",
}

const MAX_FILE_SIZE_MB = 20
const MAX_FILE_SIZE_BYTE = MAX_FILE_SIZE_MB * 1000000

function AdminCalculatorExcelUpload() {
    const { t } = useTranslation()

    const [tempFormData, setTempFormData] = useState(FORM_INITIAL_DATA)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [successMsg, setSuccessMsg] = useState("")
    const [error, setError] = useState({
        isError: false,
        errorMsg: "",
    })

    const [trigger] = useUploadCalculatorExcelMutation()

    // event type is any as an easy way to allow this handler for both input and select element
    const onChangeHandler = (e: any) => {
        const newFormData = {
            ...tempFormData,
            [e.target.name]: e.target.value,
        }
        setTempFormData(newFormData)
    }

    const onFileInputChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files[0]?.size > MAX_FILE_SIZE_BYTE) {
            setError({
                isError: true,
                errorMsg: "File size too large",
            })
            e.target.value = ""
            setTempFormData({
                ...tempFormData,
                file: undefined,
            })
            return
        }

        const newFormData = {
            ...tempFormData,
            file: e.target.files ? e.target.files[0] : undefined,
        }
        setTempFormData(newFormData)
    }

    const onUploadClickHandler = async (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append("file", tempFormData.file)
        formData.append("modelName", tempFormData.modelName)

        const uploadKey = tempFormData["uploadKey"]

        try {
            const res = await trigger({ formData, uploadKey }).unwrap()
            setSuccessMsg(res.message)
            setTempFormData(FORM_INITIAL_DATA)
        } catch (error: any) {
            setError({
                isError: true,
                errorMsg: error.data?.message ?? "Something went wrong",
            })
        }
    }

    useEffect(() => {
        if (
            tempFormData.file &&
            tempFormData.modelName &&
            tempFormData.uploadKey
        ) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }
    }, [tempFormData])

    return (
        <div>
            <h1>{t("upload_calculator_excel_file")}</h1>

            {successMsg !== "" && (
                <div className="success-message">
                    <p>Upload Successful</p>
                </div>
            )}

            <GenericError
                show={error.isError}
                message={error.errorMsg}
                closeHandler={() => {
                    setError({ isError: false, errorMsg: "" })
                }}
            />

            <SelectField
                autoWidth={false}
                native={false}
                required={true}
                multiple={false}
                name="modelName"
                id="calculatorType"
                label={t("calculator_type")}
                onChange={onChangeHandler}
                value={tempFormData.modelName}
                data={[
                    {
                        label: "Feed",
                        value: "feed_calculator",
                    },
                    {
                        label: "Medicine",
                        value: "medicine_calculator",
                    },
                    {
                        label: "Vaccine",
                        value: "vaccine_calculator",
                    },
                ]}
            />

            <InputField
                name="uploadKey"
                type="text"
                required={true}
                label={t("upload_key")}
                id="uploadKey"
                placeholder={t("upload_key_placeholder")}
                onChange={onChangeHandler}
                value={tempFormData.uploadKey}
            />

            <InputField
                name="file"
                id="uploadFile"
                type="file"
                required={true}
                accept=".xls, .xlsx"
                label={`${t(
                    "calculator_excel_file"
                )} (Max. ${MAX_FILE_SIZE_MB}MB)`}
                onChange={onFileInputChangeHandler}
            />
            <Button
                disabled={isButtonDisabled}
                onClick={onUploadClickHandler}
                sx={{ marginTop: 2 }}
            >
                {t("upload_file")}
            </Button>
        </div>
    )
}

export default AdminCalculatorExcelUpload
