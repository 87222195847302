import "./CalculatorButton.scss"
import Button from "../Button/Button"
import { useTranslation } from "react-i18next"
import { PropType } from "../../../utils/utils"
import { RootState } from "../../../redux/store"
import { RoutePaths } from "../../../configs/route"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AHBCalculatorTypes } from "../../../pages/Calculator/AHB/AHBCalculator"
import {
    getCalculatorItems,
    addItemsToCalculator,
} from "../../../redux/slices/appSlice"

interface IProps {
    id: string
    image: string
    title: string
    type: AHBCalculatorTypes
}

const CalculatorButton = ({ id, image, title, type }: IProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const products = useSelector((state: RootState) =>
        getCalculatorItems(state, type)
    )
    const isDisabled = products.some((item: PropType) => item.id === id)
    const getRedirectUrl = () => {
        const path = location.pathname
        const state = location.state

        switch (true) {
            case state?.includes("vaccine"):
            case path.includes("vaccines"):
                return RoutePaths.AHBS_CALCULATOR_VACCINES
            case state?.includes("medicine"):
            case path.includes("medicines"):
                return RoutePaths.AHBS_CALCULATOR_MEDICINES
            default:
                return RoutePaths.CALCULATOR
        }
    }

    return (
        <Button
            variant="contained"
            disabled={isDisabled}
            className="calculator-btn"
            onClick={(e: any) => {
                if (!isDisabled) {
                    e.preventDefault()
                    dispatch(
                        addItemsToCalculator({
                            id,
                            type,
                            title,
                            image,
                        })
                    )

                    navigate(getRedirectUrl())
                }
            }}
        >
            {t("add_to_calculator")}
        </Button>
    )
}

export default CalculatorButton
