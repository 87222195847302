import {
    combineReducers,
    configureStore,
    PreloadedState,
} from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"
import listSlice from "./slices/listSlice"
import pageSlice from "./slices/pageSlice"
import searchSlice from "./slices/searchSlice"

import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import appSlice from "./slices/appSlice"
import productRecommendationSlice from "./slices/productRecommendationSlice"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { apiSlice } from "./api/apiSlice"
import { calculatorApiSlice } from "./api/calculatorApiSlice"
import feedCalculatorSlice from "./slices/feedCalculatorSlice"

const persistConfig = {
    key: "root",
    storage: storage,
    version: 0,
    blacklist: [
        "apiSlice",
        "calculatorApiSlice",
        "productRecommendation",
        "search",
        "page",
    ],
    stateReconciler: autoMergeLevel2,
}
const rootReducers = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    [calculatorApiSlice.reducerPath]: calculatorApiSlice.reducer,
    list: listSlice.reducer,
    app: appSlice.reducer,
    page: pageSlice.reducer,
    search: searchSlice.reducer,
    productRecommendation: productRecommendationSlice.reducer,
    feedCalculator: feedCalculatorSlice.reducer,
})

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducers)

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware: any) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }).concat([apiSlice.middleware, calculatorApiSlice.middleware]),

        devTools: true,
        preloadedState,
    })
}

export const store = setupStore()
export const persistor = persistStore(store)
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducers>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
