import { useState, useEffect } from "react"

function useDebouncedValue(initialValue: any, delay = 400) {
    const [inputValue, setInputValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedValue(inputValue)
        }, delay)

        return () => {
            clearTimeout(debounceTimeout)
        }
    }, [inputValue, delay])

    return [inputValue, debouncedValue, setInputValue]
}

export default useDebouncedValue
