import { useMemo } from "react"
import { SHAREPOINT_SITE_ID } from "../configs/api"
import { useGetListColumnsQuery } from "../redux/api/listApiSlice"
import { PropType } from "../utils/utils"

const useGetColumnMapping = (
    listId: string | null = null,
    listName: string | null = null
): PropType => {
    const { isSuccess, data, ...rest } = useGetListColumnsQuery(
        { siteId: SHAREPOINT_SITE_ID, listId, listName },
        { skip: listId ? false : true }
    )

    const { mapping, choices } = useMemo(() => {
        if (isSuccess && data?.value?.length) {
            const choices: PropType = {}
            const mapping: PropType = data?.value.reduce(
                (acc: any, curr: any) => {
                    // Sharepoint for title field behaves diffently here we filter unnecessary items of title field i.e  LinkTitle, LinkTitleNoMenu

                    if (
                        !["LinkTitle", "LinkTitleNoMenu"].includes(curr?.name)
                    ) {
                        acc[curr?.displayName] = curr?.name
                    }
                    if (curr?.choice) {
                        choices[curr?.displayName] = curr?.choice?.choices
                    }
                    return acc
                },
                {}
            )

            return { mapping, choices }
        }

        return { mapping: {}, choices: {} }
    }, [rest.fulfilledTimeStamp])

    return {
        choices,
        isSuccess,
        columnMapping: mapping,
        ...rest,
    }
}

export default useGetColumnMapping
