import "./FeedCategoryForm.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { RoutePaths } from "../../../../configs/route"
import Button from "../../../../components/common/Button/Button"
import { setPageConfig } from "../../../../redux/slices/pageSlice"
import { allowDigitsOnly, replacePathParams } from "../../../../utils/utils"
import InputField from "../../../../components/common/InputField/InputField"
import {
    getInputs,
    setInputs,
} from "../../../../redux/slices/feedCalculatorSlice"

const FeedCategoryForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { category } = useParams()
    const inputs = useSelector(getInputs)

    const [formData, setFormData] = useState({
        no_of_swine: inputs.no_of_swine || "",
        weight_in_kg: inputs.weight_in_kg || "",
        weight_out_kg: inputs.weight_out_kg || "",
        days: inputs.days || "",
    })

    const onChangeHandler = (event: any) => {
        const value = event.target.value
        const name = event.target.name
        if (event.target.tagName === "INPUT" && !allowDigitsOnly(value)) {
            return false
        }

        setFormData({ ...formData, [name]: value })
    }

    const isFilled = () => {
        return !!(
            formData.no_of_swine &&
            formData.weight_in_kg &&
            formData.weight_out_kg &&
            formData.days
        )
    }

    const onSubmit = () => {
        dispatch(setInputs(formData))
        navigate(
            replacePathParams(RoutePaths.FEED_CALCULATOR_PACKAGE, {
                category: category,
            })
        )
    }

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("feed_calculator"),
                heading: t("feed_calculator"),
                backButtonLink: RoutePaths.FEED_CALCULATOR,
                rightIcon: false,
            })
        )
    }, [])

    return (
        <section className="feed-category-input-fields">
            <p className="title">{t("use_case")}</p>
            <div className="category-form">
                <InputField
                    type="text"
                    maxLength={5}
                    required={true}
                    id="swineInput"
                    inputMode="numeric"
                    name="no_of_swine"
                    onInput={onChangeHandler}
                    label={t("no_of_Swine")}
                    placeholder={t("add_number")}
                    value={formData?.no_of_swine}
                />
                <InputField
                    type="text"
                    maxLength={2}
                    required={true}
                    id="weightInInput"
                    name="weight_in_kg"
                    inputMode="numeric"
                    label={t("weight_in_kg")}
                    onInput={onChangeHandler}
                    placeholder={t("add_number")}
                    value={formData.weight_in_kg}
                />
                <InputField
                    type="text"
                    maxLength={3}
                    required={true}
                    id="weightOutInput"
                    name="weight_out_kg"
                    inputMode="numeric"
                    label={t("weight_out_kg")}
                    onInput={onChangeHandler}
                    placeholder={t("add_number")}
                    value={formData.weight_out_kg}
                />
                <InputField
                    type="text"
                    maxLength={3}
                    required={true}
                    id="daysInput"
                    name="days"
                    inputMode="numeric"
                    label={t("days")}
                    onInput={onChangeHandler}
                    placeholder={t("add_number")}
                    value={formData.days}
                />
            </div>
            <Button
                variant="contained"
                className="category-btn"
                onClick={onSubmit}
                disabled={!isFilled()}
            >
                {t("next")}
            </Button>
        </section>
    )
}

export default FeedCategoryForm
