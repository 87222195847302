import { useMemo } from "react"
import { PropType } from "../utils/utils"
import { useSearchParams } from "react-router-dom"

const useFilters = (data: PropType[], filters: PropType = {}) => {
    const [searchParams] = useSearchParams()

    const filteredData = useMemo(() => {
        let clone = [...data]
        const search = searchParams.get("q")
        const order = searchParams.get("order")

        if (order === "desc") {
            clone = clone.reverse()
        }

        // Handle global search
        if (search) {
            const columns = filters?.search || []
            const searchTerm = search.toUpperCase()
            clone = clone.filter((item: PropType) => {
                return columns.some((i: string) => {
                    return (
                        item.fields[i] &&
                        item.fields[i].toUpperCase().startsWith(searchTerm)
                    )
                })
            })
        }

        const params: any = {}
        searchParams.forEach((value: string, key: string) => {
            if (key !== "order" && key !== "q") {
                params[key] = value.split(",")
            }
        })

        if (Object.keys(params).length) {
            // code here
            // console.log(data)
            // console.log(params)
        }

        return clone
    }, [searchParams, data])

    return {
        filteredData,
    }
}

export default useFilters
