import { Fragment } from "react"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { ProductDetails } from "./types"
import { showMultilineText } from "../../../utils/utils"

const AccordionProductDetails = ({
    productDetails,
}: {
    productDetails: ProductDetails[]
}) => {
    return (
        <List dense className="list">
            {productDetails.map((item, idx) => {
                return (
                    <Fragment key={"list-item-" + item.name}>
                        <ListItem disablePadding>
                            <ListItemButton className="listview-padding-none">
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            variant="body2"
                                            className="list-item-typo"
                                        >
                                            {item.icon && (
                                                <img
                                                    src={item.icon}
                                                    alt="icon"
                                                    className="list-item-img"
                                                />
                                            )}
                                            <span className="list-item-desc">
                                                {item.name}
                                            </span>
                                        </Typography>
                                    }
                                />
                                <div
                                    className={
                                        item.rightChildren
                                            ? "acc-desc-withChild"
                                            : "acc-pdt-dt-description"
                                    }
                                >
                                    {showMultilineText(
                                        item.value,
                                        "multiline-items",
                                        item.maxLines || Infinity
                                    )}
                                    {item.rightChildren}
                                </div>
                            </ListItemButton>
                        </ListItem>
                        {productDetails.length - 1 !== idx && <Divider />}
                    </Fragment>
                )
            })}
        </List>
    )
}

export default AccordionProductDetails
