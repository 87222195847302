import { createSlice } from "@reduxjs/toolkit"

interface IState {
    mapping: object | null
}

const initialState: IState = {
    mapping: null,
}

const listSlice = createSlice({
    name: "list",
    initialState,
    reducers: {
        setListMapping: (state, action) => {
            const listData = action.payload?.value
            const listMapping = listData.reduce((acc: any, curr: any) => {
                acc[curr?.name] = curr?.id
                return acc
            }, {})
            state.mapping = listMapping
        },
    },
})

export const { setListMapping } = listSlice.actions

export default listSlice
export const getListState = (state: any) => state.list
