import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { api, BACK_END_URL } from "../../configs/api"
import { tokenRequest } from "../../configs/auth"
import { clearPersistStore, msalInstance } from "../../utils/utils"
import { InteractionRequiredAuthError } from "@azure/msal-common"

const baseQuery = fetchBaseQuery({
    baseUrl: BACK_END_URL,
    prepareHeaders: async (headers, { endpoint }) => {
        if (endpoint !== "uploadCalculatorExcel") {
            try {
                const { accessToken } = await msalInstance.acquireTokenSilent(
                    tokenRequest
                )

                headers.set("Content-Type", "application/json")
                if (accessToken != undefined) {
                    headers.set("Authorization", "Bearer " + accessToken)
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    localStorage.removeItem("isLoggedIn")
                    clearPersistStore()
                    msalInstance.logoutRedirect()
                }
            }
        }
    },
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    const result: any = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        localStorage.removeItem("isLoggedIn")
    }
    return result
}

export const calculatorApiSlice = createApi({
    reducerPath: "calculatorApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        calculateFeedResult: builder.query({
            query: (params) => {
                return {
                    url: api.FEED_MEDICINE,
                    method: "GET",
                    params,
                }
            },
        }),
        calculateVaccineResult: builder.query({
            query: (params) => {
                return {
                    url: api.CALCULATE_VACCINE,
                    method: "GET",
                    params,
                }
            },
        }),
        calculateMedicineResult: builder.query({
            query: (params) => {
                return {
                    url: api.CALCULATE_MEDICINE,
                    method: "GET",
                    params,
                }
            },
        }),
        uploadCalculatorExcel: builder.mutation({
            query: (params) => {
                const { formData, uploadKey } = params

                return {
                    url: api.UPLOAD_CALCULATOR_EXCEL,
                    method: "PUT",
                    body: formData,
                    formData: true,
                    headers: {
                        Authorization: "Basic " + uploadKey,
                    },
                }
            },
        }),
    }),
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 1,
})

export const {
    useCalculateFeedResultQuery,
    useCalculateVaccineResultQuery,
    useCalculateMedicineResultQuery,
    useUploadCalculatorExcelMutation,
} = calculatorApiSlice
