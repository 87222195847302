import { PublicClientApplication } from "@azure/msal-browser"
import ReactGA4 from "react-ga4"
import {
    AHBPlaceHolder,
    FarmEquipementPlaceHolder,
    FeedPlaceholder,
    LabservicePlaceholder,
} from "../assets/images"
import { msalConfig } from "../configs/auth"
import { ProductCategory, getRelativeKeys } from "../constants/enums"
import { persistor } from "../redux/store"

export const replacePathParams = (path: any, params: any, prefix = ":") => {
    let newPath = path

    Object.entries(params).forEach(([key, value]) => {
        newPath = newPath.replace(prefix + key, value)
    })

    return newPath
}

export const msalInstance = new PublicClientApplication(msalConfig)

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const getImageUrl = (metadata: any) => {
    /* instanbul ignore else */
    if (isJsonString(metadata)) {
        const { serverUrl, serverRelativeUrl } = JSON.parse(metadata)
        return serverUrl + serverRelativeUrl
    }
}

export const getDisplayDate = (lang: string, date: string) => {
    let formatedDate = ""
    const splitTag = lang === "en-US" ? ", " : " "
    /* istanbul ignore else */
    if (date != null && date != "") {
        formatedDate = new Date(date.split("T")[0])
            ?.toLocaleDateString(lang, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            ?.split(splitTag)[0]
    }
    return formatedDate
}

export const createProductDetails = (column: any, row: any) => {
    const productDetails: PropType = {}
    for (const [k, v] of Object.entries(column)) {
        const idx = v as string
        /* instanbul ignore else */
        if (row[idx] != undefined) {
            productDetails[k] = row[idx]
        }
    }
    return productDetails
}

export const getFieldValue = (
    value: PropType,
    language: string,
    key: string,
    defaultValue = "-"
) => {
    const relativekey =
        getRelativeKeys[`${language}_${key}` as keyof typeof getRelativeKeys]
    let val = value[relativekey]
    if (val !== undefined) {
        /* instanbul ignore else */
        if (typeof val === "string") {
            val = val.trim()
        }
    }
    return val ?? defaultValue
}

export type PropType = {
    [key: string]: any
}

export const showMultilineText = (
    text: string | number,
    className: string,
    maxLines = Infinity
) => {
    if (typeof text == "string" && text.includes(";")) {
        let chunks: string[] = text.split(";")
        if (isFinite(maxLines)) {
            chunks = chunks.slice(0, maxLines)
        }

        return chunks.map((item, index) => (
            <span className={className} key={index}>
                {item}
            </span>
        ))
    }
    return <span className={className}>{text}</span>
}

export function removeEmptyKeys(obj: any) {
    const result: any = {}
    Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key]) && obj[key].length !== 0) {
            result[key] = obj[key]
        } else if (!Array.isArray(obj[key]) && obj[key] != null) {
            result[key] = obj[key]
        }
    })
    return result
}

export const convertToQueryString = (obj: any) => {
    return new URLSearchParams(obj).toString()
}

export const getPlaceholderImage = (productCategory?: ProductCategory) => {
    let placeHolderImg = ""
    switch (productCategory) {
        case ProductCategory.FEED: {
            placeHolderImg = FeedPlaceholder
            break
        }
        case ProductCategory.AHB: {
            placeHolderImg = AHBPlaceHolder
            break
        }
        case ProductCategory.FARM_EQUIPMENT: {
            placeHolderImg = FarmEquipementPlaceHolder
            break
        }
        case ProductCategory.LAB_SERVICE: {
            placeHolderImg = LabservicePlaceholder
            break
        }
        default: {
            placeHolderImg = FeedPlaceholder
        }
    }

    return placeHolderImg
}

export const clearPersistStore = () => {
    persistor.pause()
    persistor.flush().then(() => {
        return persistor.purge()
    })
}

export const getColumnName = (obj: any, language: string, key: string) => {
    const displayColumnName =
        getRelativeKeys[`${language}_${key}` as keyof typeof getRelativeKeys]
    return obj && displayColumnName in obj ? obj[displayColumnName] : null
}

export const escapeSingleQuotes = (string: string) => {
    return string.replace(/'/g, "\\'")
}

export const removeTrailingSlashes = (string: string) => {
    return string.replace(/\/+$/, "")
}

/**
 * Google Analytics configurations STARTS
 */
export interface IGAOptions {
    hitType?: string
    eventCategory?: string
    eventAction?: string
    eventLabel?: string
    path?: string
    target?: string
    content_type?: string
    product_name?: string
    catalog_type?: string
    content_id?: string
    accordion_name?: string
    search_term?: string
    order?: string
    field?: string
    filter?: string[]
    level?: number
    source?: string
    selected_value?: string
    count_of_selected_value?: number
}

export interface IGASetOptions {
    user_id: string
    traffic_type: string
    employee_id?: string
    environment: string
}

export const ga4GA = (type: string, options: IGAOptions) => {
    options.hitType = "event"
    ReactGA4.ga(type, options)
}

export const ga4Send = (type: string, options: IGAOptions) => {
    ReactGA4.send({
        hitType: type,
        page: options.path,
    })
}

export const ga4Initialize = (measurementID: string) => {
    ReactGA4.initialize(measurementID)
}

export const GA4GTag = (
    event: string,
    type: string,
    fieldoptions: IGASetOptions
) => {
    ReactGA4.gtag(event, type, fieldoptions)
}

/**
 * Google Analytics configurations ENDS
 */

export const htmlEncodeSingleQuotes = (string: string) => {
    return string.replace("'", "&quot;")
}

export const allowDigitsOnly = (value: string): boolean => {
    // TODO: Remove first condition and add it as a part of Regex
    if (value.includes(" ") || !new RegExp(/^(\s*|\d+)$/).test(value)) {
        return false
    }

    return true
}

export const numberFormat = (val: number) => {
    return val.toLocaleString("en-US")
}

export const compareArraysOfObjects = (arr1: PropType[], arr2: PropType[]) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const matchRoute = (routePattern: string, path: string) => {
    const regex = new RegExp(
        "^" + routePattern.replace(/:[a-zA-Z]+/g, "([a-zA-z0-9_-]+)") + "$"
    )
    return regex.test(path)
}
