import { useIsAuthenticated } from "@azure/msal-react"
import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { RoutePaths } from "../../../configs/route"
import style from "../../../styles/_variable.scss"
import { removeTrailingSlashes } from "../../../utils/utils"
import "./Footer.scss"
import { useSelector } from "react-redux"
import SvgIcon from "../SvgIcon/SvgIcon"
import { useTranslation } from "react-i18next"
import { SvgIcons } from "../../../constants/enums"
import { getCalculatorItemsCount } from "../../../redux/slices/appSlice"
import { BottomNavigation, BottomNavigationAction, Badge } from "@mui/material"

const Footer = () => {
    const location = useLocation()
    const { t } = useTranslation()
    const isAuthenticated = useIsAuthenticated()
    const { total } = useSelector(getCalculatorItemsCount)

    const value = useMemo(() => {
        const path = removeTrailingSlashes(location.pathname)
        if (path === "") {
            return "one"
        } else if (path.includes("calculator")) {
            return "two"
        } else if (path === RoutePaths.CONTACT) {
            return "three"
        } else {
            return ""
        }
    }, [location])

    if (!isAuthenticated) {
        return null
    }

    const badgeStyles = {
        "& .MuiBadge-badge": {
            border: `2px solid #EF3145`,
            padding: "0 6px",
            backgroundColor: "#EF3145",
            width: 20,
            height: 20,
            color: "white",
            fontSize: "12px",
            fontFamily: "Poppins",
        },
    }

    return (
        <footer>
            <BottomNavigation showLabels value={value} id="bottom-container">
                <BottomNavigationAction
                    label={t("home")}
                    value="one"
                    component={Link}
                    to={RoutePaths.HOME}
                    sx={{ fontSize: "10px", gap: "5px" }}
                    icon={
                        <SvgIcon
                            color={
                                value === "one" ? style.green70 : style.gray40
                            }
                            name={SvgIcons.HOME}
                        />
                    }
                />

                <BottomNavigationAction
                    label={t("calculator")}
                    value="two"
                    component={Link}
                    to={RoutePaths.CALCULATOR}
                    sx={{ fontSize: "10px", gap: "5px" }}
                    icon={
                        <Badge badgeContent={total} sx={badgeStyles}>
                            <SvgIcon
                                color={
                                    value === "two"
                                        ? style.green70
                                        : style.gray40
                                }
                                name={SvgIcons.CALCULATOR}
                            />
                        </Badge>
                    }
                />

                <BottomNavigationAction
                    label={t("contact")}
                    value="three"
                    component={Link}
                    to={RoutePaths.CONTACT}
                    sx={{ fontSize: "10px", gap: "5px" }}
                    icon={
                        <SvgIcon
                            color={
                                value === "three" ? style.green70 : style.gray40
                            }
                            name={SvgIcons.CONTACT}
                        />
                    }
                />
            </BottomNavigation>
        </footer>
    )
}

export default Footer
