import { Link, useLocation } from "react-router-dom"

const RouteLink = ({ children, state, ...props }: any) => {
    const location = useLocation()
    const stateDefaultValue = state || location.pathname
    return (
        <Link {...props} state={stateDefaultValue}>
            {children}
        </Link>
    )
}

export default RouteLink
