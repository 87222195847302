import { Button as MuiButton, ButtonProps } from "@mui/material"
import "./Button.scss"

const Button = ({
    children,
    variant = "contained",
    className = "",
    ...rest
}: ButtonProps) => {
    return (
        <MuiButton
            variant={variant}
            className={className ? `btg-button ${className}` : "btg-button"}
            {...rest}
        >
            {children}
        </MuiButton>
    )
}
export default Button
