import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Button from "../../components/common/Button/Button"
import Loader from "../../components/common/Loader/Loader"
import { RoutePaths } from "../../configs/route"
import { GA4EventType, ListAlias } from "../../constants/enums"
import useGetListId from "../../hooks/useGetListId"
import { useGetListItemsQuery } from "../../redux/api/listApiSlice"
import { setErrorStatus, getLanguage } from "../../redux/slices/appSlice"
import { ga4GA, getColumnName, replacePathParams } from "../../utils/utils"
import "./ProductRecommendTags.scss"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"

// TODO: make tags list can show in both langeuage
const ProductRecommendTags = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const listId = useGetListId(ListAlias.TAGS)
    const language = useSelector(getLanguage)

const {
        columnMapping: productTagsColumnMapping,
    } = useGetColumnMapping(
         listId,
        "product-tags"
    )
    const tagField = getColumnName(productTagsColumnMapping, language, "TAG")
    const { data, isError, isLoading } = useGetListItemsQuery(
        {
            listId,
            listName: "product-tags",
            $expand: `fields($select=Title,${tagField})`,
            $orderby: `fields/en_priority asc`,
            $filter: `fields/level eq 2`,
            $top: 20,
        },
        { skip: !listId }
    )


    useEffect(() => {
        dispatch(setErrorStatus(isError))
    }, [isError])

    const handleClick = (name: string) => {
        ga4GA(GA4EventType.SEND, {
            search_term: name,
            level: 2,
            eventAction: "recommendation_filter_home",
            source: "home",
        })
    }

    return (
        <Loader show={isLoading}>
            <div className="product-recommend">
                <div className="product-recommend-header">
                    <h2>{t("product_recommendations")}</h2>
                    <Link to={RoutePaths.PRODUCT_RECOMMENDATION_L1}>
                        {t("see_all")}
                    </Link>
                </div>
                <div className="product-list">
                    {data?.value?.map((item: any, idx: number) => {
                        const { fields, id } = item
                        return (
                            <Link
                                key={idx}
                                to={replacePathParams(
                                    RoutePaths.PRODUCT_RECOMMENDATION_RESULT,
                                    { id: id, category: "ahbs" }
                                )}
                            >
                                <Button
                                    variant="contained"
                                    className="pr-btn"
                                    onClick={() => handleClick(fields?.Title)}
                                >
                                    {language === "TH" ? fields?.Title : fields[tagField]}
                                </Button>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </Loader>
    )
}

export default ProductRecommendTags
