import { PropsWithChildren } from "react"
import AnimatedLoader from "./loader.json"
import Lottie from "lottie-react"
import "./Loader.scss"
import { useMsal } from "@azure/msal-react"

interface IProps {
    show?: boolean
}

const Loader = ({ show = true, children }: PropsWithChildren<IProps>) => {
    const { accounts } = useMsal()

    if (show === true) {
        return (
            <div
                className="loader-container"
                style={{
                    height: accounts.length ? "calc(100% - 90px)" : "100%",
                }}
            >
                <Lottie
                    animationData={AnimatedLoader}
                    loop={true}
                    className="loader"
                    data-testid="loader"
                />
            </div>
        )
    }

    return <>{children}</>
}

export default Loader
