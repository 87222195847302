import { useLocation } from "react-router"
import { GA4EventType, ProductCategory } from "../../../constants/enums"
import { AHBCalculatorTypes } from "../../../pages/Calculator/AHB/AHBCalculator"
import { ga4GA, getPlaceholderImage } from "../../../utils/utils"
import CalculatorButton from "../CalculatorButton/CalculatorButton"
import Image from "../Image/Image"
import RouteLink from "../RouteLink/RouteLink"
import "./ProductCard.scss"

export interface IProps {
    id: string
    link: string
    image: string
    title: string
    calculate?: boolean
    type: ProductCategory
    subtype?: AHBCalculatorTypes
}

const ProductCard = ({
    id,
    image,
    title,
    type,
    subtype,
    link,
    calculate,
}: IProps) => {
    const handleClickProductThumbnail = () => {
        ga4GA(GA4EventType.SEND, {
            eventAction: "select_content",
            content_type: "product",
            product_name: title,
            catalog_type: type,
            content_id: link.split("/").at(-1),
        })
    }
    const location = useLocation()
    return (
        <div className="product-card">
            <RouteLink to={link} state={location.pathname}>
                <div className="image">
                    <Image
                        src={image}
                        alt={title}
                        placeholder={getPlaceholderImage(type)}
                        onClick={handleClickProductThumbnail}
                    />
                </div>
                <div
                    className={
                        calculate
                            ? "title singleline-ellipsis"
                            : "title multiline-ellipsis"
                    }
                    title={title}
                >
                    {title}
                </div>
            </RouteLink>

            {calculate && subtype && (
                <CalculatorButton
                    id={id}
                    image={image}
                    title={title}
                    type={subtype}
                />
            )}
        </div>
    )
}

export default ProductCard
