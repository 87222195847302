import { Alert } from "../../../assets/images"
import ErrorDialog from "../../common/Dialog/ErrorDialog"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
type GenericError = {
    show?: boolean
    title?: string
    message?: string
    buttonLabel?: string
    closeHandler?: () => void
}

const GenericError = ({
    show = false,
    title,
    message,
    buttonLabel,
    children,
    closeHandler,
}: PropsWithChildren<GenericError>) => {
    const { t } = useTranslation()
    const errorTitle = title || t("error")
    const messageTitle = message || t("something_wrong")
    const buttonLabelTitle = buttonLabel || t("okay")

    if (show === true) {
        if (!navigator.onLine) {
            return (
                <>
                    {children}
                    <ErrorDialog
                        title={t("no_network")}
                        img={Alert}
                        message={t("check_connection")}
                        buttonText={buttonLabelTitle}
                        closeHandler={closeHandler}
                    />
                </>
            )
        }
        return (
            <>
                {children}
                <ErrorDialog
                    title={errorTitle}
                    img={Alert}
                    message={messageTitle}
                    buttonText={buttonLabelTitle}
                    closeHandler={closeHandler}
                />
            </>
        )
    }

    return <>{children}</>
}

export default GenericError
