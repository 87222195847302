import "./FeedEstimation.scss"
import { Divider } from "@mui/material"
import { useEffect, useMemo, useRef } from "react"
import { useMsal } from "@azure/msal-react"
import { useTranslation } from "react-i18next"
import { RootState } from "../../../../redux/store"
import { useDispatch, useSelector } from "react-redux"
import { RoutePaths } from "../../../../configs/route"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../../../components/common/Button/Button"
import Image from "../../../../components/common/Image/Image"
import Loader from "../../../../components/common/Loader/Loader"
import { InfoCircle, AgroLogoLowDimension } from "../../../../assets/images"
import CustomAccordion from "../../../../components/common/Accordion/Accordion"
import { useCalculateFeedResultQuery } from "../../../../redux/api/calculatorApiSlice"
import {
    setHeaderDisplay,
    setPageConfig,
} from "../../../../redux/slices/pageSlice"
import {
    PropType,
    getImageUrl,
    getPlaceholderImage,
    numberFormat,
    replacePathParams,
} from "../../../../utils/utils"
import {
    reset,
    Packages,
    getInputs,
    getProducts,
} from "../../../../redux/slices/feedCalculatorSlice"
import {
    getExportPreview,
    setErrorStatus,
    setExportPreview,
} from "../../../../redux/slices/appSlice"

const FeedEstimation = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { accounts } = useMsal()
    const inputs = useSelector(getInputs)

    const inputRef = useRef<HTMLDivElement>(null)
    const exportPreview = useSelector(getExportPreview)
    const { category, package: packageType } = useParams()
    const products =
        useSelector((state: RootState) => {
            return getProducts(state, packageType as Packages)
        })?.updated ?? []
    const queryString: PropType = {}
    products.forEach((item: any, index: number) => {
        queryString[`feeds[${index}][id]`] = item.id
        queryString[`feeds[${index}][amount]`] = Number(item.amount_of_feed)
        queryString[`feeds[${index}][number]`] = Number(
            item.feed_additive_number
        )
    })

    const { data, isLoading, isError } = useCalculateFeedResultQuery(
        {
            program: packageType,
            duration: inputs.days,
            swines: inputs.no_of_swine,
            weight_in: inputs.weight_in_kg,
            weight_out: inputs.weight_out_kg,
            ...queryString,
        },
        { skip: false }
    )

    const productsData = useMemo(() => {
        if (isError || !data) {
            return {}
        }

        const products: PropType = {}
        if (data?.products?.length) {
            data?.products.forEach((item: any) => {
                const { id, ...rest } = item
                products[id] = rest
            })
        }

        return products
    }, [data])

    const handleOnInput = (event: any) => {
        if (event.target.innerText.length >= 250 && inputRef.current) {
            inputRef.current.textContent = event.target.innerText.slice(0, 250)
        }
        if (event.target.innerHTML.trim() === "<br>") {
            event.target.innerHTML = ""
        }
    }

    const handleNotes = (e: any) => {
        if (
            e.keyCode !== 8 &&
            inputRef.current &&
            inputRef?.current?.textContent
        ) {
            if (inputRef.current.textContent.length >= 250) {
                e.preventDefault()
            }
        }
    }

    const getSummary = () => {
        return Object.entries(inputs)
            .map(([key, value]: any): PropType => {
                return {
                    name: t(key),
                    value:
                        value && !isNaN(value)
                            ? numberFormat(Number(value))
                            : value,
                }
            })
            .concat([
                {
                    name: t("feed_intake"),
                    value: (
                        <span className="green-color">
                            {data?.feed_intake
                                ? numberFormat(data?.feed_intake.toFixed(2))
                                : ""}
                        </span>
                    ),
                },
                {
                    name: t("fcr"),
                    value: (
                        <span className="green-color">
                            {data?.fcr
                                ? numberFormat(data?.fcr.toFixed(2))
                                : ""}
                        </span>
                    ),
                },
                {
                    name: t("adg"),
                    value: (
                        <span className="green-color">
                            {data?.adg
                                ? numberFormat(data?.adg.toFixed(2))
                                : ""}
                        </span>
                    ),
                },
                {
                    name: t("fcg"),
                    value: (
                        <span className="green-color">
                            {data?.fcg
                                ? numberFormat(data?.fcg.toFixed(2))
                                : ""}
                        </span>
                    ),
                },
            ])
    }

    const getProductDetails = (product: PropType) => {
        const id = Number(product.id)
        return [
            {
                name: t("amt_of_feed"),
                value: Number(product.amount_of_feed),
            },
            {
                name: t("feed_additive_number"),
                value: Number(product.feed_additive_number),
            },
            {
                name: t("net_price"),
                value: isNaN(productsData[id]?.net_price)
                    ? "N/A"
                    : numberFormat(productsData[id]?.net_price),
            },
            {
                name: t("total_baht"),
                value: isNaN(productsData[id]?.total_price)
                    ? "N/A"
                    : numberFormat(productsData[id]?.total_price),
            },
        ]
    }

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("feed_estimation"),
                heading: t("feed_estimation"),
                backButtonLink: replacePathParams(
                    RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE,
                    { category, package: packageType }
                ),
                rightIcon: "share",
            })
        )
    }, [])

    useEffect(() => {
        dispatch(setHeaderDisplay(exportPreview ? false : true))
    }, [exportPreview])

    useEffect(() => {
        dispatch(setErrorStatus(isError))
    }, [isError])

    return (
        <Loader show={isLoading}>
            {!exportPreview ? (
                <div className="feed-estimation-result estimationResult">
                    <div id="exportHtml">
                        <div className="hide-export-image">
                            <img
                                src={AgroLogoLowDimension}
                                width={65}
                                height={52}
                                alt="export image"
                            />
                            <div>
                                <span>{accounts[0]?.name}</span>
                                <span>
                                    {new Date().toLocaleString("th-TH")}
                                </span>
                            </div>
                        </div>
                        <CustomAccordion
                            title={"Summary"}
                            productDetails={getSummary()}
                            expand
                        />
                        <Divider />
                        <div className="grand-total">
                            <p>{t("grand_total")}</p>
                            <div className="sub-items">
                                <p>{t("amt_of_feed")}</p>
                                <span>
                                    {data?.total_quantity
                                        ? numberFormat(data?.total_quantity)
                                        : ""}
                                </span>
                            </div>
                            <div className="sub-items">
                                <p>{t("total")}</p>
                                <span>
                                    {data?.total_amount
                                        ? numberFormat(data?.total_amount)
                                        : ""}
                                </span>
                            </div>
                            <Divider />
                        </div>
                        <div className="info-circle">
                            <img src={InfoCircle} />
                            <span>{t("feed_calculator_result_info")}</span>
                        </div>
                        <div className="amt-required">
                            <p>{t("amount_required")}</p>
                            {products.map((item: PropType) => (
                                <>
                                    <CustomAccordion
                                        expand
                                        key={item.id}
                                        productDetails={getProductDetails(item)}
                                        title={
                                            <>
                                                <Image
                                                    alt={item.title}
                                                    className="image"
                                                    src={getImageUrl(
                                                        item?.image
                                                    )}
                                                    placeholder={getPlaceholderImage()}
                                                />
                                                <span>{item.title}</span>
                                            </>
                                        }
                                    />
                                    <Divider />
                                </>
                            ))}
                        </div>
                        <div className="notes">
                            <h2>{t("notes")}</h2>
                            <div
                                ref={inputRef}
                                contentEditable={true}
                                className="editable"
                                onInput={handleOnInput}
                                onKeyDown={handleNotes}
                                data-text={t("note_placeholder")}
                            />
                        </div>
                    </div>
                    <Button
                        variant="outlined"
                        className="start-over"
                        onClick={() => {
                            dispatch(reset())
                            navigate(RoutePaths.FEED_CALCULATOR)
                        }}
                    >
                        {t("start_over")}
                    </Button>
                </div>
            ) : (
                <div className="preview">
                    <span>{t("preview_note")}</span>
                    <img src={exportPreview} alt="estimation result preview" />
                    <Button
                        variant="outlined"
                        className="back-to-result"
                        onClick={() => {
                            dispatch(setExportPreview(""))
                        }}
                    >
                        {t("back_to_result")}
                    </Button>
                </div>
            )}
        </Loader>
    )
}

export default FeedEstimation
