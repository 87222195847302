import { faRotateRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CloseIcon from "@mui/icons-material/Close"
import { Divider } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { NoResultCross } from "../../../../../assets/images"
import Button from "../../../../../components/common/Button/Button"
import InputField from "../../../../../components/common/InputField/InputField"
import Loader from "../../../../../components/common/Loader/Loader"
import NoResultFound from "../../../../../components/common/NoResultFound/NoResultFound"
import { RoutePaths } from "../../../../../configs/route"
import useGetListId from "../../../../../hooks/useGetListId"
import Image from "../../../../../components/common/Image/Image"
import useGetColumnMapping from "../../../../../hooks/useGetColumnMapping"
import { useGetListItemsQuery } from "../../../../../redux/api/listApiSlice"
import {
    getLanguage,
    setErrorStatus,
} from "../../../../../redux/slices/appSlice"
import {
    GA4EventType,
    ListAlias,
    getRelativeKeys,
} from "../../../../../constants/enums"
import {
    Packages,
    getIsLoaded,
    getProducts,
    removeProduct,
    setIsLoaded,
    setProducts,
    updateProduct,
} from "../../../../../redux/slices/feedCalculatorSlice"
import { setPageConfig } from "../../../../../redux/slices/pageSlice"
import { RootState } from "../../../../../redux/store"
import {
    PropType,
    allowDigitsOnly,
    compareArraysOfObjects,
    ga4GA,
    getColumnName,
    getImageUrl,
    getPlaceholderImage,
    replacePathParams,
} from "../../../../../utils/utils"
import "./FeedPackageDetails.scss"

const FeedPackageDetail = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { category, package: packageType } = useParams()
    const language = useSelector(getLanguage)
    const listId = useGetListId(ListAlias.FEED_CALCULATION)
    const products = useSelector((state: RootState) => {
        return getProducts(state, packageType as Packages)
    })

    const isLoaded = useSelector((state: RootState) => {
        return getIsLoaded(state, packageType as Packages)
    })

    const updatedProducts = products?.updated
    const defaultProducts = products?.defaults

    // API calls for feed calculation list
    const {
        columnMapping,
        isError: columnError,
        isFetching: columnLoading,
    } = useGetColumnMapping(isLoaded ? null : listId, "feed-calculation")

    const imageField = getColumnName(columnMapping, language, "PICTURE")
    const titleField = columnMapping[getRelativeKeys.TITLE]
    const platinumPackageField =
        columnMapping[getRelativeKeys.PLATINUM_DEFAULT_AMOUNT]
    const goldPackageField = columnMapping[getRelativeKeys.GOLD_DEFAULT_AMOUNT]
    const silverPackageField =
        columnMapping[getRelativeKeys.SILVER_DEFAULT_AMOUNT]

    const packageField =
        packageType === "platinum"
            ? platinumPackageField
            : packageType === "gold"
            ? goldPackageField
            : packageType === "silver"
            ? silverPackageField
            : null

    const {
        data,
        isError: dataError,
        isFetching: dataLoading,
    } = useGetListItemsQuery(
        {
            listId,
            $expand: `fields($select=${titleField},${packageField},${imageField})`,
            $filter: `fields/${packageField} ne null and fields/${packageField} ne 0`,
            listName: "feed-calculation",
        },
        { skip: Object.keys(columnMapping).length && !isLoaded ? false : true }
    )

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("package_custom"),
                heading: t("package_custom"),
                rightIcon: "additive",
                backButtonLink: replacePathParams(
                    RoutePaths.FEED_CALCULATOR_PACKAGE,
                    {
                        category,
                    }
                ),
            })
        )
    }, [])

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    const onChangeHandler = (e: any, id: string) => {
        const { name, value } = e.target

        if (!allowDigitsOnly(value)) {
            return false
        }

        if (name === "amount_of_feed" && (value < 0 || value > 999)) {
            return false
        }

        if (name === "feed_additive_number" && (value < 0 || value > 58)) {
            return false
        }

        dispatch(
            updateProduct({
                id,
                type: packageType,
                [name]: value,
            })
        )
    }

    const handleDelete = (id: string) => {
        dispatch(removeProduct({ type: packageType, id }))
    }

    const handleReset = () => {
        dispatch(setIsLoaded({ type: packageType, isLoaded: false }))
    }

    const resultDisabled = () => {
        return (
            !updatedProducts ||
            !updatedProducts?.length ||
            updatedProducts.some(
                (item: PropType) => item?.amount_of_feed === ""
            )
        )
    }

    const resetDisabled = () => {
        return (
            defaultProducts &&
            defaultProducts.length > 0 &&
            compareArraysOfObjects(defaultProducts, updatedProducts)
        )
    }

    useEffect(() => {
        if (data?.value?.length) {
            const feedPackageData = data?.value?.map((item: PropType) => ({
                id: item?.id,
                title: item?.fields[titleField],
                image: item?.fields[imageField],
                amount_of_feed: item?.fields[packageField],
                feed_additive_number: 0,
            }))
            dispatch(
                setProducts({
                    type: packageType,
                    isDefault: true,
                    products: feedPackageData,
                })
            )

            dispatch(setIsLoaded({ type: packageType, isLoaded: true }))
        }
    }, [data])

    return (
        <Loader show={columnLoading || dataLoading}>
            <div className="feed-package-detail">
                {updatedProducts && updatedProducts?.length ? (
                    updatedProducts?.map((item: any, index: number) => (
                        <div className="product-item" key={index}>
                            <div className="product-header">
                                <Image
                                    alt={item.title}
                                    src={getImageUrl(item?.image)}
                                    placeholder={getPlaceholderImage()}
                                />
                                <p>{item?.title}</p>
                                <div className="remove">
                                    <CloseIcon
                                        className="closeIcon"
                                        data-testid="cross-icon"
                                        onClick={() => {
                                            handleDelete(item?.id)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="package-custom-inputs">
                                <InputField
                                    type="text"
                                    maxLength={3}
                                    required={true}
                                    id={"amountInput-" + index}
                                    inputMode="numeric"
                                    name="amount_of_feed"
                                    onInput={(e) => {
                                        onChangeHandler(e, item.id)
                                    }}
                                    label={t("amt_of_feed")}
                                    value={item?.amount_of_feed ?? 0}
                                />
                                <InputField
                                    type="text"
                                    maxLength={2}
                                    inputMode="numeric"
                                    name="feed_additive_number"
                                    label={t("feed_additive_number")}
                                    id={"additiveInput-" + index}
                                    onInput={(e) => {
                                        onChangeHandler(e, item.id)
                                    }}
                                    value={item.feed_additive_number ?? 0}
                                />
                            </div>
                            <Divider />
                        </div>
                    ))
                ) : (
                    <NoResultFound
                        title={t("no_feed_found")}
                        button={{
                            show: true,
                            label: t("select_feed"),
                            onClick: () => {
                                navigate(
                                    replacePathParams(
                                        RoutePaths.FEED_CALCULATOR_SELECT_FEEDS,
                                        { category, package: packageType }
                                    )
                                )
                            },
                        }}
                        image={NoResultCross}
                    />
                )}
                {updatedProducts && updatedProducts.length !== 0 && (
                    <Button
                        className="add-more-btn"
                        disabled={updatedProducts.length >= 10}
                        onClick={() => {
                            if (updatedProducts.length <= 10) {
                                navigate(
                                    replacePathParams(
                                        RoutePaths.FEED_CALCULATOR_SELECT_FEEDS,
                                        { category, package: packageType }
                                    )
                                )
                            }
                        }}
                    >
                        {t("add_more")}
                    </Button>
                )}
                <section className="action-btns">
                    <Button
                        variant="outlined"
                        className="clear-btn"
                        onClick={handleReset}
                        disabled={resetDisabled()}
                    >
                        <FontAwesomeIcon
                            size="2xs"
                            icon={faRotateRight}
                            style={{ transform: "translateX(-3px)" }}
                        />
                        <span>{t("reset")}</span>
                    </Button>
                    <Button
                        variant="contained"
                        className="result-btn"
                        onClick={() => {
                            ga4GA(GA4EventType.SEND, {
                                target: "feed",
                                eventAction: "see_result_click",
                            })
                            if (
                                products.updated &&
                                products.updated.length !== 0
                            ) {
                                products.updated.map((value: any) => {
                                    ga4GA(GA4EventType.SEND, {
                                        selected_value: value.title,
                                        eventAction: `selected_feed`,
                                        count_of_selected_value:
                                            products.updated.length,
                                    })
                                })
                            }
                            navigate(
                                replacePathParams(
                                    RoutePaths.FEED_CALCULATOR_ESTIMATION_RESULT,
                                    { category, package: packageType }
                                )
                            )
                        }}
                        disabled={resultDisabled()}
                    >
                        {t("see_result")}
                    </Button>
                </section>
            </div>
        </Loader>
    )
}

export default FeedPackageDetail
