import React from "react"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import Divider from "@mui/material/Divider"
import { FeedAdditiveDetails } from "../../components/common/Accordion/types"
import Pagination from "../../components/common/Pagination/Pagination"
import NoResultFound from "../../components/common/NoResultFound/NoResultFound"
import usePagination from "../../hooks/usePagination"

const FeedAdditiveAccordian = ({
    feedAdditives,
}: {
    feedAdditives: FeedAdditiveDetails[]
}) => {
    const { page, pages, paginatedData, setPage } = usePagination(feedAdditives)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    if (paginatedData.length === 0) {
        return <NoResultFound />
    }

    return (
        <>
            <div className="feed-additive-accordians">
                {paginatedData.map(
                    (val: FeedAdditiveDetails, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <CustomAccordion
                                    title={val.title}
                                    productDetails={val.details}
                                />
                                <Divider />
                            </React.Fragment>
                        )
                    }
                )}
            </div>

            <Pagination count={pages} onChange={handleChange} page={page} />
        </>
    )
}

export default FeedAdditiveAccordian
