import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { SHAREPOINT_BASE_URL } from "../../configs/api"
import { tokenRequest } from "../../configs/auth"
import { clearPersistStore, msalInstance } from "../../utils/utils"
import { InteractionRequiredAuthError } from "@azure/msal-browser"

const baseQuery = fetchBaseQuery({
    baseUrl: SHAREPOINT_BASE_URL,
    prepareHeaders: async (headers) => {
        try {
            const { accessToken } = await msalInstance.acquireTokenSilent(
                tokenRequest
            )
            headers.set(
                "Prefer",
                "HonorNonIndexedQueriesWarningMayFailRandomly"
            )
            if (accessToken != undefined) {
                headers.set("Authorization", "Bearer " + accessToken)
            }
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                localStorage.removeItem("isLoggedIn")
                clearPersistStore()
                msalInstance.logoutRedirect()
            }
        }
    },
})
export const baseQueryWithReauth = async (
    args: any,
    api: any,
    extraOptions: any
) => {
    const result: any = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        localStorage.removeItem("isLoggedIn")
    }
    return result
}

export const apiSlice = createApi({
    reducerPath: "apiSlice",
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 1,
})
