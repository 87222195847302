import "./FeedAdditive.scss"
import { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../configs/route"
import { ListAlias } from "../../constants/enums"
import useGetListId from "../../hooks/useGetListId"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../components/common/Loader/Loader"
import FeedAdditiveAccordian from "./FeedAdditiveAccordian"
import { setPageConfig } from "../../redux/slices/pageSlice"
import useGetColumnMapping from "../../hooks/useGetColumnMapping"
import { useGetListItemsQuery } from "../../redux/api/listApiSlice"
import { createProductDetails, getFieldValue } from "../../utils/utils"
import { getLanguage, setErrorStatus } from "../../redux/slices/appSlice"

const FeedAdditive = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const language = useSelector(getLanguage)
    const { t } = useTranslation()
    const listId = useGetListId(ListAlias.FEED_ADDITIVE)
    const {
        columnMapping,
        isError: columnError,
        isLoading: columnLoading,
    } = useGetColumnMapping(listId, "feed-additives")

    const {
        data,
        isError: dataError,
        isSuccess: dataSuccess,
        isLoading: dataLoading,
    } = useGetListItemsQuery({
        listId,
        $expand: "fields",
        listName: "feed-additives",
    })

    const additives = useMemo(() => {
        /* istanbul ignore else */
        if (
            !dataSuccess ||
            !columnMapping ||
            Object.keys(columnMapping).length === 0
        ) {
            return []
        }

        return data?.value?.map((item: any) => {
            const mapping = createProductDetails(columnMapping, item.fields)
            return {
                title: getFieldValue(mapping, language, "ADDITIVE_NUMBER"),
                details: [
                    {
                        name: t("active_ingredients"),
                        value: getFieldValue(
                            mapping,
                            language,
                            "ACTIVE_INGREDIENT"
                        ),
                    },
                    {
                        name: t("trade_name"),
                        value: getFieldValue(mapping, language, "TRADE_NAME"),
                    },
                    {
                        name: `${t("PPM")} (Dose)`,
                        value: getFieldValue(mapping, language, "DOSE_PPM"),
                    },
                    {
                        name: `${t("additive_price")} (THB/Ton)`,
                        value: getFieldValue(
                            mapping,
                            language,
                            "ADDITIVE_PRICE"
                        ),
                    },
                    {
                        name: t("application"),
                        value: getFieldValue(
                            mapping,
                            language,
                            "FEED_ADDITIVE_APPLICATION"
                        ),
                    },
                    {
                        name: t("treatment_period"),
                        value: getFieldValue(
                            mapping,
                            language,
                            "TREATMENT_PERIOD"
                        ),
                    },
                    {
                        name: t("withdrawal_period"),
                        value: getFieldValue(
                            mapping,
                            language,
                            "WITHDRAWAL_PERIOD"
                        ),
                    },
                ],
            }
        })
    }, [language, data, columnMapping])

    useEffect(() => {
        dispatch(setErrorStatus(columnError || dataError))
    }, [columnError, dataError])

    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: "additive",
                backButtonLink: location.state?.to || RoutePaths.FEED_CATALOG,
                backButtonState: location.state?.from,
            })
        )
    }, [])

    return (
        <Loader show={columnLoading || dataLoading}>
            <div className="fda-container">
                <h1 className="fda-heading">{t("drug_list")}</h1>
                <FeedAdditiveAccordian feedAdditives={additives} />
            </div>
        </Loader>
    )
}

export default FeedAdditive
