import ProductBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs"
import { Divider } from "@mui/material"
import Loader from "../../components/common/Loader/Loader"
import ProductInformation from "../../components/shared/ProductInformation/ProductInformation"
import { ProductCategory } from "../../constants/enums"
import useGetProductDetails from "../../hooks/useGetProductDetails"
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../configs/route"
import { setPageConfig } from "../../redux/slices/pageSlice"
import { Animal, Method, Note, Timer } from "../../assets/images"
import { ProductDetails } from "../../components/common/Accordion/types"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import {
    getFieldValue,
    getImageUrl,
    getPlaceholderImage,
} from "../../utils/utils"
import { useLocation } from "react-router-dom"

const LabServiceProductDetails = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const { id, isLoading, productDetails, language, lastModifiedDateTime } =
        useGetProductDetails("LAB_SERVICES")
    const productImage: any = getFieldValue(productDetails, language, "PICTURE")

    const serviceInformation: ProductDetails[] = useMemo(() => {
        if (Object.keys(productDetails).length === 0) {
            return []
        }

        return [
            {
                name: t("method"),
                value: getFieldValue(productDetails, language, "METHOD"),
                icon: Method,
            },
            {
                name: t("test_duration"),
                value: `${getFieldValue(
                    productDetails,
                    language,
                    "TEST_DURATION"
                )} ${t("days")}`,
                icon: Timer,
            },
            {
                name: t("result_format"),
                value: getFieldValue(productDetails, language, "RESULT_FORMAT"),
                icon: Note,
            },
            {
                name: t("swine_life_cycle"),
                value: getFieldValue(
                    productDetails,
                    language,
                    "SWINE_LIFE_CYCLE"
                ),
                icon: Animal,
            },
        ]
    }, [language, productDetails])

    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: false,
                backButtonLink:
                    location.state || RoutePaths.LAB_SERVICES_CATALOG,
            })
        )
    }, [])

    return (
        <Loader show={isLoading}>
            {Object.keys(productDetails).length > 0 && (
                <>
                    <ProductBreadcrumbs
                        productDetails={productDetails}
                        productKeys={[
                            "LAB_TESTING_SERVICE",
                            "TEST_CATEGORY",
                            "TEST_NAME",
                        ]}
                    />
                    <ProductInformation
                        id={id}
                        name={getFieldValue(
                            productDetails,
                            language,
                            "TEST_NAME"
                        )}
                        description={getFieldValue(
                            productDetails,
                            language,
                            "TEST_CATEGORY"
                        )}
                        date={lastModifiedDateTime}
                        img={getImageUrl(productImage)}
                        defaultImage={getPlaceholderImage(
                            ProductCategory.LAB_SERVICE
                        )}
                    />
                    <Divider />
                    <CustomAccordion
                        title={t("service_information")}
                        productDetails={serviceInformation}
                        expand
                    />
                    <Divider />
                </>
            )}
        </Loader>
    )
}

export default LabServiceProductDetails
