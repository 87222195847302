import { SHAREPOINT_IMG_DRIVE_ID } from "../../../configs/api"
import { useGetImgDownloadUrlQuery } from "../../../redux/api/listApiSlice"

export interface Props extends React.HTMLProps<HTMLImageElement> {
    placeholder?: string
}

const Image = ({ src, title, alt = "", placeholder, ...props }: Props) => {
    /* original image URL is betagro.sharepoint.com/sites/AgroDigitalMVPSite/SiteAssets/Lists/path/to/photo.jpg
    for the file path, we only need the portion /Lists/... */
    const sharepointImage =
        src && src.includes("/Lists")
            ? src.substring(src.indexOf("/Lists"))
            : ""

    const { data } = useGetImgDownloadUrlQuery(
        {
            driveId: SHAREPOINT_IMG_DRIVE_ID,
            filePath: ":".concat(sharepointImage),
        },
        {
            skip: sharepointImage === "",
        }
    )

    const imgDownloadUrl = data
        ? data["@microsoft.graph.downloadUrl"]
        : undefined

    const onImageError = (e: any) => {
        const target = e.target as HTMLImageElement
        console.error("Unable to load image with src " + target.src)
        if (placeholder) {
            target.src = placeholder
        }
    }

    return (
        <img
            alt={alt}
            title={title || alt}
            onError={onImageError}
            src={imgDownloadUrl || placeholder}
            {...props}
        />
    )
}

export default Image
