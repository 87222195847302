import { useParams } from "react-router"
import NotFound from "../NotFound/NotFound"
import { ProductCategory } from "../../constants/enums"
import ProductCatalog from "../ProductCatalog/ProductCatalog"

const ProductRecommendations = () => {
    const { category } = useParams()

    const productCategory = Object.values(ProductCategory).find((item: any) => {
        return category === item.replaceAll(" ", "-").toLowerCase() + "s"
    })

    if (productCategory) {
        return <ProductCatalog productCategory={productCategory} />
    }

    return <NotFound />
}

export default ProductRecommendations
