import { useEffect, useState } from "react"
import { SHAREPOINT_BASE_URL, SHAREPOINT_SITE_ID } from "../configs/api"
import { baseQueryWithReauth as baseQuery } from "../redux/api/apiSlice"
import { getLanguage } from "../redux/slices/appSlice"
import { useSelector } from "react-redux"
import {
    PropType,
    convertToQueryString,
    replacePathParams,
} from "../utils/utils"

type options = {
    skip?: boolean
}

const useMakeChunkRequest = (
    endpoint: string,
    params: PropType,
    options: options
) => {
    const language = useSelector(getLanguage)
    const maxUrlLength = 2048
    const expressionLimit = 10
    const [data, setData] = useState<any[]>([])
    const [error, setError] = useState<any>(null)
    const [isError, setIsError] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(!options.skip)
    const { listId, $filter, $sortOrder, ...searchParams } = params
    const searchString = Object.keys(params).length
        ? "?" + convertToQueryString(searchParams)
        : ""
    endpoint = replacePathParams(endpoint, {
        siteId: SHAREPOINT_SITE_ID,
        listId,
    })
    const url = SHAREPOINT_BASE_URL + endpoint + searchString
    const urls: string[] = []

    useEffect(() => {
        if (options.skip) return

        if ($filter) {
            const maxFilterLength =
                maxUrlLength - url.length - "&$filter=".length
            const filters = $filter.split(" or ")

            let currentFilters: any[] = []

            for (let i = 0; i < filters.length; i++) {
                const filterString =
                    filters.length === 1
                        ? filters.join(" or ")
                        : currentFilters.join(" or ")
                const nextString =
                    filterString + " or " + encodeURIComponent(filters[i])

                if (
                    nextString.length <= maxFilterLength &&
                    currentFilters.length <= expressionLimit
                ) {
                    currentFilters.push(encodeURIComponent(filters[i]))
                } else {
                    urls.push(`${url}&$filter=${currentFilters.join(" or ")}`)
                    currentFilters = [filters[i]]
                }

                if (i === filters.length - 1) {
                    urls.push(`${url}&$filter=${currentFilters.join(" or ")}`)
                }
            }
        } else {
            urls.push(url)
        }

        if (urls.length) {
            setIsError(false)
            setIsLoading(true)

            const promises: any[] = []
            urls.forEach((url: string) => {
                promises.push(baseQuery(url, {}, { method: "GET" }))
            })

            Promise.all(promises)
                .then((results: any[]) => {
                    // Check for blocked url errors
                    const error = results.find(
                        (item) => item?.error !== undefined
                    )

                    if (error) {
                        return Promise.reject(
                            new Error("Some of URLs blocked by Firewall")
                        )
                    } else {
                        // merge response data
                        let data = results.reduce((acc: any[], curr: any) => {
                            return [...acc, ...curr.data.value]
                        }, [])

                        // remove duplicate items
                        data = data.filter((item: any, index: number) => {
                            return (
                                index ===
                                data.findIndex((el: any) => item.id === el.id)
                            )
                        })

                        // Apply sorting client side
                        if ($sortOrder) {
                            data.sort((a, b) => {
                                const nameA = a.fields.Title.toUpperCase()
                                const nameB = b.fields.Title.toUpperCase()

                                if (nameA < nameB) {
                                    return $sortOrder == "asc" ? -1 : 1
                                }

                                if (nameA > nameB) {
                                    return $sortOrder == "asc" ? 1 : -1
                                }

                                // names must be equal
                                return 0
                            })
                        }

                        setError(null)
                        setIsLoading(false)
                        setIsSuccess(true)
                        setData(data)
                    }
                })
                .catch((error) => {
                    setError(error)
                    setIsError(true)
                    setIsSuccess(false)
                    setIsLoading(false)
                    console.error(
                        `An error while fetching inside useMakeChunkRequest`,
                        error
                    )
                })
        }
    }, [options.skip, listId, $filter, $sortOrder, language])

    return { isSuccess, isError, isLoading, data, error }
}

export default useMakeChunkRequest
