import "./PageLayout.scss"
import { useEffect } from "react"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { ThemeProvider, createTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"
import { GA4EventType, GA4TrafficType } from "../../constants/enums"
import useIs404 from "../../hooks/useIs404"
import {
    useGetEmployeeIdQuery,
    useGetListsQuery,
} from "../../redux/api/listApiSlice"
import { getErrorStatus } from "../../redux/slices/appSlice"
import { setListMapping } from "../../redux/slices/listSlice"
import {
    GA4GTag,
    clearPersistStore,
    ga4Send,
    matchRoute,
    removeTrailingSlashes,
} from "../../utils/utils"
import Footer from "../common/Footer/Footer"
import Header from "../common/Header/Header"
import GenericError from "../shared/GenericError/GenericError"
import { RoutePaths } from "../../configs/route"
import { getHeaderDisplay } from "../../redux/slices/pageSlice"

const PageLayout = () => {
    const is404 = useIs404()
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const { instance, accounts } = useMsal()
    const isAuthenticated = useIsAuthenticated()
    const genericError = useSelector(getErrorStatus)
    const showHeader = useSelector(getHeaderDisplay)
    const path = location.pathname + location.search
    const { data, error, isSuccess } = useGetListsQuery(
        {},
        { skip: !isAuthenticated }
    )

    const { data: employeeData, isSuccess: dataSuccess } =
        useGetEmployeeIdQuery({}, { skip: !isAuthenticated })

    useEffect(() => {
        if (employeeData && accounts && accounts.length != 0) {
            const isExternalType = accounts[0].username
                .split("@")[0]
                .toLowerCase()
                .endsWith("_ext")
            const userId = accounts[0].localAccountId
            GA4GTag(GA4EventType.SET, GA4EventType.USER_PROPERTIES, {
                user_id: userId,
                traffic_type: isExternalType
                    ? GA4TrafficType.EXTERNAL
                    : GA4TrafficType.INTERNAL,
                environment: window.REACT_APP_NODE_ENV!,
                employee_id: employeeData.employeeId
                    ? employeeData.employeeId
                    : "",
            })
        }

        ga4Send(GA4EventType.PAGE_VIEW, {
            path: path,
        })
    }, [dataSuccess, accounts, path])

    const tabsTheme = createTheme({
        palette: {
            primary: {
                main: "#8BC63E",
            },
        },
    })

    useEffect(() => {
        /* istanbul ignore else */
        if (isSuccess) {
            dispatch(setListMapping(data))
        }
    }, [isSuccess])

    const closeHandler = () => {
        if (error?.status === 403) {
            localStorage.removeItem("isLoggedIn")
            clearPersistStore()
            instance.logoutRedirect()
        }
    }

    const getMessage = () => {
        if (error?.status === 403) {
            return t("access_limited")
        }

        return t("something_wrong")
    }

    const hideFooter = (): boolean => {
        const path = removeTrailingSlashes(location.pathname)
        return (
            is404 ||
            path === RoutePaths.AHBS_CALCULATOR_VACCINES ||
            path === RoutePaths.AHBS_CALCULATOR_MEDICINES ||
            path === RoutePaths.AHBS_ESTIMATION_RESULT_VACCINES ||
            path === RoutePaths.AHBS_ESTIMATION_RESULT_MEDICINES ||
            matchRoute(RoutePaths.FEED_CALCULATOR_ESTIMATION_RESULT, path)
        )
    }

    return (
        <ThemeProvider theme={tabsTheme}>
            {showHeader && <Header />}
            <main
                className={`${
                    isAuthenticated
                        ? !showHeader
                            ? "page-container-without-header"
                            : "page-container"
                        : "page-container-login"
                }`}
            >
                <GenericError
                    show={error?.status === 403 || genericError}
                    message={getMessage()}
                    closeHandler={closeHandler}
                >
                    <Outlet />
                </GenericError>
            </main>

            {!hideFooter() && <Footer />}
        </ThemeProvider>
    )
}

export default PageLayout
