import { t } from "i18next"
import "./AHBCalculator.scss"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useEffect, useState } from "react"
import { RootState } from "../../../redux/store"
import SelectedProducts from "./SelectedProducts"
import VaccineFields from "./Vaccines/FormFields"
import MedicineFields from "./Medicines/FormFields"
import { RoutePaths } from "../../../configs/route"
import { useDispatch, useSelector } from "react-redux"
import { allowDigitsOnly, ga4GA } from "../../../utils/utils"
import { useLocation, useNavigate } from "react-router"
import Button from "../../../components/common/Button/Button"
import { setPageConfig } from "../../../redux/slices/pageSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRotateRight } from "@fortawesome/free-solid-svg-icons"
import {
    resetCalculator,
    getCalculatorItems,
    getCalculatorDetails,
    addDetailsToCalculator,
    getCalculatorItemsCount,
} from "../../../redux/slices/appSlice"
import { GA4EventType } from "../../../constants/enums"

export type AHBCalculatorTypes = "vaccine" | "medicine"

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    }
}

const AHBCalculator = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState(
        location.pathname.includes("medicine") ? 1 : 0
    )
    const type = selectedTab === 0 ? "vaccine" : "medicine"

    const products = useSelector((state: RootState) =>
        getCalculatorItems(state, type)
    )

    const info = useSelector((state: RootState) =>
        getCalculatorDetails(state, type)
    )

    const { vaccines, medicines } = useSelector(getCalculatorItemsCount)

    const INITIAL_VALUES = {
        vaccine: {
            no_of_breeder: info.no_of_breeder || "",
            no_of_piglet: info.no_of_piglet || "",
            type_of_use: info.type_of_use || "Intramuscular",
        },
        medicine: {
            no_of_swine: info.no_of_swine || "",
            average_weight: info.average_weight || "",
            daily_feed_consumption: info.daily_feed_consumption || "",
            daily_water_consumption: info.daily_water_consumption || "",
        },
    }

    const [formData, setFormData] = useState(INITIAL_VALUES)

    const isClear = () => {
        if (type === "vaccine") {
            return (
                products.length ||
                formData.vaccine.no_of_piglet ||
                formData.vaccine.no_of_breeder ||
                formData.vaccine.type_of_use !==
                    INITIAL_VALUES.vaccine.type_of_use
            )
        } else if (type === "medicine") {
            return (
                products.length ||
                formData.medicine.no_of_swine ||
                formData.medicine.average_weight ||
                formData.medicine.daily_feed_consumption ||
                formData.medicine.daily_water_consumption
            )
        }
    }

    const isFilled = () => {
        if (type === "vaccine") {
            return (
                products.length &&
                formData.vaccine.no_of_breeder &&
                formData.vaccine.no_of_piglet &&
                formData.vaccine.type_of_use
            )
        } else if (type === "medicine") {
            return (
                products.length &&
                formData.medicine.no_of_swine &&
                formData.medicine.average_weight &&
                formData.medicine.daily_feed_consumption &&
                formData.medicine.daily_water_consumption
            )
        }
    }

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(
            newValue === 1
                ? RoutePaths.AHBS_CALCULATOR_MEDICINES
                : RoutePaths.AHBS_CALCULATOR_VACCINES
        )
    }

    const onChangeHandler = (event: any) => {
        const value = event.target.value
        if (event.target.tagName === "INPUT" && !allowDigitsOnly(value)) {
            return false
        }

        const { ...data } = formData
        if (type === "vaccine") {
            data.vaccine = {
                ...data.vaccine,
                [event.target.name]: value,
            }
        } else if (type === "medicine") {
            data.medicine = {
                ...data.medicine,
                [event.target.name]: value,
            }
        }

        setFormData(data)
    }

    const onResetClick = () => {
        const { ...data } = formData
        if (type === "vaccine") {
            data.vaccine = {
                no_of_breeder: "",
                no_of_piglet: "",
                type_of_use: "Intramuscular",
            }
        } else if (type === "medicine") {
            data.medicine = {
                no_of_swine: "",
                average_weight: "",
                daily_feed_consumption: "",
                daily_water_consumption: "",
            }
        }

        setFormData(data)
        dispatch(resetCalculator({ type }))
    }

    const getDetails = () => {
        products.map((value: any) => {
            ga4GA(GA4EventType.SEND, {
                selected_value: value.title,
                eventAction: `selected_${type}`,
                count_of_selected_value: products.length,
            })
        })
        if (type === "vaccine") {
            return {
                type,
                no_of_breeder: formData.vaccine.no_of_breeder,
                no_of_piglet: formData.vaccine.no_of_piglet,
                type_of_use: formData.vaccine.type_of_use,
            }
        } else if (type === "medicine") {
            return {
                type,
                no_of_swine: formData.medicine.no_of_swine,
                average_weight: formData.medicine.average_weight,
                daily_feed_consumption:
                    formData.medicine.daily_feed_consumption,
                daily_water_consumption:
                    formData.medicine.daily_water_consumption,
            }
        }
    }

    const getCounter = (type: string, count: number) => {
        const title = type === "vaccine" ? t("vaccine") : t("medicine")
        if (!count) {
            return title
        }

        return (
            <div className="tab-counter">
                {title}
                <div className="ahb-counter-value">{count}</div>{" "}
            </div>
        )
    }

    useEffect(() => {
        if (location.pathname.includes("vaccines")) {
            selectedTab === 1 && setSelectedTab(0)
        } else if (location.pathname.includes("medicines")) {
            selectedTab === 0 && setSelectedTab(1)
        }
    }, [location])

    useEffect(() => {
        setFormData(INITIAL_VALUES)
    }, [selectedTab])

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("ahb_calculator"),
                heading: t("ahb_calculator"),
                backButtonLink: RoutePaths.CALCULATOR,
                rightIcon: false,
            })
        )
    }, [])

    return (
        <section className="page-ahb-calculator">
            <section className="tab">
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        label={getCounter("vaccine", vaccines)}
                        {...tabProps(0)}
                    />
                    <Tab
                        label={getCounter("medicine", medicines)}
                        {...tabProps(1)}
                    />
                </Tabs>
                <section
                    role="tabpanel"
                    id="tabpanel-0"
                    className="vaccine-tab"
                    hidden={selectedTab !== 0}
                >
                    <SelectedProducts type={type} />
                    <VaccineFields
                        formData={formData.vaccine}
                        onChangeHandler={onChangeHandler}
                    />
                </section>

                <section
                    role="tabpanel"
                    id="tabpanel-1"
                    className="medicine-tab"
                    hidden={selectedTab !== 1}
                >
                    <SelectedProducts type={type} />
                    <MedicineFields
                        formData={formData.medicine}
                        onChangeHandler={onChangeHandler}
                    />
                </section>
            </section>
            <section className="action-btns">
                <Button
                    variant="outlined"
                    disabled={!isClear()}
                    className="clear-btn"
                    onClick={onResetClick}
                >
                    <FontAwesomeIcon
                        size="2xs"
                        icon={faRotateRight}
                        style={{ transform: "translateX(-3px)" }}
                    />
                    <span>{t("clear_all")}</span>
                </Button>
                <Button
                    variant="contained"
                    className="result-btn"
                    disabled={!isFilled()}
                    onClick={() => {
                        ga4GA(GA4EventType.SEND, {
                            target: type,
                            eventAction: "see_result_click",
                        })
                        dispatch(addDetailsToCalculator(getDetails()))
                        navigate(
                            type === "vaccine"
                                ? RoutePaths.AHBS_ESTIMATION_RESULT_VACCINES
                                : RoutePaths.AHBS_ESTIMATION_RESULT_MEDICINES
                        )
                    }}
                >
                    {t("see_result")}
                </Button>
            </section>
        </section>
    )
}

export default AHBCalculator
