import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import Divider from "@mui/material/Divider"
import { GA4EventType } from "../../../constants/enums"
import { ga4GA } from "../../../utils/utils"
import RouteLink from "../../common/RouteLink/RouteLink"
import "./TagsListView.scss"

type PropType = { name: string; link: string }

const TagsListView = ({ name, link }: PropType) => {
    const level = link.includes("results") ? 2 : 1
    const handleClick = () => {
        ga4GA(GA4EventType.SEND, {
            search_term: name,
            level: level,
            eventAction: "recommendation_filter_list_level_" + level,
            source: "list",
        })
    }
    return (
        <RouteLink
            to={link}
            onClick={handleClick}
            className="product-recommendations"
        >
            <div className="pr-tags">
                <p className="tag-title">{name}</p>
                <span>
                    <KeyboardArrowRightIcon fontSize="small" />
                </span>
            </div>
            <Divider className="divider" />
        </RouteLink>
    )
}

export default TagsListView
