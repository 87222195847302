import { useDispatch, useSelector } from "react-redux"
import useGetListId from "../../../../hooks/useGetListId"
import { PropType, getColumnName } from "../../../../utils/utils"
import { ListAlias, ProductCategory } from "../../../../constants/enums"
import useGetColumnMapping from "../../../../hooks/useGetColumnMapping"
import { useGetListItemsQuery } from "../../../../redux/api/listApiSlice"
import { getLanguage, setErrorStatus } from "../../../../redux/slices/appSlice"
import Loader from "../../../../components/common/Loader/Loader"
import { useEffect, useMemo, useState } from "react"
import { Anchor } from "../../../ProductCatalog/ProductCatalog"
import { RoutePaths } from "../../../../configs/route"
import useFilters from "../../../../hooks/useFilters"
import FilterDrawer from "../../../../components/common/Filters/FiltersV2"
import { getSearchTerm } from "../../../../redux/slices/searchSlice"
import { setPageConfig } from "../../../../redux/slices/pageSlice"
import ProductCatalogTopBar from "../../../../components/shared/ProductCatalogTopBar/ProductCatalogTopBar"
import ProductCatalogList from "../../../../components/shared/ProductCatalogList/ProductCatalogList"

const AHBCalculatorVaccines = () => {
    const vaccineLabel = "Vaccine"
    const dispatch = useDispatch()
    const language = useSelector(getLanguage)
    const searchTerm = useSelector(getSearchTerm)
    const ahbsListId = useGetListId(ListAlias.AHBS)
    const productGroupListId = useGetListId(ListAlias.PRODUCT_GROUPS)
    const [drawerState, setDrawerState] = useState({
        top: false,
    })
    const [loading, setLoading] = useState(false)

    // Get product group columns
    const {
        isError: productGroupColumnError,
        isFetching: productGroupColumnLoading,
        columnMapping: productGroupColumnsMapping,
    } = useGetColumnMapping(productGroupListId, "product-groups")

    const titleField = getColumnName(
        productGroupColumnsMapping,
        language,
        "NAME"
    )
    const subTagsField = getColumnName(
        productGroupColumnsMapping,
        'TH',
        "SUB_GROUPS"
    )
    const ahbsField = getColumnName(
        productGroupColumnsMapping,
        language,
        "AHBS"
    )

    console.log('---------debug1---------')
    console.log(productGroupColumnsMapping, 'productGroupColumnsMapping')
    console.log(titleField, 'titleField')
    console.log(subTagsField, 'subTagsField')
    console.log(ahbsField, 'ahbsField')

        // Get vaccine sub groups from product groups
    const {
        data: productGroups,
        isError: productGroupError,
        isFetching: productGroupLoading,
    } = useGetListItemsQuery(
        {
            listId: productGroupListId,
            listName: "product-groups",
            $filter: `fields/catalog_type eq 'AHB' and ((fields/level eq 1 and fields/${titleField} eq '${vaccineLabel}') or fields/level eq 2)`,
            $expand: `fields($select=level,${titleField},${subTagsField},${ahbsField})`,
        },
        {
            skip: productGroupColumnLoading,
        }
    )


        // Prepare filters to bes used in filter drawer
    const { filters = {}, vaccineProducts = [] } = useMemo(() => {
        const filters: PropType = {
            sorting: true,
            filters: {},
        }

        if (!productGroups) {
            return { filters, vaccineProducts: [] }
        }

        const vaccine = productGroups.value.find((item: any) => {
            return item.fields.level === "1"
        })

        // Prepare filters
        const subGroupIds: number[] = []
        const totalVaccines = vaccine.fields[subTagsField].length
        filters["filters"][vaccine.fields[titleField]] = {
            options: [],
            clear: false,
            expand: true,
            name: "filters",
        }
        vaccine.fields[subTagsField].forEach((item: any) => {
            subGroupIds.push(item.LookupId)
            filters["filters"][vaccine.fields[titleField]].options.push({
                checked: true,
                value: item.LookupId,
                ignoreUrlParam: true,
                label: item.LookupValue,
                disabled: totalVaccines === 1,
            })
        })

        // Filter again from product groups for AHBs vaccines
        let vaccineProducts: any = []
        productGroups.value.forEach((item: any) => {
            if (subGroupIds.includes(Number(item.id))) {
                vaccineProducts.push(
                    ...item["fields"][ahbsField].map((i: any) => i.LookupId)
                )
            }
        })

        // Get unique values
        vaccineProducts = new Set(vaccineProducts)
        vaccineProducts = [...vaccineProducts]

        return { filters, vaccineProducts }
    }, [productGroups])

    // Get AHB catalog columns
    const {
        isError: productColumnError,
        isFetching: productColumnLoading,
        columnMapping: productColumnMapping,
    } = useGetColumnMapping(ahbsListId, "ahbs")


    const productTitleFieldEN = getColumnName(
        productColumnMapping,
        "EN",
        "PRODUCT_NAME"
    )

    const productTitleField = getColumnName(
        productColumnMapping,
        'TH',
        "PRODUCT_NAME"
    )
    const productImageField = getColumnName(
        productColumnMapping,
        language,
        "PICTURE"
    )

    console.log(productTitleFieldEN, 'productTitleFieldEN')
    console.log(productTitleField, 'productTitleField')
    console.log(productImageField, 'productImageField')

    // Get vaccine products from AHB catalog
    const {
        data: products,
        isError: productError,
        isFetching: productLoading,
    } = useGetListItemsQuery(
        {
            listName: "ahbs",
            listId: ahbsListId,
            $expand: `fields($select=calculable,${productTitleField},${productTitleFieldEN},${productImageField})`,
            $orderby: `fields/${productTitleField} asc`,
        },
        {
            skip: productColumnLoading || vaccineProducts.length === 0,
        }
    )

    console.log('---------debug4---------')
    console.log(products, 'products')

        // Prepare final data (client side)
    const data = useMemo(() => {
        if (!products || !products?.value?.length) {
            return []
        }

        return products.value.filter((item: any) => {
            const cond =
                item.fields?.calculable === true &&
                vaccineProducts.includes(Number(item.id))

            if (searchTerm) {
                const title = item?.fields[productTitleField]?.toUpperCase()
                const titleEN = item?.fields[productTitleFieldEN]?.toUpperCase()
                const _searchTerm = searchTerm.toUpperCase()
                return (
                    cond &&
                    (title?.includes(_searchTerm) ||
                        titleEN?.includes(_searchTerm))
                )
            }

            return cond
        })
    }, [products, searchTerm])

    const toggleDrawer = (anchor: Anchor, open: boolean) => {
        setDrawerState({ ...drawerState, [anchor]: open })
    }

    // Show error in case any API call fails
    useEffect(() => {
        dispatch(
            setErrorStatus(
                productGroupColumnError ||
                    productColumnError ||
                    productGroupError ||
                    productError
            )
        )
    }, [
        productGroupColumnError,
        productColumnError,
        productGroupError,
        productError,
    ])

    const handleLoading = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 300)
    }

    const isLoading = () => {
        return (
            productGroupColumnLoading ||
            productColumnLoading ||
            productGroupLoading ||
            productLoading ||
            loading
        )
    }

    // Filter data
    const { filteredData } = useFilters(data)
    
    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: "search",
                backButtonLink: RoutePaths.AHBS_CALCULATOR_VACCINES,
            })
        )
    }, [])

    return (
        <Loader show={isLoading()}>
            <div className="page-product-catalog">
                <ProductCatalogTopBar
                    isActive={true}
                    title={ProductCategory.AHB}
                    count={filteredData.length}
                    toggleDrawer={toggleDrawer}
                />

                <ProductCatalogList
                    isFilter={false}
                    calculate={true}
                    subtype="vaccine"
                    data={filteredData}
                    titleField="field_4"
                    type={ProductCategory.AHB}
                    toggleDrawer={toggleDrawer}
                    link={RoutePaths.AHB_DETAILS}
                    imageField={productImageField}
                />

                <FilterDrawer
                    toggleDrawer={toggleDrawer}
                    drawerState={drawerState}
                    urlParams={{}}
                    filters={filters}
                    handleLoading={handleLoading}
                />
            </div>
        </Loader>
    )
}

export default AHBCalculatorVaccines
