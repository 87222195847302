import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useNavigate, useLocation } from "react-router"
import { removeTrailingSlashes } from "../utils/utils"

const usePagination = (data: any[], perPage = 10) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [page, changePage] = useState<number>(
        searchParams.get("page") ? Number(searchParams.get("page")) : 1
    )

    useEffect(() => {
        const page = searchParams.get("page")
        changePage(page ? Number(page) : 1)
    }, [searchParams])

    const setPage = (page: number) => {
        searchParams.set("page", String(page))
        navigate(
            removeTrailingSlashes(location.pathname) +
                "?" +
                searchParams.toString()
        )
    }

    const getPaginatedData = () => {
        return data.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
    }

    return {
        page,
        setPage,
        paginatedData: getPaginatedData(),
        pages: Math.ceil(data.length / perPage),
    }
}

export default usePagination
