import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { SelectInputProps } from "@mui/material/Select/SelectInput"
import "./SelectField.scss"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
export interface IPropTypes extends SelectInputProps {
    label: string
    required: boolean
    id: string
    data: any[]
}
const SelectField = ({ label, required, id, data, ...rest }: IPropTypes) => {
    return (
        <div className="select-field">
            <label htmlFor={`${id}`}>
                {label} {required && <span className="required">*</span>}
            </label>
            <Select
                inputProps={{ "aria-label": "Without label" }}
                id={id}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                {...rest}
            >
                {data.map(({ label, value }: any) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

export default SelectField
