import { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import App from "../App"
import PageLayout from "../components/layout/PageLayout"
import { RoutePaths } from "../configs/route"
import { ProductCategory } from "../constants/enums"
import AHBProductDetails from "../pages/AHBProductDetails/AHBProductDetails"
import Calculator from "../pages/Calculator/Calculator"
import Contact from "../pages/Contact/Contact"
import FarmEquipmentProductDetails from "../pages/FarmEquipmentProductDetails/FarmEquipmentProductDetails"
import FeedAdditive from "../pages/FeedAdditive/FeedAdditive"
import FeedProductDetails from "../pages/FeedProductDetails/FeedProductDetails"
import LabServiceProductDetails from "../pages/LabServiceProductDetails/LabServiceProductDetails"
import NotFound from "../pages/NotFound/NotFound"
import ProductCatalog from "../pages/ProductCatalog/ProductCatalog"
import ProductL1Tags from "../pages/ProductRecommendations/ProductL1Tags"
import ProductL2Tags from "../pages/ProductRecommendations/ProductL2Tags"
import ProductRecommendations from "../pages/ProductRecommendations/ProductRecommendations"
import Profile from "../pages/Profile/Profile"
import ProtectedRoute from "./ProtectedRoute"
import AHBEstimationResult from "../pages/Calculator/AHB/AHBEstimationResult"
import AHBCalculator from "../pages/Calculator/AHB/AHBCalculator"
import AHBCalculatorVaccines from "../pages/Calculator/AHB/Vaccines/Vaccines"
import AHBCalculatorMedicines from "../pages/Calculator/AHB/Medicines/Medicines"
import FeedPackage from "../pages/Calculator/Feed/FeedPackage/FeedPackage"
import FeedCalculator from "../pages/Calculator/Feed/FeedCalculator"
import FeedCategoryForm from "../pages/Calculator/Feed/FeedCategory/FeedCategoryForm"
import FeedPackageDetail from "../pages/Calculator/Feed/FeedPackage/FeedPackageDetail/FeedPackageDetail"
import FeedEstimation from "../pages/Calculator/Feed/FeedEstimation/FeedEstimation"
import SelectFeed from "../pages/Calculator/Feed/SelectFeed/SelectFeed"
import AdminCalculatorExcelUpload from "../pages/AdminCalculatorExcelUpload/AdminCalculatorExcelUpload"
const AppRoutes = () => {
    // Handle auto logout
    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === "isLoggedIn" && e.oldValue && !e.newValue) {
                window.location.reload()
            }
        }
        window.addEventListener("storage", handleInvalidToken)
        return function () {
            window.removeEventListener("storage", handleInvalidToken)
        }
    }, [])

    return (
        <Routes>
            <Route element={<PageLayout />}>
                <Route index element={<App />} />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path={RoutePaths.CALCULATOR}
                        element={<Calculator />}
                    />
                    <Route path={RoutePaths.CONTACT} element={<Contact />} />
                    <Route path={RoutePaths.PROFILE} element={<Profile />} />
                    <Route
                        path={RoutePaths.ADMIN_CALCULATOR_EXCEL_UPLOAD}
                        element={<AdminCalculatorExcelUpload />}
                    />
                    <Route
                        path={RoutePaths.FEED_CATALOG}
                        element={
                            <ProductCatalog
                                productCategory={ProductCategory.FEED}
                            />
                        }
                    />
                    <Route
                        path={RoutePaths.AHB_CATALOG}
                        element={
                            <ProductCatalog
                                productCategory={ProductCategory.AHB}
                            />
                        }
                    />
                    <Route
                        path={RoutePaths.FARM_EQUIPMENTS_CATALOG}
                        element={
                            <ProductCatalog
                                productCategory={ProductCategory.FARM_EQUIPMENT}
                            />
                        }
                    />
                    <Route
                        path={RoutePaths.LAB_SERVICES_CATALOG}
                        element={
                            <ProductCatalog
                                productCategory={ProductCategory.LAB_SERVICE}
                            />
                        }
                    />
                    <Route
                        path={RoutePaths.FEED_ADDITIVE}
                        element={<FeedAdditive />}
                    />
                    <Route
                        path={RoutePaths.FEED_DETAILS}
                        element={<FeedProductDetails />}
                    />
                    <Route
                        path={RoutePaths.FARM_EQUIPMENTS_DETAILS}
                        element={<FarmEquipmentProductDetails />}
                    />
                    <Route
                        path={RoutePaths.AHB_DETAILS}
                        element={<AHBProductDetails />}
                    />
                    <Route
                        path={RoutePaths.LAB_SERVICES_DETAILS}
                        element={<LabServiceProductDetails />}
                    />

                    <Route
                        path={RoutePaths.PRODUCT_RECOMMENDATION_L1}
                        element={<ProductL1Tags />}
                    />

                    <Route
                        path={RoutePaths.PRODUCT_RECOMMENDATION_L2}
                        element={<ProductL2Tags />}
                    />

                    <Route
                        path={RoutePaths.PRODUCT_RECOMMENDATION_RESULT}
                        element={<ProductRecommendations />}
                    />

                    <Route
                        path={RoutePaths.AHBS_CALCULATOR_VACCINES}
                        element={<AHBCalculator />}
                    />
                    <Route
                        path={RoutePaths.AHBS_CALCULATOR_MEDICINES}
                        element={<AHBCalculator />}
                    />

                    <Route
                        path={RoutePaths.AHBS_CALCULATOR_SELECT_VACCINES}
                        element={<AHBCalculatorVaccines />}
                    />

                    <Route
                        path={RoutePaths.AHBS_CALCULATOR_SELECT_MEDICINES}
                        element={<AHBCalculatorMedicines />}
                    />

                    <Route
                        path={RoutePaths.AHBS_ESTIMATION_RESULT_VACCINES}
                        element={<AHBEstimationResult type="vaccine" />}
                    />

                    <Route
                        path={RoutePaths.AHBS_ESTIMATION_RESULT_MEDICINES}
                        element={<AHBEstimationResult type="medicine" />}
                    />

                    <Route
                        path={RoutePaths.FEED_CALCULATOR}
                        element={<FeedCalculator />}
                    />
                    <Route
                        path={RoutePaths.FEED_CALCULATOR_CATEGORY}
                        element={<FeedCategoryForm />}
                    />
                    <Route
                        path={RoutePaths.FEED_CALCULATOR_PACKAGE}
                        element={<FeedPackage />}
                    />
                    <Route
                        path={RoutePaths.FEED_CALCULATOR_PACKAGE_CUSTOMIZE}
                        element={<FeedPackageDetail />}
                    />
                    <Route
                        path={RoutePaths.FEED_CALCULATOR_SELECT_FEEDS}
                        element={<SelectFeed />}
                    />
                    <Route
                        path={RoutePaths.FEED_CALCULATOR_ESTIMATION_RESULT}
                        element={<FeedEstimation />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default AppRoutes
