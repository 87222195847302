import { createSlice } from "@reduxjs/toolkit"
import { PropType } from "../../utils/utils"
import { RootState } from "../store"

export type Packages = "platinum" | "gold" | "silver"

interface IPackage {
    isLoaded: boolean
    products: { defaults: any[]; updated: any[] }
}

interface IState {
    inputs: PropType
    packages: Record<Packages, IPackage>
}

const initialState: IState = {
    inputs: {},
    packages: {
        platinum: {
            isLoaded: false,
            products: {
                defaults: [],
                updated: [],
            },
        },
        gold: {
            isLoaded: false,
            products: {
                defaults: [],
                updated: [],
            },
        },
        silver: {
            isLoaded: false,
            products: {
                defaults: [],
                updated: [],
            },
        },
    },
}

const feedCalculatorSlice = createSlice({
    name: "feedCalculator",
    initialState,
    reducers: {
        setInputs: (state, action) => {
            state.inputs = action.payload
        },
        setProducts: (state, action) => {
            const { type, isDefault = false, products } = action.payload
            state.packages[type as Packages].products.updated = products
            if (isDefault) {
                state.packages[type as Packages].products.defaults = products
            }
        },
        removeProduct: (state, action) => {
            const { type, id } = action.payload
            state.packages[type as Packages].products.updated = state.packages[
                type as Packages
            ].products.updated.filter((item: PropType) => item?.id !== id)
        },
        updateProduct: (state, action) => {
            const { type, id, ...rest } = action.payload
            state.packages[type as Packages].products.updated = state.packages[
                type as Packages
            ].products.updated.map((item: PropType) => {
                if (item?.id === id) {
                    return { ...item, ...rest }
                }
                return item
            })
        },
        setIsLoaded: (state, action) => {
            const { type, isLoaded } = action.payload
            state.packages[type as Packages].isLoaded = isLoaded
        },
        reset: (state) => {
            state.inputs = initialState.inputs
            state.packages = initialState.packages
        },
    },
})

export const {
    setInputs,
    setProducts,
    updateProduct,
    removeProduct,
    setIsLoaded,
    reset,
} = feedCalculatorSlice.actions
export const getInputs = (state: RootState) => state.feedCalculator.inputs
export const getProducts = (state: RootState, type: Packages) =>
    state.feedCalculator.packages[type]?.products
export const getIsLoaded = (state: RootState, type: Packages) =>
    state.feedCalculator.packages[type]?.isLoaded

export default feedCalculatorSlice
