import React from "react"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import Divider from "@mui/material/Divider"

export type TContactDetails = {
    data: TContactInfo[]
}

export type TContactInfo = {
    details: TContact[]
    title: string
}

export type TContact = {
    icon: string
    name: string
    value: string
    rightChildren?: JSX.Element
}

const ContactDetails = ({ data }: TContactDetails) => (
    <div className="contact-accordians">
        {data.map((val: any, index: number) => (
            <React.Fragment key={index}>
                <CustomAccordion
                    title={val.title}
                    productDetails={val.details}
                    expand={index === 0}
                />
                <Divider />
            </React.Fragment>
        ))}
    </div>
)

export default ContactDetails
