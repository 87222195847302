import { Fragment, useEffect, useMemo, useState } from "react"
import Loader from "../../components/common/Loader/Loader"
import { Divider } from "@mui/material"
import { MoreInfo } from "../../assets/images"
import CustomAccordion from "../../components/common/Accordion/Accordion"
import AccordionListView from "../../components/common/Accordion/AccordionListView"
import { ProductDetails } from "../../components/common/Accordion/types"
import ProductBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs"
import ProductInformation from "../../components/shared/ProductInformation/ProductInformation"
import TechnicalVocab from "../../components/shared/TechnicalVocab/TechnicalVocab"
import { GA4EventType, ProductCategory } from "../../constants/enums"
import useGetProductDetails from "../../hooks/useGetProductDetails"
import { useTranslation } from "react-i18next"
import { setPageConfig } from "../../redux/slices/pageSlice"
import { RoutePaths } from "../../configs/route"
import { useDispatch } from "react-redux"
import {
    PropType,
    ga4GA,
    getFieldValue,
    getImageUrl,
    getPlaceholderImage,
} from "../../utils/utils"
import { useLocation } from "react-router-dom"

export type ProductAccordionsPropType = {
    title: string
    data: ProductDetails[]
}

export type AccordionsListPropType = {
    title: string
    data: string[]
    rightChildren?: JSX.Element
}

interface IData {
    breadcrumbs: string[]
    productDescription: PropType
    accordionList: AccordionsListPropType[]
    productAccordions: ProductAccordionsPropType
}

const FarmEquipmentProductDetails = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id, isLoading, productDetails, language, lastModifiedDateTime } =
        useGetProductDetails("FARM_EQUIPMENTS")

    const [vocabOpen, setVocabOpen] = useState(false)
    const location = useLocation()

    const rightChildrenClickHandler = () => {
        ga4GA(GA4EventType.SEND, {
            eventAction: "farm_tech_vocab_click",
            target: "info icon on Farm equipment detail view ",
        })
        setVocabOpen(true)
    }
    const handleClose = () => {
        setVocabOpen(false)
    }

    const rightChildren = (className?: string) => {
        return (
            <img
                src={MoreInfo}
                alt="right-icon"
                className={`right-icon ${className}`}
                data-testid="right-icon"
                onClick={rightChildrenClickHandler}
            />
        )
    }

    const {
        breadcrumbs = [],
        accordionList = [],
        productDescription = {},
        productAccordions = {
            title: "",
            data: [],
        },
    }: IData | PropType = useMemo(() => {
        if (Object.keys(productDetails).length === 0) {
            return {}
        }

        const productGroup = getFieldValue(
            productDetails,
            language,
            "PRODUCT_GROUP"
        )

        const productBreadCrumbs = [
            "PRODUCT_GROUP",
            ...(productGroup !== t("others") ? ["PRODUCT_SUB_GROUP"] : []),

            ...(productGroup === t("feeding_watering_system")
                ? ["PRODUCT_CATEGORY"]
                : []),

            "PRODUCT_NAME",
        ]

        return {
            breadcrumbs: productBreadCrumbs,
            productDescription: {
                name: getFieldValue(productDetails, language, "PRODUCT_NAME"),
                description: getFieldValue(
                    productDetails,
                    language,
                    "PRODUCT_DESCRIPTION"
                ),
                date: lastModifiedDateTime,
                img: getImageUrl(
                    getFieldValue(productDetails, language, "PICTURE")
                ),
                defaultImage: getPlaceholderImage(
                    ProductCategory.FARM_EQUIPMENT
                ),
            },
            accordionList: [
                {
                    title: t("selling_point"),
                    data: [
                        getFieldValue(
                            productDetails,
                            language,
                            "PRODUCT_SELLING_POINT"
                        ),
                    ],
                    rightChildren:
                        productGroup === t("feeding_watering_system")
                            ? rightChildren("mt-10")
                            : undefined,
                },
                {
                    title: t("warranty"),
                    data: [getFieldValue(productDetails, language, "WARRANTY")],
                },
                {
                    title: t("delivery"),
                    data: [
                        getFieldValue(
                            productDetails,
                            language,
                            "DELIVERY_METHOD"
                        ),
                    ],
                },
            ],
            productAccordions: {
                title: t("product_information"),
                data: [
                    {
                        name: t("material"),
                        value: getFieldValue(
                            productDetails,
                            language,
                            "MATERIAL"
                        ),
                    },
                    {
                        name: t("size"),
                        value: getFieldValue(
                            productDetails,
                            language,
                            "PRODUCT_SIZE"
                        ),
                    },
                    ...(productGroup === t("feeding_watering_system")
                        ? [
                              {
                                  name: t("diameter"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "PRODUCT_DIMENSION"
                                  ),
                              },
                              {
                                  name: t("height"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "HEIGHT"
                                  ),
                              },
                              {
                                  name: t("weight"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "WEIGHT"
                                  ),
                              },
                              {
                                  name: t("num_of_supporting_legs"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "NO_OF_SUPPORTING_LEGS"
                                  ),
                              },
                              {
                                  name: t("num_of_rings"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "NO_OF_RING"
                                  ),
                              },
                          ]
                        : []),

                    ...(productGroup === t("ventilation_system")
                        ? [
                              {
                                  name: t("fan_specification"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "FAN_SPECIFICATION"
                                  ),
                              },
                              {
                                  name: t("motor"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "MOTOR_TYPE_SPECIFICATION"
                                  ),
                                  rightChildren: rightChildren(),
                              },
                              {
                                  name: t("wind_efficiency"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "WIND_EFFICIENCY"
                                  ),
                                  rightChildren: rightChildren(),
                              },
                              {
                                  name: t("efficiency_per_power_rating"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "EFFICIENCY_POWER_RATING"
                                  ),
                                  rightChildren: rightChildren(),
                              },
                              {
                                  name: t("package_unit"),
                                  value: getFieldValue(
                                      productDetails,
                                      language,
                                      "PACKAGE_UNIT"
                                  ),
                              },
                          ]
                        : []),
                ],
            },
        }
    }, [language, productDetails])

    useEffect(() => {
        dispatch(
            setPageConfig({
                heading: "",
                rightIcon: false,
                backButtonLink:
                    location.state || RoutePaths.FARM_EQUIPMENTS_CATALOG,
            })
        )
    }, [])

    return (
        <Loader show={isLoading}>
            {Object.keys(productDetails).length > 0 && (
                <>
                    <ProductBreadcrumbs
                        productKeys={breadcrumbs}
                        productDetails={productDetails}
                    />
                    <ProductInformation
                        id={id}
                        img={productDescription.img}
                        name={productDescription.name}
                        date={productDescription.date}
                        description={productDescription.description}
                        defaultImage={productDescription.defaultImage}
                    />
                    <Divider />
                    <CustomAccordion
                        title={productAccordions.title}
                        productDetails={productAccordions.data}
                        expand
                    />
                    <Divider />
                    {accordionList.map((item: any, idx: number) => (
                        <Fragment key={idx}>
                            <AccordionListView
                                title={item.title}
                                productDetails={item.data}
                                rightChildren={item.rightChildren}
                            />
                            <Divider />
                        </Fragment>
                    ))}
                    {vocabOpen && <TechnicalVocab handleClose={handleClose} />}
                </>
            )}
        </Loader>
    )
}

export default FarmEquipmentProductDetails
