import { SvgIcons } from "../../../constants/enums"
import style from "../../../styles/_variable.scss"
import { Contact, Filter, Home, Calculator } from "./Icons"

export interface Props {
    color: string
    name: SvgIcons
}

const SvgIcon = ({ color = style.gray60, name }: Props) => {
    switch (name) {
        case SvgIcons.FILTER:
            return <Filter color={color} />
        case SvgIcons.HOME:
            return <Home color={color} />
        case SvgIcons.CALCULATOR:
            return <Calculator color={color} />
        case SvgIcons.CONTACT:
            return <Contact color={color} />
    }
}

export default SvgIcon
