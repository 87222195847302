import "./FeedCalculator.scss"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../../configs/route"
import { replacePathParams } from "../../../utils/utils"
import { setPageConfig } from "../../../redux/slices/pageSlice"
import RouteLink from "../../../components/common/RouteLink/RouteLink"
import { feedCalculatorCatagories } from "../../../components/shared/ProductCategoryCard/ProductCategoryConfig"

const FeedCalculator = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            setPageConfig({
                title: t("feed_calculator"),
                heading: t("feed_calculator"),
                backButtonLink: RoutePaths.CALCULATOR,
                rightIcon: false,
            })
        )
    }, [])

    return (
        <div className="feed-calculator">
            <section className="categories">
                {feedCalculatorCatagories.map((item: any, idx: number) => (
                    <div
                        key={idx}
                        className="package-category-card"
                        style={{ backgroundColor: item.backgroundColor }}
                    >
                        <RouteLink
                            to={replacePathParams(item.link, {
                                category: item.key,
                            })}
                        >
                            <h3 className="title">{item.name}</h3>
                            <div className="image">
                                <img
                                    src={item.thumbnail}
                                    alt="category image"
                                />
                            </div>
                        </RouteLink>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default FeedCalculator
