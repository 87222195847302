import { NoResultFoundHere } from "../../../assets/images"
import Button from "../Button/Button"
import "./NoResultFound.scss"
import { useTranslation } from "react-i18next"

interface IButton {
    show: boolean
    label: string
    width?: string
    onClick?: () => void
}

interface ILink {
    show: boolean
    target: string
    href?: string
    width?: string
    title?: string
    onClick?: () => void
}

interface INoResultFound {
    title?: string
    button?: IButton
    link?: ILink
    image?: any
}

const NoResultFound = ({
    title,
    button,
    link,
    image = NoResultFoundHere,
}: INoResultFound) => {
    const { t } = useTranslation()
    const noResultTitle = title || t("no_records")

    return (
        <div className="no-result-container">
            <img src={image} width="64px" />
            <div className="title">{noResultTitle}</div>

            {button?.show && (
                <Button className="no-result-btn" onClick={button.onClick}>
                    {button.label}
                </Button>
            )}

            {link?.show && (
                <a
                    rel="noreferrer"
                    href={link?.href}
                    target={link?.target}
                    onClick={link?.onClick}
                >
                    {link.title}
                </a>
            )}
        </div>
    )
}

export default NoResultFound
