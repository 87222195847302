import { useTranslation } from "react-i18next"
import { PropType } from "../../../../utils/utils"
import InputField from "../../../../components/common/InputField/InputField"

interface IProps {
    formData: PropType
    onChangeHandler: (e: any) => any
}

const FormFields = ({ formData = {}, onChangeHandler }: IProps) => {
    const { t } = useTranslation()

    return (
        <section className="input-fields">
            <InputField
                type="text"
                maxLength={5}
                required={true}
                id="no_of_swine"
                name="no_of_swine"
                inputMode="numeric"
                label={t("no_of_swine")}
                onInput={onChangeHandler}
                value={formData.no_of_swine}
                placeholder={t("add_number")}
            />
            <InputField
                type="text"
                maxLength={5}
                required={true}
                id="average_weight"
                inputMode="numeric"
                name="average_weight"
                onInput={onChangeHandler}
                label={t("average_weight")}
                placeholder={t("add_number")}
                value={formData.average_weight}
            />
            <InputField
                type="text"
                maxLength={5}
                required={true}
                inputMode="numeric"
                onInput={onChangeHandler}
                id="daily_feed_consumption"
                name="daily_feed_consumption"
                placeholder={t("add_number")}
                label={t("daily_feed_consumption")}
                value={formData.daily_feed_consumption}
            />
            <InputField
                type="text"
                maxLength={5}
                required={true}
                inputMode="numeric"
                onInput={onChangeHandler}
                id="daily_water_consumption"
                placeholder={t("add_number")}
                name="daily_water_consumption"
                label={t("daily_water_consumption")}
                value={formData.daily_water_consumption}
            />
        </section>
    )
}

export default FormFields
